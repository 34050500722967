import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-double-entry-report',
  templateUrl: './double-entry-report.component.html',
  styleUrls: ['./double-entry-report.component.scss']
})
export class DoubleEntryReportComponent extends SharedClassComponent implements OnInit {

  title = 'Double Entry Report';
  doubleEntryDatasource = [];
  ngOnInit() {
    this.onGetDoubleEntryReport();
  }

  onGetDoubleEntryReport() {
    this.utilities.postServiceCall({}, 'double/entry/all').subscribe(res => {
      this.doubleEntryDatasource = res.data;
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onGetDoubleEntryReport.bind(this)
      }
    });
  }

}
