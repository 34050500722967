import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';


@Component({
  selector: 'app-approve-sales',
  templateUrl: './approve-sales.component.html',
  styleUrls: ['./approve-sales.component.scss']
})
export class ApproveSalesComponent extends SharedClassComponent implements OnInit {

  purchaseOrderHeaderForm: FormGroup;
  purchaseOrderLineItemForm: FormGroup;
  title = 'Approve new sale';

  currentYear = new Date().getFullYear();
  qrCodeString = '';

  salesDate: any;
  salesBy: any;
  salesNumber: any;
  customerName: any;
  customerNumber: any;
  customerPhone: any;
  salesOrderNumber: any;

  fetchedSalesItemsDataSource = [];
  now = new Date();
  currencyDataSource = [
    {
      id: 1,
      text: 'TZS'
    }
  ];

  vatDataSource = [
    {
      id: 1,
      text: 'Standard Rate 18%'
    }
  ];

  poNumber: any;

  hideApproveControls = true;

  purchaseOrderLineDataSource = [];
  showAddDialog = false;
  popUpTitle = 'Add New Purchase Order Item';
  invoiceBillItems = [];
  totalVat = 0;
  totalAmountBeforeVat = 0;
  totalAmountAfterVat = 0;

  invoiceHeader: any;
  hidePurchaseOrderForm = true;
  hideEditActionButton = true;

  searchModeOption: string = "contains";
  searchExprOption: any = "Name";
  searchTimeoutOption: number = 200;
  minSearchLengthOption: number = 0;
  showDataBeforeSearchOption: boolean = false;
  productDataSource = [];
  productName: any;
  productCode: any;
  productQtyAvailable: any;

  salesForm: FormGroup;
  saleStatus: string;
  hideSaleStatus = true;
  userRole: any

  salesAmount = 0;
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.userRole = this.authService.getUserRole();
    this.utilities.postServiceCall({}, 'unique-number').subscribe(res => {
      this.uniqueNumber = res.number;
    }, error => {
      this.logger(error);
    });
    this.getAllStock();

    this.salesForm = new FormGroup({
      salesNumber: new FormControl(null, Validators.compose([Validators.required])),
      discountAllowed: new FormControl(0, Validators.compose([])),
      discountDescription: new FormControl(null, Validators.compose([])),
    });
    this.purchaseOrderHeaderForm = new FormGroup({
      salesNo: new FormControl('', Validators.compose([])),
      customerName: new FormControl('', Validators.compose([Validators.required])),
      customerPhone: new FormControl('', Validators.compose([]))
    });

    this.purchaseOrderLineItemForm = new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      itemId: new FormControl(null, Validators.compose([])),
      itemName: new FormControl(null, Validators.compose([Validators.required])),
      itemQuantity: new FormControl(null, Validators.compose([Validators.required])),
      itemAvailable: new FormControl(null, Validators.compose([])),
      stockCode: new FormControl(null, Validators.compose([])),
      itemSize: new FormControl(null, Validators.compose([])),
      itemSpecs: new FormControl('', Validators.compose([])),
      sellingAmount: new FormControl(null, Validators.compose([]))
    });
  }
  resetForm(e) {
    if (e == 1) {
      this.purchaseOrderLineDataSource = [];
    } else if (e == 2) {
      this.purchaseOrderLineItemForm.reset();
    }

  }

  approveSales() {

    if (this.salesForm.invalid) {
      this.toastr.error('Sales number is required.');
      return;
    }

    if (this.salesForm.get('discountAllowed').value === null) {
      this.salesForm.get('discountAllowed').patchValue(0);
    }

    if (this.salesForm.get('discountDescription').value === null) {
      this.salesForm.get('discountDescription').patchValue('');
    }

    let temp = this.salesAmount;
    if (this.salesForm.get('discountAllowed').value > 0) {
      temp -= +this.salesForm.get('discountAllowed').value;
      if (this.salesForm.get('discountDescription').value === null || `${this.salesForm.get('discountDescription').value}`.trim() == '') {
        this.toastr.error('Please write a description for the discount allowed');
        return;
      }
    }
    const result = confirm(
      `Hakikisha malipo unayopokea yapo sawa sawa na yanayoonekana kwenye mfumo, pokea ${this.formatCurrency(temp)} TZS /= tu,
       punguzo la bei ni ${this.formatCurrency(+this.salesForm.get('discountAllowed').value)} TZS /= tu,
       Please be assured to receive cash that matches the system total, receive ${this.formatCurrency(temp)} TZS /= only,
       sales discount is  ${this.formatCurrency(+this.salesForm.get('discountAllowed').value)} TZS /= only,`,
      `Pokea Malipo/Receive Cash'`
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(this.salesForm.value, 'sales/approve').subscribe(res => {
          if (res.statusCode == 2000) {
            this.hideSaleStatus = true;
            this.toastr.success(res.statusMessage);
            this.hideApproveControls = true;
            this.salesForm.reset();
            this.clearData();

            sessionStorage.removeItem(AppSettings.salesKey);
            sessionStorage.setItem(AppSettings.salesKey, JSON.stringify(res.data));

            this.storageService.setHidePurchaseOrderForm(true);
            this.storageService.setHideSalesOrderForm(false);

            sessionStorage.removeItem(AppSettings.hideDeiveryNoteFormKey);
            sessionStorage.removeItem(AppSettings.hidePurchaseOrderFormKey);
            sessionStorage.removeItem(AppSettings.hideSalesOrderFormKey);

            sessionStorage.setItem(AppSettings.hidePurchaseOrderFormKey, JSON.stringify(true));
            sessionStorage.setItem(AppSettings.hideSalesOrderFormKey, JSON.stringify(false));

            notify('Please wait while system prepare Sales order form for printing', 'info');
            this.router.navigate(['/printing-area']);
          } else {
            this.hideApproveControls = false;
            this.clearData();
            this.toastr.error(res.statusMessage);
          }
        }, err => {
          this.toastr.error(err.message);
        });
        }
      });
  }

  cancelSales() {
    if (this.salesForm.invalid) {
      this.toastr.error('Sales number is required.');
      return;
    }

    // if (this.salesForm.get('discountAllowed').value === null) {
    //   this.salesForm.get('discountAllowed').patchValue(0);
    // }

    // this.logger(this.salesForm.get('discountAllowed').value);
    // if (this.salesForm.get('discountAllowed').value > 0) {
    //   this.salesAmount -= +this.salesForm.get('discountAllowed').value;
    // }


    const result = confirm(
      `Je, unataka kuhairisha mauzo ? / Are you sure you want to cancel sales ?`,
      `Hairisha Mauzo/Cancel Sales`
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(this.salesForm.value, 'sales/cancel').subscribe(res => {
          if (res.statusCode == 2000) {
            this.hideSaleStatus = true;
            this.toastr.success(res.statusMessage);
            this.hideApproveControls = true;
            this.clearData();
          } else {
            this.hideApproveControls = false;
            this.clearData();
            this.toastr.error(res.statusMessage);
          }
        }, err => {
          this.toastr.error(err.message);
        });
        }
      });
  }

  verifySalesNumber() {
    if (this.salesForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    this.utilities.postServiceCall(this.salesForm.value, 'sales/check').subscribe(res => {

      if (res.statusCode == 2000) {
        this.toastr.success(res.statusMessage);
        const soDate = ("0" + new Date(res.data[0].saleDate).getDate()).slice(-2) + "-" + ("0"+(new Date(res.data[0].saleDate).getMonth()+1)).slice(-2) + "-"+ new Date(res.data[0].saleDate).getFullYear();
        this.salesDate = soDate;
        this.salesNumber = res.data[0].saleNo;
        this.customerName = res.data[0].customerName;
        this.customerNumber =  res.data[0].customerPhone;


      this.salesBy = res.data[0].createdBy;
      this.customerName = res.data[0].customerName;
      this.customerPhone = res.data[0].customerPhone;
      this.salesOrderNumber = res.data[0].saleNo;
      this.fetchedSalesItemsDataSource = res.data;
      this.hideSaleStatus = false;
      if (res.data[0].status == 0) {
        this.saleStatus = 'UNPAID';
      } else if(res.data[0].status == 1) {
        this.saleStatus = 'PAID';
      }

      const data = {
        customerName: this.customerName,
        customerPhone: this.customerPhone,
        salesDate: this.salesDate,
        salesBy: this.salesBy,
        salesNumber: this.salesNumber,
        status: res.data[0].status
      };

        this.qrCodeString = JSON.stringify(data);
        this.salesAmount = 0;
        this.logger(this.fetchedSalesItemsDataSource);
        for (const iterator of this.fetchedSalesItemsDataSource) {
          this.salesAmount += +iterator.lineTotal;
        }
        this.logger(this.salesAmount);

        this.hideApproveControls = false;
      } else {
        this.hideSaleStatus = true;
        this.hideApproveControls = true;
        this.clearData();
        this.toastr.info(res.statusMessage);
      }
    }, err => {
      this.hideSaleStatus = true;
      this.toastr.error(err.message);
    });
  }

  verifySalesNumberOnDelivery() {
    if (this.salesForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    this.utilities.postServiceCall(this.salesForm.value, 'sales/check/delivery').subscribe(res => {

      if (res.statusCode == 2000) {
        this.toastr.success(res.statusMessage);
        const soDate = ("0" + new Date(res.data[0].saleDate).getDate()).slice(-2) + "-" + ("0"+(new Date(res.data[0].saleDate).getMonth()+1)).slice(-2) + "-"+ new Date(res.data[0].saleDate).getFullYear();
        this.salesDate = soDate;
        this.salesNumber = res.data[0].saleNo;
        this.customerName = res.data[0].customerName;
        this.customerNumber =  res.data[0].customerPhone;


      this.salesBy = res.data[0].createdBy;
      this.customerName = res.data[0].customerName;
      this.customerPhone = res.data[0].customerPhone;
      this.salesOrderNumber = res.data[0].saleNo;
      this.fetchedSalesItemsDataSource = res.data;
      this.hideSaleStatus = false;
      if (res.data[0].status == 0) {
        this.saleStatus = 'UNPAID';
      } else if(res.data[0].status == 1) {
        this.saleStatus = 'PAID';
      }

      const data = {
        customerName: this.customerName,
        customerPhone: this.customerPhone,
        salesDate: this.salesDate,
        salesBy: this.salesBy,
        salesNumber: this.salesNumber,
        status: res.data[0].status
      };

        this.qrCodeString = JSON.stringify(data);
        this.salesAmount = 0;
        this.logger(this.fetchedSalesItemsDataSource);
        for (const iterator of this.fetchedSalesItemsDataSource) {
          this.salesAmount += +iterator.lineTotal;
        }

        this.hideApproveControls = false;
      } else {
        this.hideSaleStatus = true;
        this.hideApproveControls = true;
        this.clearData();
        this.toastr.info(res.statusMessage);
      }
    }, err => {
      this.hideSaleStatus = true;
      this.toastr.error(err.message);
    });
  }

  clearData() {
  this.salesDate = '';
  this.salesNumber = '';
  this.customerName = '';
  this.customerNumber =  '';
  this.qrCodeString = '';
  this.saleStatus = '';

  this.fetchedSalesItemsDataSource = [];
  }

  onPointClick(e) {
    e.target.select();
  }

  addNewPurchaseItem() {
    this.hideEditActionButton = true;
    this.showAddDialog = true;
    this.purchaseOrderLineItemForm.get('itemQuantity').patchValue(null);
    this.purchaseOrderLineItemForm.get('id').patchValue(null);
    this.purchaseOrderLineItemForm.get('itemSize').patchValue(null);
    this.purchaseOrderLineItemForm.get('itemSpecs').patchValue(null);
    this.purchaseOrderLineItemForm.get('sellingAmount').patchValue(null);
    this.productCode = null;
    this.productName = null;
    this.productQtyAvailable = null;
    this.purchaseOrderLineItemForm.get('itemAvailable').patchValue(null);
    this.purchaseOrderLineItemForm.reset();
  }

  addNewPurchaseLineItem() {
    const id = this.purchaseOrderLineDataSource.length + 1;
    this.purchaseOrderLineItemForm.get('id').patchValue(id);
    const currency = this.purchaseOrderLineItemForm.get('currency').value;
    currency === 1 ? this.purchaseOrderLineItemForm.get('currency').patchValue('TZS') : this.purchaseOrderLineItemForm.get('currency').patchValue('USD');
    let discountPercent = 0;
    let totalItemAmt = this.purchaseOrderLineItemForm.get('itemQuantity').value * this.purchaseOrderLineItemForm.get('unitPrice').value;

    if (this.purchaseOrderLineItemForm.get('discountPercent').value !== null) {
      if (this.purchaseOrderLineItemForm.get('discountPercent').value > 0) {
          discountPercent = this.purchaseOrderLineItemForm.get('discountPercent').value;
          totalItemAmt -= (totalItemAmt * (discountPercent/100));
      }
    }

    if (this.purchaseOrderLineItemForm.get('availableStock').value == null) {
      this.purchaseOrderLineItemForm.get('availableStock').patchValue(0);
    }

    const totalVat = totalItemAmt * (18/100);
    const lineTotal = totalVat + totalItemAmt;

    this.purchaseOrderLineDataSource.push({
      id: this.purchaseOrderLineItemForm.get('id').value,
      itemName: this.purchaseOrderLineItemForm.get('itemName').value,
      itemSize: this.purchaseOrderLineItemForm.get('itemSize').value,
      itemQuantity: this.purchaseOrderLineItemForm.get('itemQuantity').value,
      itemSpecs: this.purchaseOrderLineItemForm.get('itemSpecs').value,
      storeLoc: this.purchaseOrderLineItemForm.get('storeLoc').value,
      stockCode: this.purchaseOrderLineItemForm.get('stockCode').value,
      availableStock: this.purchaseOrderLineItemForm.get('availableStock').value,
      discountPercent: discountPercent,
      unitPrice: this.purchaseOrderLineItemForm.get('unitPrice').value,
      currency: this.purchaseOrderLineItemForm.get('currency').value,
      net: totalItemAmt,
      vat: '18%',
      vatAmt: totalVat,
      lineTotal: lineTotal
    });
    this.showAddDialog = false;

    this.purchaseOrderLineItemForm.reset();

    this.purchaseOrderLineItemForm.get('currency').patchValue(this.currencyDataSource[0].id);
    this.purchaseOrderLineItemForm.get('vat').patchValue(this.vatDataSource[0].id);
  }

  onDeleteListener(e) {
    const result = confirm(
      'Are you sure you want to delete selected order list item ?',
      'Delete Line Item'
    );
    result.then(dialogResult => {
      if (dialogResult) {

    for (let index = 0; index < this.purchaseOrderLineDataSource.length; index++) {
      if (this.purchaseOrderLineDataSource[index].id === e.id) {
        this.purchaseOrderLineDataSource.splice(index, 1);
        break;
      }
    }
      }
    });
  }

  createPurchaseOrder() {
    this.printPurchaseOrder();
    if (this.purchaseOrderHeaderForm.invalid) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please fill all required details on purchase order header section.`);
      return;
    }

    if (this.purchaseOrderLineDataSource.length < 1) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please add order items in the list to proceed.`);
      return;
    }
    try {
      const poDate = this.purchaseOrderHeaderForm.get('poDate').value.getFullYear() + "-"+  ("0"+(this.purchaseOrderHeaderForm.get('poDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.purchaseOrderHeaderForm.get('poDate').value.getDate()).slice(-2);
      const deliveryDate = this.purchaseOrderHeaderForm.get('deliveryDate').value.getFullYear() + "-"+  ("0"+(this.purchaseOrderHeaderForm.get('deliveryDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.purchaseOrderHeaderForm.get('deliveryDate').value.getDate()).slice(-2);
      this.purchaseOrderHeaderForm.get('poDate').patchValue(poDate);
      this.purchaseOrderHeaderForm.get('deliveryDate').patchValue(deliveryDate);
    } catch (error) {
      this.logger(error);
    }
    sessionStorage.removeItem(AppSettings.tempKey);
    sessionStorage.removeItem(AppSettings.tempKey1);
    sessionStorage.setItem(AppSettings.tempKey, JSON.stringify(this.purchaseOrderHeaderForm.value));
    sessionStorage.setItem(AppSettings.tempKey1, JSON.stringify(this.purchaseOrderLineDataSource));
    this.qrCodeString = sessionStorage.getItem(AppSettings.tempKey);
    this.logger(this.purchaseOrderHeaderForm.value);
    this.logger(this.purchaseOrderLineDataSource);
  }

  getAllStock() {
    this.utilities.postServiceCall({}, 'stockCategory/all').subscribe(res => {
      this.productDataSource = res.data;
      this.logger(res.data);
    }, error => {
      this.logger(error);
    });
  }

  onProductSelectionValueChange(e){
    this.logger(this.productDataSource);
    for (const iterator of this.productDataSource) {
      if (e.value == iterator.id) {
        this.productCode = iterator.categoryCode;
        this.productName = iterator.categoryName;
        this.productQtyAvailable = iterator.stockItemsQty;
        this.purchaseOrderLineItemForm.get('sellingAmount').patchValue(iterator.sellPrice);
        this.purchaseOrderLineItemForm.get('itemId').patchValue(iterator.id);
        this.purchaseOrderLineItemForm.get('itemSize').patchValue(iterator.itemSize);
        this.purchaseOrderLineItemForm.get('itemAvailable').patchValue(iterator.stockItemsQty);
      }
    }
  }

  addSellItem() {
    if (this.purchaseOrderLineItemForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserName()} please fill all required fields.`)
      return;
    }

    if (+this.purchaseOrderLineItemForm.get('itemAvailable').value < this.purchaseOrderLineItemForm.get('itemQuantity').value) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, no enough stock, please check the available item quantity.`)
      return;
    }

    if (this.purchaseOrderLineItemForm.get('itemQuantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, item quantity can not be less than one.`)
      return;
    }

    for (const iterator of this.purchaseOrderLineDataSource) {
      if (this.productCode == iterator.stockCode) {
        this.toastr.error(`Dear ${this.authService.getUserName()}, item with product code (${this.productCode}) is already added.`)
        return;
      }
    }

    const id = this.purchaseOrderLineDataSource.length + 1;
    this.purchaseOrderLineItemForm.get('id').patchValue(id);

    let totalItemAmt = this.purchaseOrderLineItemForm.get('itemQuantity').value * +this.purchaseOrderLineItemForm.get('sellingAmount').value;



    this.purchaseOrderLineDataSource.push({
      id: id,
      stockCode: this.productCode,
      itemName: this.productName,
      itemSize: this.purchaseOrderLineItemForm.get('itemSize').value,
      itemId: this.purchaseOrderLineItemForm.get('itemId').value,
      itemQuantity: this.purchaseOrderLineItemForm.get('itemQuantity').value,
      sellingPrice: this.purchaseOrderLineItemForm.get('sellingAmount').value,
      totalSellingPrice: totalItemAmt,
      currency: 'TZS'
    });
    this.showAddDialog = false;
  }

  findDuplicates(arr) {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  registerSales() {

    if (this.purchaseOrderHeaderForm.invalid) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please fill all required details on sales order header section.`);
      return;
    }

    if (this.purchaseOrderLineDataSource.length < 1) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, add sales items to continue.`);
      return;
    }

    let counter: any;

    let productCodes = [];

    for (const iterator of this.purchaseOrderLineDataSource) {
      productCodes.push(iterator.stockCode);
    }

    counter = this.findDuplicates(productCodes);

    if (counter.length > 0 ) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, more than one product code(${counter}) of the same type detected.`);
      return;
    }

    if (this.purchaseOrderHeaderForm.get('customerPhone').value === null) {
      this.purchaseOrderHeaderForm.get('customerPhone').patchValue('');
    }

    const data = {
      salesNo: this.purchaseOrderHeaderForm.get('salesNo').value,
      customerName: this.purchaseOrderHeaderForm.get('customerName').value,
      customerPhone: this.purchaseOrderHeaderForm.get('customerPhone').value,
      items: this.purchaseOrderLineDataSource
    }

    for (const iterator of this.purchaseOrderLineDataSource) {
      this.totalAmountAfterVat += iterator.totalSellingPrice;
    }

      this.utilities.postServiceCall(data, 'products/sale').subscribe(res => {
      if(res.statusCode == 2000) {
        this.qrCodeString = JSON.stringify(data);

        this.toastr.success(res.statusMessage);

        this.purchaseOrderLineDataSource = [];

        this.purchaseOrderHeaderForm.reset();

        sessionStorage.removeItem(AppSettings.salesKey);
        sessionStorage.setItem(AppSettings.salesKey, JSON.stringify(res.data));

      const soDate = ("0" + new Date(res.data[0].saleDate).getDate()).slice(-2) + "-" + ("0"+(new Date(res.data[0].saleDate).getMonth()+1)).slice(-2) + "-"+ new Date(res.data[0].saleDate).getFullYear();
      this.salesDate = soDate;
      this.salesBy = res.data[0].createdBy;
      this.salesNumber = res.data[0].saleNo;
      this.customerName = res.data[0].customerName;
      this.customerPhone = res.data[0].customerPhone;
      this.salesOrderNumber = res.data[0].saleNo;
      this.fetchedSalesItemsDataSource = res.data;

        this.utilities.postServiceCall({}, 'unique-number').subscribe(res => {
        this.purchaseOrderHeaderForm.get('salesNo').patchValue(res.number);
        // this.printPurchaseOrder();
        }, error => {
          this.logger(error);
        });

        // this.hidePurchaseOrderForm = true;
        // this.hideSalesOrderForm = false;
        this.storageService.setHidePurchaseOrderForm(true);
        this.storageService.setHideSalesOrderForm(false);

        // public static hidePurchaseOrderFormKey = 'hgjfs';
        // public static hideSalesOrderFormKey = '998787';

        sessionStorage.removeItem(AppSettings.hideDeiveryNoteFormKey);
        sessionStorage.removeItem(AppSettings.hidePurchaseOrderFormKey);
        sessionStorage.removeItem(AppSettings.hideSalesOrderFormKey);

        sessionStorage.setItem(AppSettings.hidePurchaseOrderFormKey, JSON.stringify(true));
        sessionStorage.setItem(AppSettings.hideSalesOrderFormKey, JSON.stringify(false));

        notify('Please wait while system prepare Sales order form for printing', 'info');
        // setTimeout(() => {
        //   this.printPurchaseOrder();
          this.router.navigate(['/printing-area']);
        //  }, 3000);

        // this.printPurchaseOrder();
      } else {
        this.toastr.error(res.statusMessage);
      }
      }, err => {
        this.toastr.error('Failed to register sales, please try again.');
        this.logger(err);
      });
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'New Item',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewPurchaseItem.bind(this)
      }
    });
  }

  previewPurchaseOrderItem(e) {
    this.hideEditActionButton = false;
    this.popUpTitle = 'Update sale item';
    this.purchaseOrderLineItemForm.patchValue(e.data);
    this.purchaseOrderLineItemForm.get('itemName').patchValue(e.data.itemId);
    this.showAddDialog = true;
  }

  onUpdatePurchaseOrderItem() {
    if (this.purchaseOrderLineItemForm.invalid) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please fill some important data that are lest empty to continue.`);
      return;
    }

    if (+this.purchaseOrderLineItemForm.get('itemAvailable').value < this.purchaseOrderLineItemForm.get('itemQuantity').value) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, no enough stock, please check the available item quantity.`);
      return;
    }

    if (this.purchaseOrderLineItemForm.get('itemQuantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, item quantity can not be less than one.`);
      return;
    }

    let counter = 0;

    for (const iterator of this.purchaseOrderLineDataSource) {
      if (this.productCode == iterator.stockCode) {
        counter += 1;
      }
    }

    for (const iterator of this.purchaseOrderLineDataSource) {
      if (this.productCode == iterator.stockCode && counter > 1) {
        this.toastr.error(`Dear ${this.authService.getUserName()}, item with product code (${this.productCode}) is already added.`);
        return;
      }
    }


    for (let index = 0; index < this.purchaseOrderLineDataSource.length; index++) {
      if (this.purchaseOrderLineDataSource[index].id === this.purchaseOrderLineItemForm.get('id').value) {
        this.purchaseOrderLineDataSource[index] = this.purchaseOrderLineItemForm.value;
         let totalItemAmt = this.purchaseOrderLineItemForm.get('itemQuantity').value * +this.purchaseOrderLineItemForm.get('sellingAmount').value;
         this.purchaseOrderLineDataSource[index].totalSellingPrice = totalItemAmt;
         this.purchaseOrderLineDataSource[index].sellingPrice = +this.purchaseOrderLineItemForm.get('sellingAmount').value;
        this.purchaseOrderLineDataSource[index].itemName = this.productName;
        this.showAddDialog = false;
        this.spinner.hide();
        break;
      }
    }
  }

  onDeletePurchaseOrderItem() {
    const result = confirm(
      'Are you sure you want to delete selected item ?',
      'Delete Order Item'
    );
    result.then(dialogResult => {
      if (dialogResult) {

    for (let index = 0; index < this.purchaseOrderLineDataSource.length; index++) {
      if (this.purchaseOrderLineDataSource[index].id === this.purchaseOrderLineItemForm.get('id').value) {
        this.purchaseOrderLineDataSource.splice(index, 1);
        this.showAddDialog = false;
        break;
      }
    }
      }
    });
  }

  previewSales() {
    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
      this.printPurchaseOrder();
    }, 3000);
  }

  salesDelivery() {

    if (this.salesForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    this.utilities.postServiceCall(this.salesForm.value, 'sales/delivery').subscribe(res => {

      if (res.statusCode == 2000) {
        sessionStorage.removeItem(AppSettings.salesKey);
        sessionStorage.setItem(AppSettings.salesKey, JSON.stringify(res.data));
        this.toastr.success(res.statusMessage);
        const soDate = ("0" + new Date(res.data[0].saleDate).getDate()).slice(-2) + "-" + ("0"+(new Date(res.data[0].saleDate).getMonth()+1)).slice(-2) + "-"+ new Date(res.data[0].saleDate).getFullYear();
        this.salesDate = soDate;
        this.salesNumber = res.data[0].saleNo;
        this.customerName = res.data[0].customerName;
        this.customerNumber =  res.data[0].customerPhone;


      this.salesBy = res.data[0].createdBy;
      this.customerName = res.data[0].customerName;
      this.customerPhone = res.data[0].customerPhone;
      this.salesOrderNumber = res.data[0].saleNo;
      this.fetchedSalesItemsDataSource = res.data;
      this.hideSaleStatus = false;
      if (res.data[0].status == 0) {
        this.saleStatus = 'UNPAID';
      } else if(res.data[0].status == 1) {
        this.saleStatus = 'PAID';
      }

      const data = {
        customerName: this.customerName,
        customerPhone: this.customerPhone,
        salesDate: this.salesDate,
        salesBy: this.salesBy,
        salesNumber: this.salesNumber,
        status: res.data[0].status
      };

        this.qrCodeString = JSON.stringify(data);
        this.salesAmount = 0;

        sessionStorage.removeItem(AppSettings.hideDeiveryNoteFormKey);
        sessionStorage.removeItem(AppSettings.hidePurchaseOrderFormKey);
        sessionStorage.removeItem(AppSettings.hideSalesOrderFormKey);

        sessionStorage.setItem(AppSettings.hideDeiveryNoteFormKey, JSON.stringify(true));
        sessionStorage.setItem(AppSettings.hideDeiveryNoteFormKey, JSON.stringify(false));

        notify('Please wait while system prepare Delivery Note form for printing', 'info');
        this.router.navigate(['/printing-area']);
        this.hideApproveControls = false;
      } else {
        this.hideSaleStatus = true;
        this.hideApproveControls = true;
        this.saleStatus = "";
        this.clearData();
        this.toastr.info(res.statusMessage);
      }
    }, err => {
      this.hideSaleStatus = true;
      this.toastr.error(err.message);
    });

  }

  printPurchaseOrder() {
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
    <head>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="assets/css/layout.css" rel="stylesheet" type="text/css">
    <link href="assets/css/themes.css" rel="stylesheet" type="text/css">
    <link href="assets/css/typography.css" rel="stylesheet" type="text/css">
    <link href="assets/css/styles.css" rel="stylesheet" type="text/css">
    <link href="assets/css/shCore.css" rel="stylesheet" type="text/css">
    <link href="assets/css/jquery.jqplot.css" rel="stylesheet" type="text/css">
    <link href="assets/css/jquery-ui-1.8.18.custom.css" rel="stylesheet" type="text/css">
    <link href="assets/css/data-table.css" rel="stylesheet" type="text/css">
    <link href="assets/css/form.css" rel="stylesheet" type="text/css">
    <link href="assets/css/ui-elements.css" rel="stylesheet" type="text/css">
    <link href="assets/css/wizard.css" rel="stylesheet" type="text/css">
    <link href="assets/css/sprite.css" rel="stylesheet" type="text/css">
    <link href="assets/css/gradient.css" rel="stylesheet" type="text/css">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<style>
    body{
     font-size: 14px;
     line-height: 1.42857143;
     color: #333;
   }
   .space{
     display: flex;
     justify-content: space-between;
     // border: 1px dotted #4d88bb;
     padding: 1rem 0;
   }
   .btn_control_block{
     display: flex;
     justify-content: space-evenly;
     align-content: center;
   }
   .unpaid {
    font-weight: bold;
    font-size: larger;
    font-family: monospace;
    color: rgb(129, 2, 2);
    border: 1px dotted rgb(129, 2, 2);
  }

  .paid {
    font-weight: bold;
    font-size: larger;
    font-family: monospace;
    color: #147529;
    border: 1px dotted rgb(0, 82, 18);
  }
   .stripe {
     background-color: rgba(0,0,0,.05);
   }
   .logo__block{
   /* border-bottom: 1px dotted #4d88bb; */
   // border-bottom: 1px dotted rgb(0 172 238);
   }
   .logo__block p, .footer, p {
     /* color: #4d88bb; */
     // color: rgb(0 172 238);
   }
   .invoice__head__child_contacts table tbody tr td{
   /* text-decoration: underline; */
   font-style: italic;
   }
   .kill_margin {
     margin: 0 !important;
     padding: 0 !important;
   }
   .invoice{
     background-color: rgba(0, 0, 0, 0.055);
     padding: 2rem;
     // width: fit-content;
     height: 100%;
     width: 100%;
   }
   .invoice__head,
   .invoice__how_to_pay{
     display: flex;
     justify-content: space-between;
   }
   .invoice__body{
     display: flex;
     /* justify-content: center; */
   }
   .invoice__body table tr th{
     // background: rgb(0 172 238);
     color:white;
     text-align:left;
     vertical-align:center;
   }
   .invoice__body table tr {
     // background-color: rgba(136 ,136 ,136, .1);
     // border: 1px solid #ddd;
     padding: .35em;
   }
   .logo__block{
       text-align: start;
   }
   .footer {
     color:white;
     text-align: center;
   }
   .kill_padding_right{
     padding-right: 0;
   }
   .kill_padding_left{
     padding-left: 0;
   }
   .center_para {
     text-align: center;
   }
   .full_table{
     width: 100%;
   }

   table tbody tr td{
   padding: .5rem;
   }

   @media (max-width: 576px) {
     .btn_control_block{
       display: flex;
       flex-direction: row;
     }
    }

   @media print {
   @page {
     size: A4;
     margin: 0mm;
   }

   html, body {
     width: 1024px;
   }

   body {
     margin: 0 auto;
     line-height: 1em;
     word-spacing:2px;
     letter-spacing:0.2px;
     /* font: 14px "Times New Roman", Times, serif; */
     font-size: 17px;
     background:rgba(0, 0, 0, 0.055);
     color:black;
     width: 100%;
     float: none;
   }
   .invoice__body table tr th{
     padding: 5px;
   }
   .footer{
     position: absolute;
     bottom: 0;
     width: 100%;
     text-align: center;
   }
   .footer2{
     position: absolute;
     bottom: 2%;
     width: 100%;
     text-align: center;
     color: #000;
   }
   .invoice{
     background-color: transparent;
   }

   /* avoid page-breaks inside a listingContainer*/
   .listingContainer{
     page-break-inside: avoid;
   }
   h1 {
       font: 28px "Times New Roman", Times, serif;
     }

     h2 {
       font: 24px "Times New Roman", Times, serif;
     }

     h3 {
       font: 20px "Times New Roman", Times, serif;
     }

     /* Improve colour contrast of links */
     a:link, a:visited {
       color: #781351
     }
     /* URL */
     a:link, a:visited {
       background: transparent;
       color:#333;
       text-decoration:none;
     }

     a[href]:after {
       content: "" !important;
     }
     a[href^="http://"] {
       color:#000;
     }

     #header {
       height:75px;
       font-size: 24pt;
       color:black
     }
   }
    </style>
    </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }
}
