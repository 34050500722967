import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.scss']
})
export class StockManagementComponent extends SharedClassComponent  implements OnInit {

  tab_paneldata: any = [
    {
      ID: 1,
      name: 'New Stock',
    },
    {
      ID: 2,
      name: 'Stock Categories',
    }
  ];

  now = new Date();
  searchModeOption: string = "contains";
  searchExprOption: any = "categoryName";
  searchTimeoutOption: number = 200;
  minSearchLengthOption: number = 0;
  stockOrderLineDataSource = [];
  stockCategoryDataSource = [];
  purchaseOrderForm: FormGroup;
  purchaseOrderLineItemForm: FormGroup;
  supplierName = '';

  poNumber: any;

  showAddDialog = false;
  showAddDialog2 = false;
  popUpTitle = 'Add New Stock/product Category';
  invoiceBillItems = [];
  totalVat = 0;
  totalAmountBeforeVat = 0;
  totalAmountAfterVat = 0;

  invoiceHeader: any;
  hidePurchaseOrderForm = true;
  hideEditActionButton = true;
  availableStokeQty = 0;

  title = 'Stock Category Managemnt';
  storesDataSource = [];
  hideStoreStatus = true;
  storeLocation = '';
  storeId: any;
  itemName: any;

  fetchedPoLineDatasource = [];

  currencyDataSource = [
    {
      id: 1,
      text: 'TZS'
    }
  ];

  vatDataSource = [
    {
      id: 1,
      text: 'Standard Rate 18%'
    }
  ];

  hideApproveControls = true;

  purchaseOrderLineDataSource = [];

  ngOnInit() {
    this.observerCall();
    this.getAllStockCategories();
    this.getAllStores()
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      categoryName: new FormControl('', Validators.compose([Validators.required])),
      itemSize: new FormControl(null, Validators.compose([])),
      unitPrice: new FormControl(null, Validators.compose([])),
      currency: new FormControl(this.currencyDataSource[0].id, Validators.compose([Validators.required])),
      sellingPriceVat: new FormControl(null, Validators.compose([])),
      sellingPrice: new FormControl(null, Validators.compose([Validators.required])),
      unitCost: new FormControl(null, Validators.compose([Validators.required])),
      stockCode: new FormControl('', Validators.compose([Validators.required])),
      storeId: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([Validators.required])),
      vat: new FormControl(this.vatDataSource[0].id, Validators.compose([])),
      status: new FormControl('', Validators.compose([]))
    });



    this.purchaseOrderForm = new FormGroup({
      poNumber: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.purchaseOrderLineItemForm = new FormGroup({
      id: new FormControl(null, Validators.compose([Validators.required])),
      itemName: new FormControl(null, Validators.compose([Validators.required])),
      itemSize: new FormControl(null, Validators.compose([])),
      itemQuantity: new FormControl(null, Validators.compose([Validators.required])),
      itemSpecs: new FormControl(null, Validators.compose([])),
      storeLoc: new FormControl(null, Validators.compose([Validators.required])),
      stockCode: new FormControl(null, Validators.compose([Validators.required])),
      availableStock: new FormControl(null, Validators.compose([])),
      discountPercent: new FormControl(0, Validators.compose([])),
      unitPrice: new FormControl(null, Validators.compose([Validators.required])),
      currency: new FormControl(null, Validators.compose([Validators.required])),
      vat: new FormControl(null, Validators.compose([Validators.required])),
      vatAmt: new FormControl(null, Validators.compose([])),
      lineTotal: new FormControl(null, Validators.compose([])),
      net: new FormControl(null, Validators.compose([]))
    });

    this.purchaseOrderLineItemForm = new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      itemName: new FormControl(null, Validators.compose([Validators.required])),
      itemSize: new FormControl('', Validators.compose([])),
      itemQuantity: new FormControl(null, Validators.compose([Validators.required])),
      itemSpecs: new FormControl('', Validators.compose([])),
      storeLoc: new FormControl('', Validators.compose([])),
      stockCode: new FormControl(null, Validators.compose([Validators.required])),
      availableStock: new FormControl(this.availableStokeQty, Validators.compose([])),
      discountPercent: new FormControl(0, Validators.compose([])),
      unitPrice: new FormControl(null, Validators.compose([Validators.required])),
      sellingPrice: new FormControl(null, Validators.compose([Validators.required])),
      sellingPriceVat: new FormControl(true, Validators.compose([Validators.required])),
      currency: new FormControl(this.currencyDataSource[0].id, Validators.compose([Validators.required])),
      vat: new FormControl(this.vatDataSource[0].id, Validators.compose([Validators.required])),
      vatAmt: new FormControl(null, Validators.compose([])),
      lineTotal: new FormControl(null, Validators.compose([])),
      net: new FormControl(null, Validators.compose([]))
    });

  }

  onStockCategoryValueChanged(e) {
    for (const iterator of this.stockCategoryDataSource) {
      if(iterator.id == e.value) {
        this.availableStokeQty = iterator.stockItemsQty;
        this.purchaseOrderLineItemForm.get('availableStock').patchValue(iterator.stockItemsQty);
        this.purchaseOrderLineItemForm.get('storeLoc').patchValue(iterator.stockStore.storeName);
        this.purchaseOrderLineItemForm.get('itemName').patchValue(iterator.categoryName);
        this.storeLocation = iterator.stockStore.storeName;
        this.storeId = iterator.stockStore.id;
        break;
      }
    }
  }

  addNewPurchaseItem() {
    this.hideEditActionButton = true;
    this.showAddDialog2 = true;
    this.purchaseOrderLineItemForm.reset();
    this.purchaseOrderLineItemForm.get('itemSize').patchValue('');
    this.purchaseOrderLineItemForm.get('itemSpecs').patchValue('');
    this.purchaseOrderLineItemForm.get('storeLoc').patchValue('');
    this.purchaseOrderLineItemForm.get('sellingPriceVat').patchValue(true);
    this.purchaseOrderLineItemForm.get('currency').patchValue(this.currencyDataSource[0].id);
    this.purchaseOrderLineItemForm.get('vat').patchValue(this.vatDataSource[0].id);
  }

  addNewPurchaseLineItem() {

    if (this.purchaseOrderLineItemForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserName()} please fill all required fields.`)
      return;
    }

    if (this.purchaseOrderLineItemForm.get('itemQuantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, item quantity can not be less than one.`)
      return;
    }

    const id = this.stockOrderLineDataSource.length + 1;
    this.purchaseOrderLineItemForm.get('id').patchValue(id);
    const currency = this.purchaseOrderLineItemForm.get('currency').value;
    currency === 1 ? this.purchaseOrderLineItemForm.get('currency').patchValue('TZS') : this.purchaseOrderLineItemForm.get('currency').patchValue('USD');
    let discountPercent = 0;
    let sellingPrice = 0;
    let sellingPriceHoldingVat = 0;
    let sellingPriceVAT = 0;

    if (this.purchaseOrderLineItemForm.get('sellingPriceVat').value === true) {
      sellingPriceVAT = 18;
      sellingPriceHoldingVat = this.purchaseOrderLineItemForm.get('sellingPrice').value * (18/100);
      sellingPrice = this.purchaseOrderLineItemForm.get('sellingPrice').value + sellingPriceHoldingVat;
    } else {
      sellingPriceHoldingVat = 0;
      sellingPrice = this.purchaseOrderLineItemForm.get('sellingPrice').value + sellingPriceHoldingVat;
    }


    let totalItemAmt = this.purchaseOrderLineItemForm.get('itemQuantity').value * this.purchaseOrderLineItemForm.get('unitPrice').value;

    if (this.purchaseOrderLineItemForm.get('discountPercent').value !== null) {
      if (this.purchaseOrderLineItemForm.get('discountPercent').value > 0) {
          discountPercent = this.purchaseOrderLineItemForm.get('discountPercent').value;
          totalItemAmt -= (totalItemAmt * (discountPercent/100));
      }
    }

    if (this.purchaseOrderLineItemForm.get('availableStock').value == null) {
      this.purchaseOrderLineItemForm.get('availableStock').patchValue(0);
    }

    const totalVat = totalItemAmt * (18/100);
    const lineTotal = totalVat + totalItemAmt;

    this.stockOrderLineDataSource.push({
      id: this.purchaseOrderLineItemForm.get('id').value,
      itemName: this.purchaseOrderLineItemForm.get('itemName').value,
      itemSize: this.purchaseOrderLineItemForm.get('itemSize').value,
      itemQuantity: this.purchaseOrderLineItemForm.get('itemQuantity').value,
      itemSpecs: this.purchaseOrderLineItemForm.get('itemSpecs').value,
      storeLoc: this.purchaseOrderLineItemForm.get('storeLoc').value,
      stockCode: this.purchaseOrderLineItemForm.get('stockCode').value,
      availableStock: this.purchaseOrderLineItemForm.get('availableStock').value,
      discountPercent: discountPercent,
      storeId: this.storeId,
      sellingPrice: this.purchaseOrderLineItemForm.get('sellingPrice').value,
      totalSellingPrice: sellingPrice,
      sellingPriceVAT: sellingPriceVAT,
      sellingPriceVatAmt: sellingPriceHoldingVat,
      unitPrice: this.purchaseOrderLineItemForm.get('unitPrice').value,
      currency: this.purchaseOrderLineItemForm.get('currency').value,
      net: totalItemAmt,
      vat: '18%',
      vatAmt: totalVat,
      lineTotal: lineTotal
    });
    this.showAddDialog2 = false;

    // this.purchaseOrderLineItemForm.reset();

    this.purchaseOrderLineItemForm.get('currency').patchValue(this.currencyDataSource[0].id);
    this.purchaseOrderLineItemForm.get('vat').patchValue(this.vatDataSource[0].id);
  }

  verifyPurchaseOrderNumber() {
    if (this.purchaseOrderForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    const data = {
      poNumber: this.purchaseOrderForm.get('poNumber').value
    };

    this.utilities.postServiceCall(data, 'purchase-order/po').subscribe(res => {

      if (res.statusCode == 2000) {

        if (res.data.header.status == 0) {
          this.toastr.error('Purchase Order not approved.');
          this.hideApproveControls = true;
          this.fetchedPoLineDatasource = [];
          return;
        }

        if (res.data.header.status == 2) {
          this.toastr.error('Purchase Order Cancelled.');
          this.hideApproveControls = true;
          this.fetchedPoLineDatasource = [];
          return;
        }

        if (res.data.header.status == 3) {
          this.toastr.error('Purchase order already used and stock registered.');
          this.hideApproveControls = true;
          this.fetchedPoLineDatasource = [];
          return;
        }

        this.toastr.success(res.statusMessage);
        this.fetchedPoLineDatasource = res.data.line;
        this.hideApproveControls = false;
      } else {
        this.fetchedPoLineDatasource = [];
        this.hideApproveControls = true;
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error(err.message);
    });
  }



  updateStore() {}

  registerStock() {
    if (this.purchaseOrderForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    const data = {
      poNumber: this.purchaseOrderForm.get('poNumber').value
    };

    this.utilities.postServiceCall(data, 'stock/register').subscribe(res => {

      if (res.statusCode == 2000) {
        this.toastr.success(res.statusMessage);
        this.fetchedPoLineDatasource = [];
        this.hideApproveControls = true;
        this.purchaseOrderForm.reset();
      } else {
        // this.fetchedPoLineDatasource = [];
        // this.hideApproveControls = true;
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error(err.message);
    });
  }

  getAllStockCategories() {
    this.utilities.postServiceCall({}, 'stockCategory/all').subscribe(res => {
      this.stockCategoryDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  getAllStores() {
    this.utilities.postServiceCall({}, 'store/all').subscribe(res => {
      this.storesDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  addNewStockCategory() {
    this.hideEditActionButton = true;
    this.showAddDialog = true;
    this.hideStoreStatus = true;
    this.paramwinfrm.reset();
  }

  previewStockCategory(e) {
    this.hideEditActionButton = false;
    this.hideStoreStatus = false;
    this.popUpTitle = 'Update Stock Category';
    this.showAddDialog = true;
    this.paramwinfrm.patchValue(e.data);
    this.paramwinfrm.get('storeId').patchValue(e.data.stockStore.id);
    this.paramwinfrm.get('id').patchValue(e.data.id);
    this.paramwinfrm.get('stockCode').patchValue(e.data.categoryCode);
    this.paramwinfrm.get('sellingPrice').patchValue(e.data.unitPriceFlat);
    this.paramwinfrm.get('unitCost').patchValue(e.data.unitCostFlat);
    this.paramwinfrm.get('currency').patchValue(this.currencyDataSource[0].id);
    if (e.data.status == 1) {
      this.paramwinfrm.get('status').patchValue(true);
    } else{
      this.paramwinfrm.get('status').patchValue(false);
    }

    }

    createStockCategory(): void {

      if (this.paramwinfrm.invalid) {
        this.toastr.error('Please fill all required details');
        return;
      }

    const currency = this.paramwinfrm.get('currency').value;
    currency === 1 ? this.paramwinfrm.get('currency').patchValue('TZS') : this.paramwinfrm.get('currency').patchValue('USD');
    let discountPercent = 0;
    let sellingPrice = 0;
    let unitCost = 0;
    let sellingPriceHoldingVat = 0;
    let unitCostHoldingVat = 0;
    let sellingPriceVAT = 18;

      sellingPriceHoldingVat = +this.paramwinfrm.get('sellingPrice').value * (sellingPriceVAT/100);
      sellingPrice = +this.paramwinfrm.get('sellingPrice').value + sellingPriceHoldingVat;


      unitCostHoldingVat = +this.paramwinfrm.get('unitCost').value * (sellingPriceVAT/100);
      unitCost = +this.paramwinfrm.get('unitCost').value + sellingPriceHoldingVat;

      if (this.paramwinfrm.get('itemSize').value === null) {
        this.paramwinfrm.get('itemSize').patchValue('');
      }
      if (this.paramwinfrm.get('description').value === null) {
        this.paramwinfrm.get('description').patchValue('');
      }

      const data = {
        categoryName: this.paramwinfrm.get('categoryName').value,
        stockCode: this.paramwinfrm.get('stockCode').value,
        unitSellPrice: sellingPrice,
        itemSize: this.paramwinfrm.get('itemSize').value,
        unitCost: unitCost,
        unitCostVat: unitCostHoldingVat,
        unitPriceVat: sellingPriceHoldingVat,
        description: this.paramwinfrm.get('description').value,
        storeId: this.paramwinfrm.get('storeId').value,
        unitPriceFlat: +this.paramwinfrm.get('sellingPrice').value,
        unitCostFlat: +this.paramwinfrm.get('unitCost').value
      }

      this.utilities.postServiceCall(data, 'stockCategory/add').subscribe(res => {
        const response = res;
        if (response.statusCode == 2000) {
          this.toastr.success(response.statusMessage);
          this.showAddDialog = false;
          this.getAllStockCategories();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    updateStockCategory() {

      if (this.paramwinfrm.invalid) {
        this.toastr.error('Please fill all required details');
        return;
      }

    const currency = this.paramwinfrm.get('currency').value;
    currency === 1 ? this.paramwinfrm.get('currency').patchValue('TZS') : this.paramwinfrm.get('currency').patchValue('USD');
    let discountPercent = 0;
    let sellingPrice = 0;
    let unitCost = 0;
    let sellingPriceHoldingVat = 0;
    let unitCostHoldingVat = 0;
    let sellingPriceVAT = 18;

      sellingPriceHoldingVat = +this.paramwinfrm.get('sellingPrice').value * (sellingPriceVAT/100);
      sellingPrice = +this.paramwinfrm.get('sellingPrice').value + sellingPriceHoldingVat;


      unitCostHoldingVat = +this.paramwinfrm.get('unitCost').value * (sellingPriceVAT/100);
      unitCost = +this.paramwinfrm.get('unitCost').value + sellingPriceHoldingVat;

      this.logger('Selling Price: ' + sellingPrice);
      this.logger('Cost Price: ' + unitCost);

      this.logger('Selling Price: form: ' + this.paramwinfrm.get('sellingPrice').value);
      this.logger('Cost Price: form: ' + this.paramwinfrm.get('unitCost').value);

      if (this.paramwinfrm.get('itemSize').value === null) {
        this.paramwinfrm.get('itemSize').patchValue('');
      }
      if (this.paramwinfrm.get('description').value === null) {
        this.paramwinfrm.get('description').patchValue('');
      }
      if (this.paramwinfrm.get('status').value === true) {
        this.paramwinfrm.get('status').patchValue(1);
      } else {
        this.paramwinfrm.get('status').patchValue(0);
      }

      const data = {
        categoryId: this.paramwinfrm.get('id').value,
        categoryName: this.paramwinfrm.get('categoryName').value,
        stockCode: this.paramwinfrm.get('stockCode').value,
        unitSellPrice: sellingPrice,
        itemSize: this.paramwinfrm.get('itemSize').value,
        unitCost: unitCost,
        unitCostVat: unitCostHoldingVat,
        unitPriceVat: sellingPriceHoldingVat,
        description: this.paramwinfrm.get('description').value,
        storeId: this.paramwinfrm.get('storeId').value,
        unitPriceFlat: this.paramwinfrm.get('sellingPrice').value,
        unitCostFlat: this.paramwinfrm.get('unitCost').value,
        status: this.paramwinfrm.get('status').value
      }

      this.utilities.postServiceCall(data, 'stockCategory/update').subscribe(res => {
        const response = res;
        if (response.statusCode == 2000) {
          this.toastr.success(response.statusMessage);
          this.showAddDialog = false;
          this.getAllStockCategories();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }


  customizeText1(data) {
    return "Total Items: " +  data.value;
  }
  customizeText2(data) {
    return "Total Vat Amount: " + data.value;
  }
  customizeText3(data) {
    return "Total amount before VAT: " + data.value;
  }
  customizeText4(data) {
    return "Total amount after VAT: " + data.value;
  }

  previewPurchaseOrderItem(e) {
    this.hideEditActionButton = false;
    // this.popUpTitle = 'Update order item';
    this.purchaseOrderLineItemForm.patchValue(e.data);
    this.showAddDialog2 = true;
  }

  createStock() {

    if (this.stockOrderLineDataSource.length < 1) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please add Stock items in the list to proceed.`);
      return;
    }

    const data = {
      line: this.stockOrderLineDataSource
    }

    this.utilities.postServiceCall(data, 'stock/add').subscribe(res => {

    this.toastr.success(res.statusMessage, 'New Stock');

    this.stockOrderLineDataSource = [];

    }, err => {
      this.toastr.error('Failed to add new Stock, please try again.');
      this.logger(err);
    });

  }

  onUpdatePurchaseOrderItem() {
    if (this.purchaseOrderLineItemForm.invalid) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please fill some important data that are lest empty to continue.`);
      return;
    }

    for (let index = 0; index < this.stockOrderLineDataSource.length; index++) {
      if (this.stockOrderLineDataSource[index].id === this.purchaseOrderLineItemForm.get('id').value) {
        this.stockOrderLineDataSource[index] = this.purchaseOrderLineItemForm.value;
        this.stockOrderLineDataSource[index].currency = this.purchaseOrderLineItemForm.get('currency').value === 1 ? 'TZS' : 'USD';
        this.stockOrderLineDataSource[index].vat = this.purchaseOrderLineItemForm.get('vat').value === 1 ? '18%' : '18%';
        let discountPercent = 0;
        let totalItemAmt = this.purchaseOrderLineItemForm.get('itemQuantity').value * this.purchaseOrderLineItemForm.get('unitPrice').value;

        if (this.purchaseOrderLineItemForm.get('discountPercent').value !== null) {
          if (this.purchaseOrderLineItemForm.get('discountPercent').value > 0) {
              discountPercent = this.purchaseOrderLineItemForm.get('discountPercent').value;
              totalItemAmt -= (totalItemAmt * (discountPercent/100));
          }
        }

        if (this.purchaseOrderLineItemForm.get('availableStock').value == null) {
          this.purchaseOrderLineItemForm.get('availableStock').patchValue(0);
        }

        const totalVat = totalItemAmt * (18/100);
        const lineTotal = totalVat + totalItemAmt;

        this.stockOrderLineDataSource[index].vatAmt = totalVat;
        this.stockOrderLineDataSource[index].net = totalItemAmt;
        this.stockOrderLineDataSource[index].lineTotal = lineTotal;

        this.showAddDialog2 = false;
        this.spinner.hide();
        break;
      }
    }
  }

  onDeletePurchaseOrderItem() {
    const result = confirm(
      'Are you sure you want to delete selected item ?',
      'Delete Order Item'
    );
    result.then(dialogResult => {
      if (dialogResult) {

    for (let index = 0; index < this.stockOrderLineDataSource.length; index++) {
      if (this.stockOrderLineDataSource[index].id === this.purchaseOrderLineItemForm.get('id').value) {
        this.stockOrderLineDataSource.splice(index, 1);
        this.showAddDialog2 = false;
        break;
      }
    }
      }
    });
  }


  onToolBarPreparing(e): void {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewStockCategory.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getAllStockCategories.bind(this)
        }
      }
    );
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'New Item',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewPurchaseItem.bind(this)
      }
    });
  }

}
