import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { LoginFormComponent } from './shared/components';
import { AuthGuardService } from './shared/services';
import { HomeComponent } from './pages/home/home.component';
import { DisplayDataComponent } from './pages/display-data/display-data.component';
import {
  DxDataGridModule,
  DxFormModule,
  DxContextMenuModule,
  DxMenuModule,
  DxTextBoxModule,
  DxDateBoxModule,
  DxButtonModule,
  DxPopupModule,
  DxActionSheetModule,
  DxFileUploaderModule,
  DxNumberBoxModule,
  DxCheckBoxModule,
  DxSelectBoxModule,
  DxTextAreaModule,
  DxScrollViewModule,
  DxHtmlEditorModule,
  DxDropDownBoxModule,
  DxTagBoxModule,
  DxRadioGroupModule,
  DxTabPanelModule,
  DxBoxModule,
  DxTemplateModule,
  DxValidatorModule,
  DxValidationSummaryModule,
  DxSpeedDialActionModule,
  DxLoadIndicatorModule,
  DxChartModule,
  DxListModule,
  DxSwitchModule,
  DxToolbarModule,
  DxPieChartModule,
  DxAccordionModule,
  DxSliderModule,
  DxLoadPanelModule,
} from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxBarcodeModule } from 'ngx-barcode';

import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from './shared/services/utilities.service';
import { SharedClassComponent } from './configurations/shared-class/shared-class.component';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { ModalDialogModule } from 'ngx-modal-dialog';
import { TemporaryLoginComponent } from './shared/components/temporary-login/temporary-login.component';
import { StoreModule} from '@ngrx/store';
import { AnQrcodeModule } from 'an-qrcode';
import { ForgetPasswordComponent } from './shared/components/forget-password/forget-password.component';
import { PurchaseOrderComponent } from './pages/purchase-order/purchase-order.component';
import { StockManagementComponent } from './pages/stock-management/stock-management.component';
import { SupplierManagementComponent } from './pages/supplier-management/supplier-management.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { SettingsManagementComponent } from './pages/settings-management/settings-management.component';
import { ExpensesManagementComponent } from './pages/expenses-management/expenses-management.component';
import { RolesManagementComponent } from './pages/roles-management/roles-management.component';
import { AccountingManagementComponent } from './pages/accounting-management/accounting-management.component';
import { SalesManagementComponent } from './pages/sales-management/sales-management.component';
import { ReportManagementComponent } from './report-management/report-management.component';
import { AnalyticsComponent } from './pages/analytics/analytics.component';
import { DepartmentManagementComponent } from './pages/department-management/department-management.component';
import { PrintingPageComponent } from './pages/printing-page/printing-page.component';

import { RegisterCostComponent } from './views/sales/cost-center/register-cost.component';
import { RegisterSalesComponent } from './views/sales/register-sales/register-sales.component';
import { NewStockComponent } from './views/stock/new-stock/new-stock.component';
import { StockCategoryComponent } from './views/stock/stock-category/stock-category.component';
import { SuppliersComponent } from './views/stock/suppliers/suppliers.component';
import { NewPurchaseOrderComponent } from './views/purchaseOrder/new-purchase-order/new-purchase-order.component';
import { ApprovePurchaseOrderComponent } from './views/purchaseOrder/approve-purchase-order/approve-purchase-order.component';
import { AmendPurchaseOrderComponent } from './views/purchaseOrder/amend-purchase-order/amend-purchase-order.component';
import { PendingPurchaseOrderComponent } from './views/purchaseOrder/pending-purchase-order/pending-purchase-order.component';
import { CompletedPurchaseOrderComponent } from './views/purchaseOrder/completed-purchase-order/completed-purchase-order.component';
import { AddExpensesComponent } from './views/expenses/add-expenses/add-expenses.component';
import { ExpensesCategoryComponent } from './views/expenses/expenses-category/expenses-category.component';
import { ApproveExpensesComponent } from './views/expenses/approve-expenses/approve-expenses.component';
import { SecurityManagerComponent } from './views/appManager/security-manager/security-manager.component';
import { StoreManagementComponent } from './pages/store-management/store-management.component';
import { AccountManagementComponent } from './views/accounting/account-management/account-management.component';
import { AccountOperationsComponent } from './views/accounting/account-operations/account-operations.component';
import { FundTransferComponent } from './views/accounting/fund-transfer/fund-transfer.component';
import { ApproveSalesComponent } from './views/sales/approve-sales/approve-sales.component';
import { DeliverSaleStockComponent } from './views/sales/deliver-sale-stock/deliver-sale-stock.component';
import { DailySalesComponent } from './views/sales/daily-sales/daily-sales.component';
import { RunningAccountsComponent } from './views/running-accounts/running-accounts.component';
import { SalesReportComponent } from './views/reports/sales-report/sales-report.component';
import { DoubleEntryReportComponent } from './views/reports/double-entry-report/double-entry-report.component';
import { ExpensesReportComponent } from './views/reports/expenses-report/expenses-report.component';
import { PurchaseOrderReportComponent } from './views/reports/purchase-order-report/purchase-order-report.component';
import { VatReturnReportComponent } from './views/reports/vat-return-report/vat-return-report.component';
import { CashDisbursementReportComponent } from './views/reports/cash-disbursement-report/cash-disbursement-report.component';
import { DiscountAllowedReportComponent } from './views/reports/discount-allowed-report/discount-allowed-report.component';
import { DiscountReceivedReportComponent } from './views/reports/discount-received-report/discount-received-report.component';
import { SupplierPaymentReportComponent } from './views/reports/supplier-payment-report/supplier-payment-report.component';
import { CancelledSalesPaymentReportComponent } from './views/reports/cancelled-sales-payment-report/cancelled-sales-payment-report.component';
import { CancelledPurchaseOrderReportComponent } from './views/reports/cancelled-purchase-order-report/cancelled-purchase-order-report.component';
import { UploadExcelFileComponent } from './views/upload-excel-file/upload-excel-file.component';
import { EndOfTheDayReportComponent } from './views/reports/end-of-the-day-report/end-of-the-day-report.component';
import { CloseDayOperationComponent } from './views/close-day-operation/close-day-operation.component';
import { ProductSalesReportComponent } from './views/reports/product-sales-report/product-sales-report.component';
import { SmsReportComponent } from './views/reports/sms-report/sms-report.component';
import { AdjustStokeApprovalComponent } from './views/adjust-stoke-approval/adjust-stoke-approval.component';
import { StockAdjustmentReportComponent } from './views/reports/stock-adjustment-report/stock-adjustment-report.component';
import { StockAdjustmentComponent } from './views/stock-adjustment/stock-adjustment.component';
import { UnitsManagementComponent } from './screens/units-management/units-management.component';
import { AssetsReportComponent } from './views/assets-report/assets-report.component';
import { CostReportComponent } from './views/sales/cost-report/cost-report/cost-report.component';
import { UnitReportComponent } from './views/sales/unit-report/unit-report/unit-report.component';
import { AllCostReportComponent } from './views/sales/all-cost-center-report/all-cost-report/all-cost-report.component';
import { WelcomeComponent } from './views/sales/landing-home/welcome.component';


const routes: Routes = [
  {
   path: 'register-cost',
   component: RegisterCostComponent,
   canActivate: [AuthGuardService]
  },

  {
    path: 'register-asset',
    component: RegisterSalesComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'available-units',
    component: DailySalesComponent,
    canActivate: [AuthGuardService]
  },
 
  {
    path: "welcome",
    component: WelcomeComponent,
    canActivate: [AuthGuardService]
  },
 
  {
    path: 'change-password',
    component: SecurityManagerComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-management',
    component: UserManagementComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'asset-report',
    component: AssetsReportComponent,
    canActivate: [AuthGuardService]
  },
  {
   path: 'cost-report',
   component: CostReportComponent,
   canActivate: [AuthGuardService]
  },
  {
    path : 'unit-report',
    component: UnitReportComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'all-cost-report',
    component: AllCostReportComponent,
    canActivate: [AuthGuardService] 
  },
  {
    path: 'login',
    component: LoginFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '**',
    redirectTo: 'register-villa',
    canActivate: [AuthGuardService]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),
    DxDataGridModule,
    DxFormModule,
    DxToolbarModule,
    DxContextMenuModule,
    DxMenuModule,
    FormsModule,
    ReactiveFormsModule,
    DxTextBoxModule,
    DxDateBoxModule,
    DxButtonModule,
    DxDataGridModule,
    CommonModule,
    DxPopupModule,
    DxActionSheetModule,
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxCheckBoxModule,
    DxSelectBoxModule,
    DxTextAreaModule,
    DxContextMenuModule,
    DxMenuModule,
    DxScrollViewModule,
    DxHtmlEditorModule,
    NgbModalModule,
    DxTabPanelModule,
    DxBoxModule,
    DxDropDownBoxModule,
    DxTagBoxModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxTemplateModule,
    DxSpeedDialActionModule,
    DxLoadIndicatorModule,
    DxListModule,
    NgHttpLoaderModule.forRoot(),
    ModalDialogModule.forRoot(),
    DxRadioGroupModule,
    DxChartModule,
    AnQrcodeModule,
    DxSwitchModule,
    DxPieChartModule,
    DxAccordionModule,
    DxSliderModule,
    DxLoadPanelModule,
    NgxBarcodeModule
  ],
  providers: [AuthGuardService, UtilitiesService],
  exports: [RouterModule],
  declarations: [
    UnitsManagementComponent,
    HomeComponent,
    DisplayDataComponent,
    SharedClassComponent,
    TemporaryLoginComponent,
    ForgetPasswordComponent,
    PurchaseOrderComponent,
    StockManagementComponent,
    SupplierManagementComponent,
    UserManagementComponent,
    SettingsManagementComponent,
    ExpensesManagementComponent,
    RolesManagementComponent,
    AccountingManagementComponent,
    SalesManagementComponent,
    ReportManagementComponent,
    AnalyticsComponent,
    DepartmentManagementComponent,
    PrintingPageComponent,
    RegisterCostComponent,
    RegisterSalesComponent,
    CostReportComponent,
    NewStockComponent,
    StockCategoryComponent,
    SuppliersComponent,
    NewPurchaseOrderComponent,
    ApprovePurchaseOrderComponent,
    AmendPurchaseOrderComponent,
    PendingPurchaseOrderComponent,
    CompletedPurchaseOrderComponent,
    AddExpensesComponent,
    ExpensesCategoryComponent,
    ApproveExpensesComponent,
    SecurityManagerComponent,
    StoreManagementComponent,
    AccountManagementComponent,
    AccountOperationsComponent,
    FundTransferComponent,
    ApproveSalesComponent,
    DeliverSaleStockComponent,
    DailySalesComponent,
    RunningAccountsComponent,
    SalesReportComponent,
    DoubleEntryReportComponent,
    ExpensesReportComponent,
    PurchaseOrderReportComponent,
    VatReturnReportComponent,
    CashDisbursementReportComponent,
    DiscountAllowedReportComponent,
    DiscountReceivedReportComponent,
    SupplierPaymentReportComponent,
    CancelledSalesPaymentReportComponent,
    CancelledPurchaseOrderReportComponent,
    UploadExcelFileComponent,
    EndOfTheDayReportComponent,
    CloseDayOperationComponent,
    ProductSalesReportComponent,
    SmsReportComponent,
    AdjustStokeApprovalComponent,
    StockAdjustmentReportComponent,
    StockAdjustmentComponent,
    AssetsReportComponent,
    UnitReportComponent,
    AllCostReportComponent,
    WelcomeComponent 


  ]
})
export class AppRoutingModule {}
