import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
// import XLSX from 'xlsx-style/dist/xlsx';
import { confirm } from 'devextreme/ui/dialog';
import csv2json from 'csvjson-csv2json/csv2json';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-upload-excel-file',
  templateUrl: './upload-excel-file.component.html',
  styleUrls: ['./upload-excel-file.component.scss']
})
export class UploadExcelFileComponent extends SharedClassComponent implements OnInit {

  ngOnInit() {
  }

}