import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';

@Component({
  selector: 'app-approve-purchase-order',
  templateUrl: './approve-purchase-order.component.html',
  styleUrls: ['./approve-purchase-order.component.scss']
})
export class ApprovePurchaseOrderComponent extends SharedClassComponent implements OnInit {

  title = 'Approve Purchase Order';
  purchaseOrderHeaderForm: FormGroup;
  purchaseOrderLineItemForm: FormGroup;
  purchaseOrderForm: FormGroup;

  currentYear = new Date().getFullYear();
  qrCodeString = '';

  now = new Date();

  suppilersData = [];

  stockCategoryDataSource = [];

  paymentMethod = [
    {
      ID: 1,
      Name: 'Cash'
    },
    {
      ID: 2,
      Name: 'Transfer'
    }
  ];

  currencyDataSource = [
    {
      id: 1,
      text: 'TZS'
    },
    {
      id: 2,
      text: 'USD'
    }
  ];

  storeLocationDataSource = [];

  vatDataSource = [
    {
      id: 1,
      text: 'Standard Rate 18%'
    }
  ];

  poNumber: any;

  purchaseOrderLineDataSource = [];
  showAddDialog = false;
  popUpTitle = 'Add New Purchase Order Item';
  invoiceBillItems = [];
  totalVat = 0;
  totalAmountBeforeVat = 0;
  totalAmountAfterVat = 0;

  invoiceHeader: any;
  hideEditActionButton = true;

  departmentDataSource = [];
  purchaseOrderResultDatasource = [];

  availableStokeQty = 0;
  storeLocation = '';

  searchModeOption: string = "contains";
  searchExprOption: any = "categoryName";
  searchTimeoutOption: number = 200;
  minSearchLengthOption: number = 0;
  supplierContacts = '';
  countries = [];
  countryCode = '';
  storeId: any;

  // purchase order fetched details

  fetchedPoNumber: any;
  poDate: any;
  supplier: any;
  requestedBy: any;
  expectedDeliveryDate: any;
  glAccount: any;
  paymentMerhod: any;
  department: any;
  requesterContact: any;
  billAddress: any;
  address: any;
  postCode: any;
  country: any;
  fetchedPoCountryCode: any;
  city: any;
  poSpecialNote: any;
  fetchedPoLineDatasource = [];
  completedPurchaseOrders = [];
  pendingPurchaseOrders = [];

  hideApproveControls = true;
  productCode: any;
  productName: any;
  productSize: any;
  productUnitCostFlat: any;
  productUnitPriceFlat: any;
  productDescription: any;
  discountAmount: any;



  ngOnInit() {
    this.appInfo.setTitle(this.title);
    const data = {};
    this.countries = this.storageService.getAllCountries();
    this.getAllDepartments();
    this.getAllStores();
    this.getAllStockCategories();
    this.getAllCompletedPurchaseOrders();
    this.utilities.postServiceCall(data, 'unique-number').subscribe(res => {
      this.uniqueNumber = res.number;
    }, error => {
      this.logger(error);
    });
    this.getAllSuppliers();
    this.observerCall();

    this.invoiceHeader = JSON.parse(sessionStorage.getItem(AppSettings.tempKey));
    this.invoiceBillItems = JSON.parse(sessionStorage.getItem(AppSettings.tempKey1));

    if (this.invoiceHeader !== null && this.invoiceBillItems !== null) {
      this.hidePurchaseOrderForm = false;
    if (this.invoiceHeader.hasOwnProperty('specialNote')) {
      this.qrCodeString = sessionStorage.getItem(AppSettings.tempKey);
    }
  }

  if (this.invoiceBillItems == null) {
    this.invoiceBillItems = [];
  }
    if (this.invoiceBillItems.length > 0) {
      for (const iterator of this.invoiceBillItems) {
        if (iterator.hasOwnProperty('vatAmt')) {
          this.totalVat +=iterator.vatAmt;
        }
        if (iterator.hasOwnProperty('net')) {
          this.totalAmountBeforeVat += iterator.net;
        }
        if (iterator.hasOwnProperty('lineTotal')) {
          this.totalAmountAfterVat += iterator.lineTotal;
        }
      }
    }

    this.onGetDebitAccounts();

    this.purchaseOrderForm = new FormGroup({
      poNumber: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.poNumber = Math.floor(Math.random() * 99999) + 9999;
    this.purchaseOrderHeaderForm = new FormGroup({
      poNumber: new FormControl(this.uniqueNumber, Validators.compose([Validators.required])),
      supplier: new FormControl(null, Validators.compose([Validators.required])),
      requestedBy: new FormControl(this.authService.getUserName(), Validators.compose([Validators.required])),
      poDate: new FormControl(this.now, Validators.compose([Validators.required])),
      deliveryDate: new FormControl(this.now, Validators.compose([Validators.required])),
      glAccount: new FormControl(null, Validators.compose([Validators.required])),
      paymentMethod: new FormControl(null, Validators.compose([Validators.required])),
      department: new FormControl(null, Validators.compose([Validators.required])),
      billingAddress: new FormControl(null, Validators.compose([Validators.required])),
      address: new FormControl('', Validators.compose([])),
      city: new FormControl(null, Validators.compose([Validators.required])),
      countryCode: new FormControl(null, Validators.compose([Validators.required])),
      postCode: new FormControl('', Validators.compose([])),
      country: new FormControl(null, Validators.compose([Validators.required])),
      supplierContact: new FormControl('', Validators.compose([])),
      requesterContact: new FormControl('', Validators.compose([])),
      specialNote: new FormControl('', Validators.compose([]))
    });

    this.purchaseOrderLineItemForm = new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      itemName: new FormControl(null, Validators.compose([Validators.required])),
      itemSize: new FormControl('', Validators.compose([])),
      itemQuantity: new FormControl(null, Validators.compose([Validators.required])),
      itemSpecs: new FormControl('', Validators.compose([])),
      storeLoc: new FormControl('', Validators.compose([])),
      stockCode: new FormControl(null, Validators.compose([Validators.required])),
      availableStock: new FormControl(this.availableStokeQty, Validators.compose([])),
      discountPercent: new FormControl(0, Validators.compose([])),
      unitPrice: new FormControl(null, Validators.compose([Validators.required])),
      sellingPrice: new FormControl(null, Validators.compose([Validators.required])),
      sellingPriceVat: new FormControl(true, Validators.compose([Validators.required])),
      currency: new FormControl(this.currencyDataSource[0].id, Validators.compose([Validators.required])),
      vat: new FormControl(this.vatDataSource[0].id, Validators.compose([Validators.required])),
      vatAmt: new FormControl(null, Validators.compose([])),
      lineTotal: new FormControl(null, Validators.compose([])),
      net: new FormControl(null, Validators.compose([]))
    });
  }
  getCompletedPurchaseOrders() {

  }

  onSupplierValueChanged(e) {
    for (const iterator of this.suppilersData) {
      if (e.value == iterator.id ) {
        this.supplierContacts = iterator.phone;
        break;
      }
    }
  }

  onCountryValueChanged(e) {
    for (const iterator of this.countries) {
      if (e.value == iterator.name ) {
        this.countryCode = iterator.code;
        break;
      }
    }
  }

  onStockCategoryValueChanged(e) {
    for (const iterator of this.stockCategoryDataSource) {
      if(iterator.id == e.value) {
        this.productCode = iterator.categoryCode;
        this.productName = iterator.categoryName;

        this.productSize = iterator.itemSize;
        this.productUnitCostFlat = iterator.unitCostFlat;
        this.productUnitPriceFlat = iterator.unitPriceFlat;
        this.productDescription = iterator.description;

        this.availableStokeQty = iterator.stockItemsQty;

        this.purchaseOrderLineItemForm.get('unitPrice').patchValue(iterator.unitCostFlat);
        this.purchaseOrderLineItemForm.get('sellingPrice').patchValue(iterator.unitPriceFlat);
        this.purchaseOrderLineItemForm.get('itemSize').patchValue(iterator.itemSize);
        this.purchaseOrderLineItemForm.get('itemSpecs').patchValue(iterator.description);

        this.purchaseOrderLineItemForm.get('itemName').patchValue(iterator.categoryName);
        this.purchaseOrderLineItemForm.get('availableStock').patchValue(iterator.stockItemsQty);
        this.purchaseOrderLineItemForm.get('storeLoc').patchValue(iterator.stockStore.storeName);
        this.storeLocation = iterator.stockStore.storeName;
        this.storeId = iterator.stockStore.id;
        break;
      }
    }
  }

  getAllStockCategories() {
    this.utilities.postServiceCall({}, 'stockCategory/all').subscribe(res => {
      this.stockCategoryDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  getAllStores() {
    this.utilities.postServiceCall({}, 'store/all').subscribe(res => {
      this.storeLocationDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }
  getAllDepartments() {
    this.utilities.postServiceCall({}, 'department/all').subscribe(res => {
      this.departmentDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  getAllSuppliers() {
    this.utilities.postServiceCall({}, 'suppliers/all').subscribe(res => {
      this.suppilersData = res.data;
    });
  }
  resetForm(e) {
    if (e == 1) {
      this.purchaseOrderLineDataSource = [];
    } else if (e == 2) {
      this.purchaseOrderLineItemForm.reset();
    }

  }

  onGetDebitAccounts(): void {
    this.utilities.postServiceCall(this.data, 'accounts/all').subscribe(
      response => {
        const data = response;
        for (const el of data.data) {
          if (el.accountType == '105') {
            this.accountsCategory.push(el);
          }
          this.accounts.push(el);
        }
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  onPointClick(e) {
    e.target.select();
  }

  addNewPurchaseItem() {
    this.hideEditActionButton = true;
    this.showAddDialog = true;
    this.purchaseOrderLineItemForm.reset();
    this.purchaseOrderLineItemForm.get('itemSize').patchValue('');
    this.purchaseOrderLineItemForm.get('itemSpecs').patchValue('');
    this.purchaseOrderLineItemForm.get('storeLoc').patchValue('');
    this.purchaseOrderLineItemForm.get('sellingPriceVat').patchValue(true);
    this.purchaseOrderLineItemForm.get('currency').patchValue(this.currencyDataSource[0].id);
    this.purchaseOrderLineItemForm.get('vat').patchValue(this.vatDataSource[0].id);
  }


  addNewPurchaseLineItem() {

    if (this.purchaseOrderLineItemForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserName()} please fill all required fields.`)
      return;
    }

    if (this.purchaseOrderLineItemForm.get('itemQuantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, item quantity can not be less than one.`)
      return;
    }

    for (const iterator of this.purchaseOrderLineDataSource) {
      if (this.productCode == iterator.productCode) {
        this.toastr.error(`Dear ${this.authService.getUserName()}, item with product code (${this.productCode}) is already added.`)
        return;
      }
    }

    const id = this.purchaseOrderLineDataSource.length + 1;
    this.purchaseOrderLineItemForm.get('id').patchValue(id);
    const currency = this.purchaseOrderLineItemForm.get('currency').value;
    currency === 1 ? this.purchaseOrderLineItemForm.get('currency').patchValue('TZS') : this.purchaseOrderLineItemForm.get('currency').patchValue('USD');
    let discountPercent = 0;
    let sellingPrice = 0;
    let sellingPriceHoldingVat = 0;
    let sellingPriceVAT = 0;

    if (this.purchaseOrderLineItemForm.get('sellingPriceVat').value === true) {
      sellingPriceVAT = 18;
      sellingPriceHoldingVat = +this.purchaseOrderLineItemForm.get('sellingPrice').value * (18/100);
      sellingPrice = +this.purchaseOrderLineItemForm.get('sellingPrice').value + sellingPriceHoldingVat;
    } else {
      sellingPriceHoldingVat = 0;
      sellingPrice = +this.purchaseOrderLineItemForm.get('sellingPrice').value + sellingPriceHoldingVat;
    }


    let totalItemAmt = +this.purchaseOrderLineItemForm.get('itemQuantity').value * +this.purchaseOrderLineItemForm.get('unitPrice').value;

    if (this.purchaseOrderLineItemForm.get('discountPercent').value !== null) {
      if (this.purchaseOrderLineItemForm.get('discountPercent').value > 0) {
          discountPercent = +this.purchaseOrderLineItemForm.get('discountPercent').value;
          totalItemAmt -= (totalItemAmt * (discountPercent/100));
      }
    }

    if (this.purchaseOrderLineItemForm.get('availableStock').value == null) {
      this.purchaseOrderLineItemForm.get('availableStock').patchValue(0);
    }

    const totalVat = totalItemAmt * (18/100);
    const lineTotal = totalVat + totalItemAmt;

    this.purchaseOrderLineDataSource.push({
      id: this.purchaseOrderLineItemForm.get('id').value,
      itemName: this.purchaseOrderLineItemForm.get('itemName').value,
      itemSize: this.purchaseOrderLineItemForm.get('itemSize').value,
      itemQuantity: this.purchaseOrderLineItemForm.get('itemQuantity').value,
      itemSpecs: this.purchaseOrderLineItemForm.get('itemSpecs').value,
      storeLoc: this.purchaseOrderLineItemForm.get('storeLoc').value,
      productCode: this.productCode,
      stockCode: this.purchaseOrderLineItemForm.get('stockCode').value,
      availableStock: this.purchaseOrderLineItemForm.get('availableStock').value,
      discountPercent: discountPercent,
      storeId: this.storeId,
      sellingPrice: +this.purchaseOrderLineItemForm.get('sellingPrice').value,
      totalSellingPrice: sellingPrice,
      sellingPriceVAT: sellingPriceVAT,
      sellingPriceVatAmt: sellingPriceHoldingVat,
      unitPrice: +this.purchaseOrderLineItemForm.get('unitPrice').value,
      currency: 'TZS',
      net: totalItemAmt,
      vat: '18%',
      vatAmt: totalVat,
      lineTotal: lineTotal
    });
    this.showAddDialog = false;

    // this.purchaseOrderLineItemForm.reset();

    this.purchaseOrderLineItemForm.get('currency').patchValue(this.currencyDataSource[0].id);
    this.purchaseOrderLineItemForm.get('vat').patchValue(this.vatDataSource[0].id);
  }

  onDeleteListener(e) {
    const result = confirm(
      'Are you sure you want to delete selected order list item ?',
      'Delete Line Item'
    );
    result.then(dialogResult => {
      if (dialogResult) {

    for (let index = 0; index < this.purchaseOrderLineDataSource.length; index++) {
      if (this.purchaseOrderLineDataSource[index].id === e.id) {
        this.purchaseOrderLineDataSource.splice(index, 1);
        break;
      }
    }
      }
    });
  }

  verifyPurchaseOrderNumber() {
    if (this.purchaseOrderForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    const data = {
      poNumber: this.purchaseOrderForm.get('poNumber').value
    };

    this.utilities.postServiceCall(data, 'purchase-order/po').subscribe(res => {

      if (res.statusCode == 2000) {
        this.toastr.success(res.statusMessage);
        this.fetchedPoNumber = res.data.header.poNumber;
        this.poDate =res.data.header.purchaseOrderHeader.poDate;
        this.supplier =res.data.header.purchaseOrderHeader.supplier.supplierName;
        this.requestedBy =res.data.header.purchaseOrderHeader.requestedUser.username;
        this.expectedDeliveryDate =res.data.header.purchaseOrderHeader.deliveryDate;
        this.glAccount =res.data.header.purchaseOrderHeader.accountPayable.accountName;
        this.paymentMerhod =res.data.header.purchaseOrderHeader.paymentType;
        this.department =res.data.header.purchaseOrderHeader.department.name;
        this.requesterContact =res.data.header.purchaseOrderHeader.requesterContact;
        this.billAddress =res.data.header.purchaseOrderHeader.billingAddress;
        this.address =res.data.header.purchaseOrderHeader.address2;
        this.postCode =res.data.header.purchaseOrderHeader.postCode;
        this.country =res.data.header.purchaseOrderHeader.country;
        this.fetchedPoCountryCode =res.data.header.purchaseOrderHeader.countryCode;
        this.city =res.data.header.purchaseOrderHeader.city;
        this.poSpecialNote =res.data.header.purchaseOrderHeader.specialNote;
        this.fetchedPoLineDatasource = res.data.line;
        this.discountAmount =res.data.header.purchaseOrderHeader.discountAmount;
        this.hideApproveControls = false;
        this.logger(res.data);
      } else {
        this.hideApproveControls = true;
        this.clearData();
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error(err.message);
    });
  }

  approvePurchaseOrder() {
      if (this.purchaseOrderForm.invalid) {
        this.toastr.error('Purchase order number is required.');
        return;
      }

      const data = {
        poNumber: this.purchaseOrderForm.get('poNumber').value
      };

      this.utilities.postServiceCall(data, 'purchase-order/approve').subscribe(res => {
        if (res.statusCode == 2000) {
          this.toastr.success(res.statusMessage);
          this.hideApproveControls = true;
          this.clearData();
        } else {
          this.hideApproveControls = false;
          this.clearData();
          this.toastr.error(res.statusMessage);
        }
      }, err => {
        this.toastr.error(err.message);
      });
  }

  cancelPurchaseOrder() {
    if (this.purchaseOrderForm.invalid) {
      this.toastr.error('Purchase order number is required.');
      return;
    }

    const data = {
      poNumber: this.purchaseOrderForm.get('poNumber').value
    };

    this.utilities.postServiceCall(data, 'purchase-order/cancel').subscribe(res => {
      if (res.statusCode == 2000) {
        this.toastr.success(res.statusMessage);
        this.hideApproveControls = true;
        this.clearData();
      } else {
        this.hideApproveControls = false;
        this.clearData();
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error(err.message);
    });
  }

  clearData() {
    this.fetchedPoNumber = ''
    this.poDate = '';
    this.supplier = '';
    this.requestedBy = '';
    this.expectedDeliveryDate = '';
    this.glAccount = '';
    this.paymentMerhod = '';
    this.department = '';
    this.requesterContact = '';
    this.billAddress = '';
    this.address = '';
    this.postCode = '';
    this.country = '';
    this.fetchedPoCountryCode = '';
    this.city = '';
    this.poSpecialNote = '';
    this.fetchedPoLineDatasource = [];
  }


  findDuplicates(arr) {
    let sorted_arr = arr.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] == sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  createPurchaseOrder() {
    if (this.purchaseOrderHeaderForm.invalid) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please fill all required details on purchase order header section.`);
      return;
    }

    let counter: any;

    let productCodes = [];

    for (const iterator of this.purchaseOrderLineDataSource) {
      productCodes.push(iterator.productCode);
    }

    counter = this.findDuplicates(productCodes);

    if (counter.length > 0 ) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, more than one product code(${counter}) detected.`);
      return;
    }

    if (this.purchaseOrderLineDataSource.length < 1) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please add order items in the list to proceed.`);
      return;
    }
    try {
      const poDate = this.purchaseOrderHeaderForm.get('poDate').value.getFullYear() + "-"+  ("0"+(this.purchaseOrderHeaderForm.get('poDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.purchaseOrderHeaderForm.get('poDate').value.getDate()).slice(-2);
      const deliveryDate = this.purchaseOrderHeaderForm.get('deliveryDate').value.getFullYear() + "-"+  ("0"+(this.purchaseOrderHeaderForm.get('deliveryDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.purchaseOrderHeaderForm.get('deliveryDate').value.getDate()).slice(-2);
      this.purchaseOrderHeaderForm.get('poDate').patchValue(poDate);
      this.purchaseOrderHeaderForm.get('deliveryDate').patchValue(deliveryDate);
    } catch (error) {
      this.logger(error);
    }

    const data = {
      header: this.purchaseOrderHeaderForm.value,
      line: this.purchaseOrderLineDataSource
    }

    if (this.purchaseOrderHeaderForm.get('address').value == null) {
      this.purchaseOrderHeaderForm.get('address').patchValue('');
    }
    if (this.purchaseOrderHeaderForm.get('postCode').value == null) {
      this.purchaseOrderHeaderForm.get('postCode').patchValue('');
    }
    if (this.purchaseOrderHeaderForm.get('supplierContact').value == null) {
      this.purchaseOrderHeaderForm.get('supplierContact').patchValue('');
    }
    if (this.purchaseOrderHeaderForm.get('requesterContact').value == null) {
      this.purchaseOrderHeaderForm.get('requesterContact').patchValue('');
    }
    if (this.purchaseOrderHeaderForm.get('specialNote').value == null) {
      this.purchaseOrderHeaderForm.get('specialNote').patchValue('');
    }

    this.utilities.postServiceCall(data, 'purchase-order').subscribe(res => {

    sessionStorage.removeItem(AppSettings.tempKey);
    sessionStorage.removeItem(AppSettings.tempKey1);
    sessionStorage.setItem(AppSettings.tempKey, JSON.stringify(this.purchaseOrderHeaderForm.value));
    sessionStorage.setItem(AppSettings.tempKey1, JSON.stringify(this.purchaseOrderLineDataSource));
    this.qrCodeString = JSON.stringify(data);

    this.toastr.success(res.statusMessage, 'Purchase Order');

    // this.hidePurchaseOrderForm = false;
    // this.hideSalesOrderForm = true;

    // this.storageService.setHidePurchaseOrderForm(false);
    // this.storageService.setHideSalesOrderForm(true);

    sessionStorage.removeItem(AppSettings.hideDeiveryNoteFormKey);
    sessionStorage.removeItem(AppSettings.hidePurchaseOrderFormKey);
    sessionStorage.removeItem(AppSettings.hideSalesOrderFormKey);

    sessionStorage.setItem(AppSettings.hidePurchaseOrderFormKey, JSON.stringify(false));
    sessionStorage.setItem(AppSettings.hideSalesOrderFormKey, JSON.stringify(true));

    this.utilities.postServiceCall(data, 'unique-number').subscribe(res => {
      this.uniqueNumber = res.number;
    }, error => {
      this.logger(error);
    });

    this.router.navigate(['/printing-area']);

    this.printPurchaseOrder();
    }, err => {
      this.toastr.error('Failed To create Purchase Order, please try again.');
      this.logger(err);
    });

    this.logger(JSON.stringify(data));
  }

  customizeText1(data) {
    return "Total Items: " +  data.value;
  }
  customizeText2(data) {
    return "Total Vat Amount: " + data.value;
  }
  customizeText3(data) {
    return "Total amount before VAT: " + data.value;
  }
  customizeText4(data) {
    return "Total amount after VAT: " + data.value;
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'New Item',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewPurchaseItem.bind(this)
      }
    });
  }

  previewPurchaseOrderItem(e) {
    this.logger(e.data);
    this.hideEditActionButton = false;
    this.popUpTitle = 'Update order item';
    this.purchaseOrderLineItemForm.patchValue(e.data);
    this.showAddDialog = true;
    this.purchaseOrderLineItemForm.get('currency').patchValue(this.currencyDataSource[0].id);
    this.purchaseOrderLineItemForm.get('vat').patchValue(this.vatDataSource[0].id);
  }

  onUpdatePurchaseOrderItem() {
    if (this.purchaseOrderLineItemForm.invalid) {
      this.toastr.error(`Hello ${this.authService.getUserName()}, please fill some important data that are lest empty to continue.`);
      return;
    }


    if (this.purchaseOrderLineItemForm.get('itemQuantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, item quantity can not be less than one.`)
      return;
    }

    let counter = 0;

    for (const iterator of this.purchaseOrderLineDataSource) {
      if (this.productCode == iterator.productCode) {
        counter += 1;
      }
    }

    for (const iterator of this.purchaseOrderLineDataSource) {
      if (this.productCode == iterator.productCode && counter > 1 && this.purchaseOrderLineDataSource.length > 1) {
        this.toastr.error(`Dear ${this.authService.getUserName()}, item with product code (${this.productCode}) is already added.`);
        return;
      }
    }

    for (let index = 0; index < this.purchaseOrderLineDataSource.length; index++) {
      if (this.purchaseOrderLineDataSource[index].id === this.purchaseOrderLineItemForm.get('id').value) {
        this.purchaseOrderLineDataSource[index] = this.purchaseOrderLineItemForm.value;
        this.purchaseOrderLineDataSource[index].currency = this.purchaseOrderLineItemForm.get('currency').value === 1 ? 'TZS' : 'USD';
        this.purchaseOrderLineDataSource[index].vat = this.purchaseOrderLineItemForm.get('vat').value === 1 ? '18%' : '18%';
        let discountPercent = 0;


        let sellingPrice = 0;
        let sellingPriceHoldingVat = 0;
        let sellingPriceVAT = 0;

        if (this.purchaseOrderLineItemForm.get('sellingPriceVat').value === true) {
          sellingPriceVAT = 18;
          sellingPriceHoldingVat = this.purchaseOrderLineItemForm.get('sellingPrice').value * (18/100);
          sellingPrice = this.purchaseOrderLineItemForm.get('sellingPrice').value + sellingPriceHoldingVat;
        } else {
          sellingPriceHoldingVat = 0;
          sellingPrice = this.purchaseOrderLineItemForm.get('sellingPrice').value + sellingPriceHoldingVat;
        }


        let totalItemAmt = this.purchaseOrderLineItemForm.get('itemQuantity').value * this.purchaseOrderLineItemForm.get('unitPrice').value;

        if (this.purchaseOrderLineItemForm.get('discountPercent').value !== null) {
          if (this.purchaseOrderLineItemForm.get('discountPercent').value > 0) {
              discountPercent = this.purchaseOrderLineItemForm.get('discountPercent').value;
              totalItemAmt -= (totalItemAmt * (discountPercent/100));
          }
        }

        if (this.purchaseOrderLineItemForm.get('availableStock').value == null) {
          this.purchaseOrderLineItemForm.get('availableStock').patchValue(0);
        }

        const totalVat = totalItemAmt * (18/100);
        const lineTotal = totalVat + totalItemAmt;

        this.purchaseOrderLineDataSource[index].vatAmt = totalVat;
        this.purchaseOrderLineDataSource[index].net = totalItemAmt;
        this.purchaseOrderLineDataSource[index].lineTotal = lineTotal;

        this.purchaseOrderLineDataSource[index].storeId = this.storeId;
        this.purchaseOrderLineDataSource[index].totalSellingPrice = sellingPrice;
        this.purchaseOrderLineDataSource[index].sellingPriceVAT = sellingPriceVAT;
        this.purchaseOrderLineDataSource[index].sellingPriceVatAmt = sellingPriceHoldingVat;
        this.purchaseOrderLineDataSource[index].productCode = this.productCode;

        this.showAddDialog = false;
        this.spinner.hide();
        break;
      }
    }
  }

  onDeletePurchaseOrderItem() {
    const result = confirm(
      'Are you sure you want to delete selected item ?',
      'Delete Order Item'
    );
    result.then(dialogResult => {
      if (dialogResult) {

    for (let index = 0; index < this.purchaseOrderLineDataSource.length; index++) {
      if (this.purchaseOrderLineDataSource[index].id === this.purchaseOrderLineItemForm.get('id').value) {
        this.purchaseOrderLineDataSource.splice(index, 1);
        this.showAddDialog = false;
        break;
      }
    }
      }
    });
  }

  printPurchaseOrder() {
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <link href="assets/css/layout.css" rel="stylesheet" type="text/css">
           <link href="assets/css/themes.css" rel="stylesheet" type="text/css">
           <link href="assets/css/typography.css" rel="stylesheet" type="text/css">
           <link href="assets/css/styles.css" rel="stylesheet" type="text/css">
           <link href="assets/css/shCore.css" rel="stylesheet" type="text/css">
           <link href="assets/css/jquery.jqplot.css" rel="stylesheet" type="text/css">
           <link href="assets/css/jquery-ui-1.8.18.custom.css" rel="stylesheet" type="text/css">
           <link href="assets/css/data-table.css" rel="stylesheet" type="text/css">
           <link href="assets/css/form.css" rel="stylesheet" type="text/css">
           <link href="assets/css/ui-elements.css" rel="stylesheet" type="text/css">
           <link href="assets/css/wizard.css" rel="stylesheet" type="text/css">
           <link href="assets/css/sprite.css" rel="stylesheet" type="text/css">
           <link href="assets/css/gradient.css" rel="stylesheet" type="text/css">
           <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
          }
          .space{
            display: flex;
            justify-content: space-between;
            // border: 1px dotted #4d88bb;
            padding: 1rem 0;
          }
          .btn_control_block{
            display: flex;
            justify-content: space-evenly;
            align-content: center;
          }
          .stripe {
            background-color: rgba(0,0,0,.05);
          }
          .logo__block{
          /* border-bottom: 1px dotted #4d88bb; */
          // border-bottom: 1px dotted rgb(0 172 238);
          }
          .logo__block p, .footer, p {
            /* color: #4d88bb; */
            // color: rgb(0 172 238);
          }
          .invoice__head__child_contacts table tbody tr td{
          /* text-decoration: underline; */
          font-style: italic;
          }
          .kill_margin {
            margin: 0 !important;
            padding: 0 !important;
          }
          .invoice{
            background-color: rgba(0, 0, 0, 0.055);
            padding: 2rem;
            // width: fit-content;
            height: 100%;
            width: 100%;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
          }
          .invoice__body{
            display: flex;
            /* justify-content: center; */
          }
          .invoice__body table tr th{
            // background: rgb(0 172 238);
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
            // background-color: rgba(136 ,136 ,136, .1);
            // border: 1px solid #ddd;
            padding: .35em;
          }
          .logo__block{
              text-align: start;
          }
          .footer {
            color:white;
            text-align: center;
          }
          .kill_padding_right{
            padding-right: 0;
          }
          .kill_padding_left{
            padding-left: 0;
          }
          .center_para {
            text-align: center;
          }
          .full_table{
            width: 100%;
          }

          table tbody tr td{
          padding: .5rem;
          }

          @media (max-width: 576px) {
            .btn_control_block{
              display: flex;
              flex-direction: row;
            }
           }

          @media print {
          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            background:rgba(0, 0, 0, 0.055);
            color:black;
            width: 100%;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
          }
          .footer2{
            position: absolute;
            bottom: 2%;
            width: 100%;
            text-align: center;
            color: #000;
          }
          .invoice{
            background-color: transparent;
          }

          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }
            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }
           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

  getAllCompletedPurchaseOrders() {
    this.utilities.postServiceCall(this.data, 'purchase/order/all').subscribe(
      response => {
        const data = response;
        this.completedPurchaseOrders = [];
        this.pendingPurchaseOrders = [];
        for (const el of data.data) {
          if (el.status == 3) {
            this.completedPurchaseOrders.push(
              {
                id: el.id,
                poNumber: el.poNumber,
                status: el.status,
                amountPaid: el.amountReceivable,
                poDate: el.purchaseOrderHeader.poDate,
                debitAccount: el.purchaseOrderHeader.accountPayable.accountName,
                creditAccount: el.purchaseOrderHeader.supplier.supplierGLAccount.accountName,
                deliveryDate: el.purchaseOrderHeader.deliveryDate,
                supplier: el.purchaseOrderHeader.supplier.supplierName,
                paymentType: el.purchaseOrderHeader.paymentType,
                department: el.purchaseOrderHeader.department.name,
                requestedUser: el.purchaseOrderHeader.requestedUser.username
              }
            );
          } else {
            this.pendingPurchaseOrders.push(
              {
                id: el.id,
                poNumber: el.poNumber,
                status: el.status,
                amountPaid: el.amountReceivable,
                poDate: el.purchaseOrderHeader.poDate,
                debitAccount: el.purchaseOrderHeader.accountPayable.accountName,
                creditAccount: el.purchaseOrderHeader.supplier.supplierGLAccount.accountName,
                deliveryDate: el.purchaseOrderHeader.deliveryDate,
                supplier: el.purchaseOrderHeader.supplier.supplierName,
                paymentType: el.purchaseOrderHeader.paymentType,
                department: el.purchaseOrderHeader.department.name,
                requestedUser: el.purchaseOrderHeader.requestedUser.username
              }
            );
          }
        }
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  completedPurchaseOrdersOnParam(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.getAllCompletedPurchaseOrders.bind(this)
      }
    });
  }


}
