import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AppSettings } from 'src/app/app-settings';
import { map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {

  get loggedIn(): boolean {
    return JSON.parse(sessionStorage.getItem(AppSettings.isLoggedInKey));
  }

  constructor(private router: Router, private httpClient: HttpClient) { }

  getJwtToken(): string {
    return 'xxxx';
  }

  getUserName(): string {
    const data = JSON.parse(sessionStorage.getItem(AppSettings.jwtTokenKey));
    return data.username;
  }

  getUserId(): string {
    const data = JSON.parse(sessionStorage.getItem(AppSettings.jwtTokenKey));
    return data.id;
  }

  getUserRole(): string {
    return 'Administrator';
  }

  setJwtToken(token) {
    // sessionStorage.setItem(AppSettings.jwtTokenKey, token);
  }

  // tslint:disable-next-line: typedef
  logIn(username: string, password: string){

    const data = {
      username,
      password
    };

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Expose-Headers': '*, Authorization'
    });

    // tslint:disable-next-line: max-line-length
    return this.httpClient.post(AppSettings.loginUrl, data, {headers}).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  logOut(): void {
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }
}

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isLoggedIn = this.authService.loggedIn;
    const isAuthForm = [
      'login'
    ].includes(route.routeConfig.path);

    if (isLoggedIn && isAuthForm) {
      this.router.navigate(['/home']);
      return false;
    }

    if (!isLoggedIn && !isAuthForm) {
      this.router.navigate(['/login']);
    }

    return isLoggedIn || isAuthForm;
  }
}
