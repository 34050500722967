import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-supplier-management',
  templateUrl: './supplier-management.component.html',
  styleUrls: ['./supplier-management.component.scss']
})
export class SupplierManagementComponent extends SharedClassComponent implements OnInit {

  title = 'Suppliers';
  modelName = 'users/list';
  suppliers = [];
  countries = [];
  hideEditActionButton = true;
  showAddDialog = false;
  popUpTitle = 'Add New Supplier';
  hideSupplierStatus = true;

    searchModeOption: string = "contains";
    searchExprOption: any = "name";
    searchTimeoutOption: number = 200;
    minSearchLengthOption: number = 0;

  ngOnInit() {
    this.getAllSuppliers();
    this.countries = this.storageService.getAllCountries();
    this.paramwinfrm =  new FormGroup({
      supplierId: new FormControl('', Validators.compose([])),
      supplierName: new FormControl('', Validators.compose([Validators.required])),
      address: new FormControl('', Validators.compose([Validators.required])),
      address1: new FormControl('', Validators.compose([])),
      address2: new FormControl('', Validators.compose([])),
      country: new FormControl('', Validators.compose([Validators.required])),
      city: new FormControl('', Validators.compose([Validators.required])),
      phone: new FormControl('', Validators.compose([Validators.required])),
      fax: new FormControl('', Validators.compose([])),
      zipCode: new FormControl('', Validators.compose([])),
      email: new FormControl('', Validators.compose([])),
      businessNumber: new FormControl('', Validators.compose([])),
      website: new FormControl('', Validators.compose([])),
      status: new FormControl(null, Validators.compose([]))
    });
  }

  addNewSuppplier() {
    this.hideEditActionButton = true;
    this.hideSupplierStatus = true;
    this.showAddDialog = true;
    this.paramwinfrm.reset();
  }

  previewSupllier(e) {
    this.hideEditActionButton = false;
    this.hideSupplierStatus = false;
    this.popUpTitle = 'Update Supplier';
    this.showAddDialog = true;
    this.paramwinfrm.patchValue(e.data);
    this.paramwinfrm.get('supplierId').patchValue(e.data.id);
    }

  getAllSuppliers() {
    this.utilities.postServiceCall({}, 'suppliers/all').subscribe(res => {
      this.suppliers = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  createSupplier(): void {

    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill all required details');
      return;
    }

    if (this.paramwinfrm.get('address').value === null) {
      this.paramwinfrm.get('address').patchValue('');
    }
    if (this.paramwinfrm.get('address1').value === null) {
      this.paramwinfrm.get('address1').patchValue('');
    }
    if (this.paramwinfrm.get('address2').value === null) {
      this.paramwinfrm.get('address2').patchValue('');
    }
    if (this.paramwinfrm.get('fax').value === null) {
      this.paramwinfrm.get('fax').patchValue('');
    }
    if (this.paramwinfrm.get('zipCode').value === null) {
      this.paramwinfrm.get('zipCode').patchValue('');
    }
    if (this.paramwinfrm.get('email').value === null) {
      this.paramwinfrm.get('email').patchValue('');
    }
    if (this.paramwinfrm.get('businessNumber').value === null) {
      this.paramwinfrm.get('businessNumber').patchValue('');
    }
    if (this.paramwinfrm.get('website').value === null) {
      this.paramwinfrm.get('website').patchValue('');
    }
    if (this.paramwinfrm.get('status').value === null) {
      this.paramwinfrm.get('status').patchValue('');
    }

    this.utilities.postServiceCall(this.paramwinfrm.value, 'supplier').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllSuppliers();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  updateSupplier(): void {
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill all required details');
      return;
    }

    if (this.paramwinfrm.get('status').value === null || this.paramwinfrm.get('status').value === false) {
      this.paramwinfrm.get('status').patchValue(0);
    } else {
      this.paramwinfrm.get('status').patchValue(1);
    }

    this.utilities.postServiceCall(this.paramwinfrm.value, 'supplier/update').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllSuppliers();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  onToolBarPreparing(e): void {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewSuppplier.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getAllSuppliers.bind(this)
        }
      }
    );
  }

}
