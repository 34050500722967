import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class AppInfoService {

  DisableEnabledTextSwitcher: any;

  constructor(private titleService: Title) {}

  public setTitle( newTitle: string) {
    this.titleService.setTitle(`Asset+ | ${newTitle}`);
  }

  public get title() {
    return 'Point of Sale';
  }

  public get currentYear() {
    return new Date().getFullYear();
  }

  public setTextSwitcher(text: any) {
    this.DisableEnabledTextSwitcher = text;
  }

  public getTextSwitcher() {
    return this.DisableEnabledTextSwitcher;
  }
}
