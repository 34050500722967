import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-purchase-order-report',
  templateUrl: './purchase-order-report.component.html',
  styleUrls: ['./purchase-order-report.component.scss']
})
export class PurchaseOrderReportComponent extends SharedClassComponent implements OnInit {

  completedPurchaseOrders = [];

  ngOnInit() {
    this.getAllCompletedPurchaseOrders();
  }

  getAllCompletedPurchaseOrders() {
    this.utilities.postServiceCall(this.data, 'purchase/order/all').subscribe(
      response => {
        const data = response;
        this.completedPurchaseOrders = [];
        for (const el of data.data) {
            this.completedPurchaseOrders.push(
              {
                id: el.id,
                poNumber: el.poNumber,
                status: el.status,
                amendStatus: el.amendStatus,
                amendNarration: el.amendNarration,
                amendedBy: el.amendedBy,
                approvedBy: el.approvedBy,
                amountPaid: el.amountReceivable,
                discountAmount: el.discountAmount,
                poDate: el.purchaseOrderHeader.poDate,
                debitAccount: el.purchaseOrderHeader.accountPayable.accountName,
                creditAccount: el.purchaseOrderHeader.supplier.supplierGLAccount.accountName,
                deliveryDate: el.purchaseOrderHeader.deliveryDate,
                supplier: el.purchaseOrderHeader.supplier.supplierName,
                paymentType: el.purchaseOrderHeader.paymentType,
                department: el.purchaseOrderHeader.department.name,
                requestedUser: el.purchaseOrderHeader.requestedUser.username
              }
            );
        }
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }


  completedPurchaseOrdersOnParam(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.getAllCompletedPurchaseOrders.bind(this)
      }
    });
  }

}
