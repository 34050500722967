import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-running-accounts',
  templateUrl: './running-accounts.component.html',
  styleUrls: ['./running-accounts.component.scss']
})
export class RunningAccountsComponent extends SharedClassComponent implements OnInit {

  title = 'Running Accounts';
  modelName = 'accounts/running/account/all';

  accountTypesDataSource = [];
  accountsDatasource = [];

  now = new Date();

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      accTypeId: new FormControl('', Validators.compose([Validators.required])),
      accId: new FormControl('', Validators.compose([Validators.required]))
    });
    this.observerCall();
    this.onGetParamsdetails();
    this.onGetAccountTypes();
    this.onGetAllAccounts();
  }

  onGetAccountTypes() {
    this.utilities.postServiceCall({}, 'accounts/accountTypes/all').subscribe(
      response => {
        const data = response;
        this.accountTypesDataSource = data.data;
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  onGetAllAccounts() {
    this.utilities.postServiceCall(this.data, 'accounts/all').subscribe(
      response => {
        this.accountsDatasource = response.data;
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }


  saveParams(path): void {
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please provide information on empty fields');
      return;
    }
    this.utilities.postServiceCall(this.paramwinfrm.value, path).subscribe(res => {
      const response = res;
      if (response.statusCode === 2000) {
        this.toastr.success(response.statusMessage);
        this.paramswinpnl = false;
        this.onGetParamsdetails();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

}
