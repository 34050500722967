import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent extends SharedClassComponent implements OnInit {

  resetPasswordForm: FormGroup;
  empEmail = 'abc@test.com';
  empMobile = '07xxx';
  emailOrSMS = 'email';
  isPasswordResetCompleted = false;
  hideInstructionForm = true;
  hideCustomerNumberVerifyForm = false;
  hidePaswordResetForm = true;
  isResetCompleted = false;
  password = '';
  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      customerNumber: new FormControl(null, Validators.compose([Validators.required])),
      otp: new FormControl(null, Validators.compose([Validators.required])),
      newPassword: new FormControl(null, Validators.compose([Validators.required, Validators.min(6)])),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required, Validators.min(6)]))
    });
  }
  passwordComparison = () => {
    return this.password;
  }

  submitMobileOrEmail() {
    this.isPasswordResetCompleted = true;
  }

  customerNumberVerification() {
    if (!this.resetPasswordForm.get('customerNumber').valid) {
      this.toastr.error('Customer Number is required.');
      return;
    }
    const data = {
      requestType: 'PASSWORD_RESET_REQUEST',
      linkId: this.resetPasswordForm.get('customerNumber').value
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(response => {
      const serverResponse = response.json();
      if (serverResponse.code == 2000) {
         this.hideCustomerNumberVerifyForm = true;
         this.hidePaswordResetForm = false;
       } else {
         this.toastr.error(serverResponse.message);
       }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error('Something went wrong', 'Please try again');
    });
  }

  next() {
    // this.isPasswordResetCompleted = false;
  }

  passwordReset() {
    if (!this.resetPasswordForm.get('customerNumber').valid) {
      this.toastr.error('Customer Number is required.');
      return;
    }
    if (!this.resetPasswordForm.get('otp').valid) {
      this.toastr.error('Please enter OTP code to continue.');
      return;
    }
    if (!this.resetPasswordForm.get('newPassword').valid) {
      this.toastr.error('New Password is required.');
      return;
    }
    if (!this.resetPasswordForm.get('confirmPassword').valid) {
      this.toastr.error('Confirm Password is required.');
      return;
    }
    const data = {
      requestType: 'PASSWORD_RESET',
      linkId: this.resetPasswordForm.get('customerNumber').value,
      otp: this.resetPasswordForm.get('otp').value,
      newPassword: this.resetPasswordForm.get('newPassword').value
    };
    this.spinner.show();
    this.utilities.postServiceCall(data, 'portal/request').subscribe(response => {
      const serverResponse = response.json();
      if (serverResponse.code == 2000) {
        this.hideCustomerNumberVerifyForm = true;
        this.hidePaswordResetForm = true;
        this.isResetCompleted = true;
       } else {
         this.toastr.error(serverResponse.message);
       }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error('Something went wrong', 'Please try again');
    });
  }
}
