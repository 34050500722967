import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-settings-management',
  templateUrl: './settings-management.component.html',
  styleUrls: ['./settings-management.component.scss']
})
export class SettingsManagementComponent extends SharedClassComponent implements OnInit {

  title = 'System Settings';
  modelName = 'app/settings/list';
  showPopUpDialog = false;

  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      settingName: new FormControl('', Validators.compose([Validators.required])),
      settingCurrentValue: new FormControl('', Validators.compose([Validators.required])),
      settingPreviousValue: new FormControl('', Validators.compose([])),
    });
    this.onGetParamsdetails();
  // Controls the datagrid height and max rows to display
    this.appInfo.setTitle(this.title);
    this.observerCall();
  }


    // insert settings
    insertSettings(): void {
      if (this.paramwinfrm.invalid) {
        this.toastr.error('Please provide all details required');
        return;
      }
      const data = {
        settingName: this.paramwinfrm.get('settingName').value,
        settingValue: this.paramwinfrm.get('settingCurrentValue').value
      };

      this.utilities.postServiceCall(data, 'app/settings/register').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.paramswinpnl = false;
          this.onGetParamsdetails();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    // update settings
    updateSettings(): void {
      if (this.paramwinfrm.get('settingCurrentValue').invalid) {
        this.toastr.error('Setting value is required');
        return;
      }
      const data = {
        settingId: this.currentData.id,
        settingValue: this.paramwinfrm.get('settingCurrentValue').value
      };

      this.utilities.postServiceCall(data, 'app/settings/update').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.paramswinpnl = false;
          this.onGetParamsdetails();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    // delete settings
    deleteSettings(): void {
      const data = {
        settingId: this.currentData.id
      };
      const result = confirm(
        'Are You sure You want to delete the selected record?',
        'Delete Record'
      );
      result.then(dialogResult => {
        if (dialogResult) {
          this.utilities.postServiceCall(data, 'app/settings/delete').subscribe(res => {
            const response = res;
            if (response.statusCode === 2000) {
              this.toastr.success(response.statusMessage);
              this.paramswinpnl = false;
              this.onGetParamsdetails();
            } else {
              this.toastr.error(response.statusMessage);
            }
          }, error => {
            this.toastr.error(error.message);
          });
        }
      });
    }

}
