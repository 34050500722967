import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-accounting-management',
  templateUrl: './accounting-management.component.html',
  styleUrls: ['./accounting-management.component.scss']
})
export class AccountingManagementComponent extends SharedClassComponent  implements OnInit {

  title = 'Accounting Management';
  tab_paneldata: any = [
    {
      ID: 1,
      name: 'Accounts Management',
    },
    {
      ID: 2,
      name: 'Account Operations',
    },
    {
      ID: 3,
      name: 'Fund Transfer',
    },
    {
      ID: 4,
      name: 'Fund Transfer Report',
    },
    {
      ID: 5,
      name: 'Sales Report',
    }
  ];

  modelName = 'accounts/all';
  showPopUpDialog = false;

  accountPrefix = [
    {
      id: 101,
      prefix: 'ASSETS'
    },
    {
      id: 102,
      prefix: 'LIABILITIES'
    },
    {
      id: 103,
      prefix: 'EQUITY'
    },
    {
      id: 104,
      prefix: 'REVENUE'
    },
    {
      id: 105,
      prefix: 'EXPENSES'
    },
    {
      id: 106,
      prefix: 'CASH'
    },
    {
      id: 107,
      prefix: 'BANK'
    },
    {
      id: 108,
      prefix: 'VAT'
    }
  ];
  AccountOperationsDataSource = [];
  fundTransferForm: FormGroup;
  paymentsDataSource = [];
  fundTransferDataSource = [];
  oneWeekAgo = new Date();
  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      accountName: new FormControl('', Validators.compose([Validators.required])),
      accountNumber: new FormControl('', Validators.compose([Validators.required])),
      markedForCredits: new FormControl('', Validators.compose([Validators.required])),
      markedForDebits: new FormControl('', Validators.compose([Validators.required])),
      markedForSalesCollectionAccount: new FormControl(false, Validators.compose([])),
      accountStatus: new FormControl('', Validators.compose([Validators.required])),
      accountEnabled: new FormControl('', Validators.compose([Validators.required])),
      accountBalance: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([Validators.required])),
      accountPrefix: new FormControl(this.accountPrefix[0], Validators.compose([Validators.required]))
    });

    this.fundTransferForm =  new FormGroup({
      fromAccount: new FormControl(null, Validators.compose([Validators.required])),
      toAccount: new FormControl(null, Validators.compose([Validators.required])),
      amount: new FormControl(null, Validators.compose([Validators.required])),
      currency: new FormControl('TZS', Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(3000)])),
    });
    this.appInfo.setTitle(this.title);
    this.oneWeekAgo.setDate(this.oneWeekAgo.getDate() - 7);
    this.onGetParamsdetails();
    // this.fetchAccountOperations();
    this.onGetDebitAccounts();
    // this.fetchData();
    // this.fetchPaymentData()
  // Controls the datagrid height and max rows to display
    this.appInfo.setTitle(this.title);
    this.observerCall();
  }


    // insert settings
    addAccount(): void {

      if (this.paramwinfrm.get('markedForCredits').value === null) {
        this.paramwinfrm.get('markedForCredits').patchValue(false);
      }

      if (this.paramwinfrm.get('markedForDebits').value === null) {
        this.paramwinfrm.get('markedForDebits').patchValue(false);
      }

      if (this.paramwinfrm.get('markedForSalesCollectionAccount').value === null) {
        this.paramwinfrm.get('markedForSalesCollectionAccount').patchValue(false);
      }

      if (this.paramwinfrm.get('accountName').invalid) {
        this.toastr.error('Account Name is required');
        return;
      }
      if (this.paramwinfrm.get('accountPrefix').invalid) {
        this.toastr.error('Account Prefix is required');
        return;
      }
      if (this.paramwinfrm.get('description').invalid) {
        this.toastr.error('Account descriptionis required');
        return;
      }

      // if (this.paramwinfrm.invalid) {
      //   this.toastr.error('Please provide all details required');
      //   return;
      // }
      const data = {
        accountName: this.paramwinfrm.get('accountName').value,
        accountPrefix: this.paramwinfrm.get('accountPrefix').value,
        accountMarkedForCredit: this.paramwinfrm.get('markedForCredits').value,
        accountMarkedForDebit: this.paramwinfrm.get('markedForDebits').value,
        salesCollectionAccount: this.paramwinfrm.get('markedForSalesCollectionAccount').value,
        description: this.paramwinfrm.get('description').value
      };

      this.utilities.postServiceCall(data, 'accounts/register').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.paramswinpnl = false;
          this.onGetParamsdetails();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    updateAccount(): void {

      if (this.paramwinfrm.get('markedForCredits').value === null) {
        this.paramwinfrm.get('markedForCredits').patchValue(false);
      }

      if (this.paramwinfrm.get('markedForDebits').value === null) {
        this.paramwinfrm.get('markedForDebits').patchValue(false);
      }

      if (this.paramwinfrm.get('markedForSalesCollectionAccount').value === null) {
        this.paramwinfrm.get('markedForSalesCollectionAccount').patchValue(false);
      }

      if (this.paramwinfrm.invalid) {
        this.toastr.error('Please provide all details required');
        return;
      }
      const data = {
        id: this.currentData.id,
        accountName: this.paramwinfrm.get('accountName').value,
        accountPrefix: this.paramwinfrm.get('accountPrefix').value,
        markedForCredits: this.paramwinfrm.get('markedForCredits').value,
        markedForDebits: this.paramwinfrm.get('markedForDebits').value,
        salesCollectionAccount: this.paramwinfrm.get('markedForSalesCollectionAccount').value,
        description: this.paramwinfrm.get('description').value
      };

      this.utilities.postServiceCall(data, 'accounts/update').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.paramswinpnl = false;
          this.onGetParamsdetails();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    creditAccount(e): void {

      const amount = prompt('Please enter amount to credit');

      if (amount === null || amount.trim() === '') {
        this.toastr.error('Please enter amount in digits only');
        return;
      }

      if (isNaN(+amount)) {
        this.toastr.error('Please enter amount in digits only');
        return;
      }

      if (+amount < 1) {
        this.toastr.error('Please enter amount greater than zero');
        return;
      }

      const data = {
        accountNumber: e.accountNumber,
        creditedAmount: +amount
      };

      this.utilities.postServiceCall(data, 'accounts/credit').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.paramswinpnl = false;
          this.onGetParamsdetails();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    enableDisableAccount(e): void {
      const accountEnabled: boolean = e.accountEnabled ? false : true;
      const data = {
        accountId: e.id,
        accountEnabled
      };
      const result = confirm(
        `Are You sure You want to ${accountEnabled ? 'Enable account' : 'Disable account'} ?`,
        `${accountEnabled ? 'Enable account' : 'Disable account'}`
      );
      result.then(dialogResult => {
        if (dialogResult) {

          this.utilities.postServiceCall(data, 'accounts/status').subscribe(res => {
            const response = res;
            if (response.statusCode === 2000) {
              this.toastr.success(response.statusMessage);
              this.paramswinpnl = false;
              this.onGetParamsdetails();
            } else {
              this.toastr.error(response.statusMessage);
            }
          }, error => {
            this.toastr.error(error.message);
          });
        }
      });
    }
    fetchAccountOperations() {
      this.utilities.postServiceCall(this.data, 'accounts/account-operations').subscribe(
        response => {
          const serverResponse = response;
          this.AccountOperationsDataSource = [];
          for (const iterator of serverResponse.data) {
              this.AccountOperationsDataSource.push(
                {
                    id: iterator.id,
                    accountName: iterator.accountName,
                    accountNumber: iterator.accountNumber,
                    amount: iterator.amount,
                    transactionDate: iterator.createdAt,
                    createdBy: iterator.createdBy,
                    operation: iterator.operation
                }
              );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onFundTransferHandler(): void {
      if (this.fundTransferForm.invalid) {
        this.toastr.error('Please provide required informations');
        return;
      }
      if (`${this.fundTransferForm.get('fromAccount').value}`.match(`${this.fundTransferForm.get('toAccount').value}`)) {
        this.toastr.error('Debit account and credit account can not be the same');
        return;
      }
      if (+this.fundTransferForm.get('amount').value < 1) {
        this.toastr.error('Amount must be above zero (0)');
        return;
      }
      this.utilities.postServiceCall(this.fundTransferForm.value, 'accounts/fund-transfer').subscribe(
        response => {
          const data = response;
          if (data.statusCode === 2000) {
            this.toastr.success(data.statusMessage);
            this.fundTransferForm.reset();
            this.fundTransferForm.get('currency').patchValue('TZS');
          } else {
            this.toastr.error(data.statusMessage);
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    fetchData() {
      this.utilities.postServiceCall(this.data, 'accounts/fund-transfer/list').subscribe(
        response => {
          const serverResponse = response;
          if (serverResponse.statusCode === 2000) {
            this.fundTransferDataSource = [];
          for (const el of serverResponse.data) {
            this.fundTransferDataSource.push(el);
          }
          } else {
            this.toastr.error(serverResponse.statusMessage);
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

   hasTxnReversedDescription(e): boolean {
     const desc = `${e.description}`;

     if (desc.startsWith('Transaction')) {
       return true;
     }
     return false;
   }

   eligibleToReverseTxn(e) {
    if (new Date(e.createdAt) > this.oneWeekAgo) {
      return false;
    }
    return true;
   }

    onTransactionReverse(e) {
      const data = {
        id: e.id,
        fromAccountName: e.toAccount,
        toAccountName: e.fromAccount,
        reverse: true,
        amount: e.amount
      };

      const result = confirm(`Are you sure you want to reverse selected transaction ?`, 'Transaction Reversal');

      result.then(dialogResult => {
      if (dialogResult) {
      this.utilities.postServiceCall(data, 'accounts/fund-transfer').subscribe(
        response => {
          const data = response;
          if (data.statusCode === 2000) {
            this.toastr.success('Transaction Reversal completed.');
            this.fetchData();
          } else {
            this.toastr.error(data.statusMessage);
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
        }
      });
    }
    fetchPaymentData() {
      this.utilities.postServiceCall(this.data, 'sales/payments').subscribe(
        response => {
          const serverResponse = response;
          this.paymentsDataSource = [];
            for (const iterator of serverResponse.data) {
              this.paymentsDataSource.push(
                {
                  paymentRef: iterator.paymentRef,
                  paymentDate: iterator.paymentDate,
                  paymentType: iterator.paymentType,
                  paymentAmount: iterator.paymentAmount,
                  salesId: iterator.sales.id,
                  addDetails: iterator.addDetails,
                  createdBy: iterator.createdBy
                }
              );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onBillToolsBarPreparingAccountOp (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: this.fetchAccountOperations.bind(this)
          }
        }
      );
    }

    onBillToolsBarPreparingFundTransferReport (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: this.fetchData.bind(this)
          }
        }
      );
    }

    onBillToolsBarPreparingPaymentData (e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: this.fetchPaymentData.bind(this)
          }
        }
      );
    }
}
