
export const tokAdmin = [
  {
   text: 'Home',
   icon: "fa fa-home",
   path: "/welcome"
  },
  {
    text:"Cost Center",
    icon: "fa fa-home",
    path: "/register-cost"
  },
  {
    text: 'Register Asset',
    icon: 'fa fa-fax',
    path: '/register-asset'
  },
 
  {
    text: "Available Units",
    icon: "money",
    path: "/available-units"
  },
  // {
  //   text: "Users",
  //   icon: "fa fa-users",
  //   path: "/user-management"
  // },
  // {
  //   text: 'Security',
  //   icon: 'fa fa-shield',
  //   path: '/security-manager'
  // },
 
  
       {
        text: "Unit Report",
        icon: "fa fa-book",
        path: '/unit-report'
      },
      {
        text: "Cost Center Report",
        icon: "fa fa-book",
        path: '/cost-report'
      },
     
      {
        text: "All Cost Center Report",
        icon: "fa fa-book",
        path: '/all-cost-report'
      }
    ]
  


export const tokAccountant = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Sales',
    icon: 'cart',
    items: [
      {
        text: "Approve Sales",
        icon: "fa fa-check-circle",
        path: "/approve-sales"
      },
      {
        text: "Daily Sales",
        icon: "money",
        path: "/daily-sales"
      }
    ]
  },
  {
    text: 'Stock',
    icon: 'fa fa-archive',
    items: [
      {
        text: 'Units Management',
        icon: 'fa fa-balance-scale',
        path: '/units-management'
      },
      {
        text: 'New Stock',
        icon: 'fa fa-plus-square-o',
        path: '/new-stock'
      },
      {
        text: "Stock Category",
        icon: "fa fa-server",
        path: "/stock-category"
      },
      {
        text: "Upload Categories",
        icon: "fa fa-upload",
        path: "/stock/categories/upload"
      },
      {
        text: "Suppliers",
        icon: "fa fa-users",
        path: "/supplier"
      }
    ]
  },
  {
    text: 'Purchase Order',
    icon: 'fa fa-list-alt',
    items: [
      {
        text: 'New P.Order',
        icon: 'fa fa-file-text-o',
        path: '/new-porder'
      },
      {
        text: "Amend P.Order",
        icon: "fa fa-indent",
        path: "/amend-porder"
      },
      {
        text: "Pending P.Orders",
        icon: "fa fa-clock-o",
        path: "/pending-porder"
      },
      {
        text: "Completed P.Orders",
        icon: "fa fa-list-ol",
        path: "/completed-porder"
      }
    ]
  },
  {
    text: 'Expenses',
    icon: "fa fa-pie-chart",
    items: [
      {
        text: 'Add Expenses',
        path: '/add-expenses',
        icon: 'movetofolder'
      },
      {
        text: 'Expense Categories',
        icon: 'hierarchy',
        path: '/expenses-category'
      },
      {
        text: "Approve Expenses",
        icon: "exportselected",
        path: "/approve-expenses"
      }
    ]
  },
  {
    text: 'App Manager',
    icon: 'product',
    items: [
      {
        text: 'Security',
        icon: 'fa fa-shield',
        path: '/security-manager'
      },
      {
        text: 'Shop Store',
        icon: 'fa fa-home',
        path: '/store-management'
      },
      {
        text: 'Department',
        icon: 'fa fa-university',
        path: '/department-management'
      }
    ]
  },
  {
    text: 'Accounting',
    icon: 'fa fa-credit-card',
    items: [
      {
        text: 'Accounts',
        icon: 'activefolder',
        path: '/account-management'
      },
      {
        text: 'Close Biz Day',
        icon: 'fa fa-archive',
        path: '/eod-close'
      },
      {
        text: 'Account Setup',
        icon: 'fa fa-cog',
        path: '/running-accounts'
      },
      {
        text: 'Account Operation',
        path: '/account-operations',
        icon: 'fa fa-line-chart'
      },
      {
        text: 'Fund Transfer',
        path: '/fund-transfer',
        icon: 'fa fa-exchange'
      }
    ]
  },
  {
    text: 'Reports',
    icon: 'fa fa-envelope',
    items: [
      {
        text: 'Sales Report',
        path: '/sales-report'
      },
      {
        text: 'Product Sales Report',
        path: '/product-sales-report'
      },
      {
        text: 'EOD Report',
        path: '/eod-report',
      },
      {
        text: 'Double Entry Report',
        path: '/double-entry-report'
      },
      {
        text: 'Expenses Report',
        path: '/expenses-report'
      },
      {
        text: 'PO Report',
        path: '/po-report'
      },
      {
        text: 'Vat Return Report',
        path: '/vat-return-report'
      },
      {
        text: 'Cash Disbursment',
        path: '/disbursement-report'
      },
      {
        text: 'Discount Allowed',
        path: '/discount-allowed-report'
      },
      {
        text: 'Discount Received',
        path: '/discount-received-report'
      },
      {
        text: 'Supplier Payments',
        path: '/supplier-payments-report'
      }
    ]
  }
];

export const tokSeller = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Sales',
    icon: 'cart',
    items: [
      {
        text: 'Register Sales',
        icon: 'fa fa-fax',
        path: '/register-sales'
      },
      {
        text: "Daily Sales",
        icon: "money",
        path: "/daily-sales"
      }
    ]
  },
  {
    text: 'App Manager',
    icon: 'product',
    items: [
      {
        text: 'Security',
        icon: 'fa fa-shield',
        path: '/security-manager'
      }
    ]
  }
];

export const tokStoreKeeper = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Sales',
    icon: 'cart',
    items: [
      {
        text: "Deliver Stock",
        icon: "fa fa-shopping-cart",
        path: "/deliver-stock"
      },
      {
        text: "Daily Sales",
        icon: "money",
        path: "/daily-sales"
      }
    ]
  },
  {
    text: 'App Manager',
    icon: 'product',
    items: [
      {
        text: 'Security',
        icon: 'fa fa-shield',
        path: '/security-manager'
      }
    ]
  }
];

