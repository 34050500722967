import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-stock-adjustment',
  templateUrl: './stock-adjustment.component.html',
  styleUrls: ['./stock-adjustment.component.scss']
})
export class StockAdjustmentComponent extends SharedClassComponent implements OnInit {

  title = 'Stock Adjustment';

  stockAdjustmentForm: FormGroup;
  stockSearchForm: FormGroup;
  hideStockAdjustmentForm = true;

  showAlertDialog = false;
  showSuccessDialog = false;
  showWarningDialog = false;
  alertReason = '';

  productCode: any;
  productName: any;
  itemSize: any;
  availableStockQty: any;
  sellprice: any;
  unitCost: any;
  store: any;
  productDescription: any;
  currency: any;
  maxLength = 250;


  ngOnInit() {
    this.stockAdjustmentForm = new FormGroup({
      productCode: new FormControl(null, Validators.compose([Validators.required])),
      quantity: new FormControl(null, Validators.compose([Validators.required])),
      reason: new FormControl(null, Validators.compose([Validators.required]))
    });
    this.stockSearchForm = new FormGroup({
      productCode: new FormControl(null, Validators.compose([Validators.required]))
    });
  }

  hideDialog() {
    this.showAlertDialog = false;
  }

  hideSuccessDialod() {
    this.showSuccessDialog = false;
  }

  onsearchProductCode() {
    if (this.stockSearchForm.invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please enter product code first.`;
      return;
    }

    this.utilities.postServiceCall(this.stockSearchForm.value, 'product/code/check').subscribe(res => {
      if (res.statusCode == 2000) {
        this.productCode = res.data.categoryCode;
        this.productName = res.data.categoryName;
        this.itemSize = res.data.itemSize;
        this.availableStockQty = res.data.stockItemsQty;
        this.sellprice = res.data.sellPrice;
        this.unitCost = res.data.unitCost;
        this.store = res.data.stockStore.storeName;
        this.productDescription = res.data.description;
        this.currency = '/= TZS';
        this.stockAdjustmentForm.get('productCode').patchValue(res.data.categoryCode);
        this.hideStockAdjustmentForm = false;
      } else {
        this.hideStockAdjustmentForm = true;
        this.clearData();
        this.showAlertDialog = true;
        this.alertReason = res.statusMessage;

      }

    }, error => {
      this.showAlertDialog = true;
      this.alertReason = 'Failed to search product code, please check your internet';
    });

  }

  clearData() {
    this.productCode = null;
    this.productName = null;
    this.itemSize = null;
    this.availableStockQty = null;
    this.sellprice = null;
    this.unitCost = null;
    this.store = null;
    this.productDescription = null;
    this.currency = null;
    this.stockAdjustmentForm.reset();
    this.stockSearchForm.reset();
  }

  onStockAdjustment() {

    if (this.stockAdjustmentForm.invalid) {
      this.showAlertDialog = true;
      this.alertReason = `Please fill all the required details to proceed.`;
      return;
    }

    const result = confirm(
      `Are you sure you want to submit stock adjustment request, click Yes to submit, click No to cancel`,
      `Stock Adjustment`
    );

    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(this.stockAdjustmentForm.value, 'product/stock/adjust').subscribe(res => {
          if (res.statusCode == 2000) {
            this.clearData();
            this.hideStockAdjustmentForm = true;
            this.showSuccessDialog = true;
            this.alertReason = res.statusMessage;
          } else {
            this.showAlertDialog = true;
            this.alertReason = res.statusMessage;
          }

        }, error => {
          this.showAlertDialog = true;
          this.alertReason = 'Failed to search product code, please check your internet';
        });
      }
    });
  }

}
