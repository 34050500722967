import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends SharedClassComponent  implements OnInit {

  title = 'System Users';
  modelName = 'users/list';
  users = [];


  ngOnInit() {
    this.paramwinfrm =  new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      username: new FormControl(null, Validators.compose([Validators.required])),
      firstName: new FormControl(null, Validators.compose([Validators.required])),
      lastName: new FormControl(null, Validators.compose([Validators.required])),
      mobile: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.getAllUsers();
    this.appInfo.setTitle(this.title);
    this.observerCall();
  }

  createUser(): void {

    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please enter all required fields');
      return;
    }

    const data = {
      "username":this.paramwinfrm.get('username').value,
      "firstName":this.paramwinfrm.get('firstName').value,
      "lastName":this.paramwinfrm.get('lastName').value,
      "mobile":this.paramwinfrm.get('mobile').value,
      "createdBy":this.authService.getUserName()
    }

    this.utilities.postServiceCall(data, 'user/create').subscribe(res => {
      const response = res;
      if (response.statusCode == 200) {
        this.toastr.success(response.statusMessage);
        this.paramswinpnl = false;
        this.getAllUsers();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  getAllUsers(): void {
    this.utilities.postServiceCall(this.data, 'users').subscribe(
      response => {
        this.users = response.data;
      },
      error => {
        this.toastr.error(error.message);
        console.error(error);
      }
    );
  }


  onToolBarPreparing(e): void {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewParameter.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getAllUsers.bind(this)
        }
      }
    );
  }

}
