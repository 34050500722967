import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-all-cost-report',
  templateUrl: './all-cost-report.component.html',
  styleUrls: ['./all-cost-report.component.scss']
})
export class AllCostReportComponent extends SharedClassComponent implements OnInit {

  title = 'Asset Report';
  assetsDatasource = [];
  elementType = 'svg';
  value = 'someValue12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 2;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  selectedRowsData = [];

  imageSrc = '';

  assetsCount = 0;

  imgBase = 'https://asset.digilabs.co.tz/v1/public/api/image/';

  noImgSrc = 'https://asset.digilabs.co.tz/v1/public/api/image/no-im.png';

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.onGetAssetData();
  }

  onGetAssetData() {
    this.utilities.getServiceCall('cost_centers/all-cost').subscribe(res => {
      if (res.statusCode == 200) {
     // this.toastr.success(res.statusMessage);
      this.assetsDatasource = [];
      for (const iterator of res.data) {
        if (iterator.status == 1) {
          this.assetsDatasource.push(iterator);
        }
      }

      this.assetsCount = this.assetsDatasource.length;

    } else {
      this.toastr.error(res.statusMessage);

    }
    }, err =>{
      this.toastr.error('Failed to get cost center data');
    })
  }

  onAssetRowSelected(e) {
    this.selectedRowsData = [];
    for (const iterator of e.selectedRowsData) {
      this.selectedRowsData.push({
        id: iterator.id,
        villaId: iterator.villaId,
        costId: iterator.costId,
        assetName: iterator.assetName,
        villaName: iterator.villaName,
        costName: iterator.costName,
        assetLocation: iterator.assetLocation,
        assetPrice: iterator.assetPrice,
        assetDepreciation: iterator.assetDepreciation,
        assetQuantity: iterator.assetQuantity,
        totalItemAmt: iterator.totalItemAmt,
        assetDescription: iterator.assetDescription,
        barcode: iterator.barcode,
        createdBy: iterator.createdBy,
        imageBase: iterator.imageBase == null ? this.noImgSrc : this.imgBase+iterator.imageBase,
        status: iterator.status,
        created_at: iterator.created_at,
        updated_at: iterator.updated_at
      });
    }
    console.log(this.selectedRowsData);
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
      location: 'before',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-print',
        onClick: this.printBarcode.bind(this)
      }
    },
    // {
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     type: 'default',
    //     icon: 'fa fa-recycle',
    //     onClick: this.onGetAssetData.bind(this)
    //   }
    // }
    );
  }

  printBarcode() {
    if (this.selectedRowsData.length == 0) {
      this.toastr.error('Please select asset first to print barcode.!');
      return;
    }
    // if (this.selectedRowsData.length > 24) {
    //   this.toastr.error('Please select only 24 assets at a time to print the barcodes');
    //   return;
    // }
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="./assets/css/bootstrap.min.css" type="text/css" rel="stylesheet"/>
           <style>
           body{
            font-size: 16px;
            background:#fff;
            color:#000;
          }
          .table-bordered th, .table-bordered td {
            border: 1px solid #000 !important;
          }

          @media print {
          @page {
            size: A4;
            margin: 0mm;
          }

          body {
            margin: 10 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            background:#fff;
            color:#000;
            width: 100%;
          }

          .table-bordered th, .table-bordered td {
            border: 1px solid #000 !important;
          }

          @media print {
            .pagebreak { page-break-before: always; } /* page-break-after works, as well */
        }
            h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }
            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }
           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

}
