import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PercentPipe } from '@angular/common';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { DxDataGridComponent } from 'devextreme-angular';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  templateUrl: 'home.component.html',
  styleUrls: [ './home.component.scss' ],
  encapsulation: ViewEncapsulation.None
})

export class HomeComponent extends SharedClassComponent  implements OnInit  {

  now: Date = new Date();
  pipe: any = new PercentPipe('en-US');
  currentYear = new Date().getFullYear();
  oneDayAhead = new Date();
  today = new Date();
  searchForm: FormGroup;
  searchFormExpenses: FormGroup;
  applicationStageTrackerForm: FormGroup;

  packagesBookedSearchForm: FormGroup;
  photoShootsBookedSearchForm: FormGroup;

  showDateRangeUI = false;
  showDateRangeUIExpenses = false;
  totalBookings = 0;
  totalBookingCapacity = 0;
  totalPhotoShootSessions = 0;
  totalSales = 0;
  totalSalesWithDebit = 0;
  totalSalesDebitAmount = 0.0;
  totalSalesWithCredit = 0;
  totalSalesCreditAmount = 0.0;
  totalApprovedSalesCount = 0;
  totalApprovedSalesAmount = 0.0;
  totalUnApprovedSalesCount = 0;
  totalUnApprovedSalesAmount = 0.0;
  totalCancelledSalesCount = 0;
  totalCancelledSalesAmount = 0.0;
  totalSalesAmount = 0.0;
  totalExpensesAmount = 0.0;
  totalExpensesBudget = 0.0;
  completedSalesPercent = 0;


  totalRevenue = 0.0;
  totalExpenses = 0.0;
  netProfit = 0.0;

  financialSearchDatasource = [
    {
      id: 'TODAY',
      name: 'Today'
    },
    {
      id: 'WEEK',
      name: 'This Week'
    },
    {
      id: 'MONTH',
      name: 'This Month'
    },
    {
      id: 'YEAR',
      name: 'This Year'
    },
    {
      id: 'CUSTOM',
      name: 'Custom Dates'
    }
  ];

  applicationStageFilterDatasource = [
    {
      id: 1,
      name: 'Completed'
    },
    {
      id: 2,
      name: 'Started(Ongoing)'
    },
    {
      id: 3,
      name: 'Cancelled'
    }
  ];

  widthPercent = '20%';
  applicationPercent = 20;

  applicationStageTrackerDataSource = [];
  bookingInEachApplicationStageDatasource = [];
  bookingInEachApplicationStatusDatasource = [];
  financialExpensesDatasource = [];
  bookingByPackages = [];
  photoShootTypesByPhotoshootSessionDatasource = [];
  showDateRangeUIPackagesBooked = false;
  showDateRangeUIPhotoShootBooked = false;

  unclosedBookingsCounter = 0;
  closedBookingsCount = 0;

  unapprovedSalesAmount = 0;
  unapprovedExpensesAmount = 0;
  salesDebitAmount = 0;

  unapprovedSalesCount = 0;
  unapprovedExpensesCount = 0;
  salesDebitCount = 0;


  unclosedBookingDatasource = [];

  showUnclosedBookingsData = false;

  // userRole = 'teller';
  userRole = this.authService.getUserRole().replace('_', ' ');

  modelName = 'eod/check';
  totalItemSold = 0;
  totalIncome = 0;
  totalVat = 0;
  unapprovedSales = 0;
  approvedSales = 0;
  approvedSalesAmount = 0;
  cancelledSales = 0;
  dailySalesDataSource = [];

  tab_paneldata: any = [
    {
      ID: 1,
      name: 'Completed Sales',
    },
    {
      ID: 2,
      name: 'Approved Sales',
    },
    {
      ID: 3,
      name: 'Unapproved Sales',
    },
    {
      ID: 4,
      name: 'Cancelled Sales',
    }
  ];

  completedSalesDatasource = [];
  approvedSalesDatasource  = [];
  unapprovedSalesDatasource  = [];
  cancelledSalesDatasource = [];

  closingDate = '';
  totalGoodsSold = 0;
  costOfGoodsSold = 0;
  netRevenue = 0;
  totalRevenueVat = 0;
  totalDiscountAllowedAmount = 0;
  grossProfit = 0;

  productsSold = 0;

  financialPeriodCostOfGoodsSold = 0.0;
  financialPeriodTotalRevenue = 0.0;
  financialPeriodNetProfit = 0.0;
  financialPeriodExpenses = 0.0;

  ngOnInit() {

  this.appInfo.setTitle('Home');

  this.searchForm = new FormGroup({
    searchPeriod: new FormControl(this.financialSearchDatasource[0].id, Validators.compose([Validators.required])),
    startDate: new FormControl(this.today, Validators.compose([Validators.required])),
    endDate: new FormControl(this.today, Validators.compose([Validators.required]))
  });

  this.searchFormExpenses = new FormGroup({
    searchPeriod: new FormControl(this.financialSearchDatasource[0].id, Validators.compose([Validators.required])),
    startDate: new FormControl(this.today, Validators.compose([Validators.required])),
    endDate: new FormControl(this.today, Validators.compose([Validators.required]))
  });

  this.packagesBookedSearchForm = new FormGroup({
    searchPeriod: new FormControl(this.financialSearchDatasource[0].id, Validators.compose([Validators.required])),
    startDate: new FormControl(this.today, Validators.compose([Validators.required])),
    endDate: new FormControl(this.today, Validators.compose([Validators.required]))
  });

  this.photoShootsBookedSearchForm = new FormGroup({
    searchPeriod: new FormControl(this.financialSearchDatasource[0].id, Validators.compose([Validators.required])),
    startDate: new FormControl(this.today, Validators.compose([Validators.required])),
    endDate: new FormControl(this.today, Validators.compose([Validators.required]))
  });

  this.applicationStageTrackerForm = new FormGroup({
    status: new FormControl(this.applicationStageFilterDatasource[1].id, Validators.compose([Validators.required])),
  });

  this.masterMethod();
}

onGetDailySalesData() {
  this.utilities.postServiceCall({}, 'all/sales').subscribe(res => {
    this.completedSalesDatasource = [];
    this.approvedSalesDatasource  = [];
    this.unapprovedSalesDatasource  = [];
    this.cancelledSalesDatasource = [];
    this.unapprovedSales = 0;
    this.unapprovedSalesAmount = 0;
    this.approvedSales = 0;
    this.approvedSalesAmount = 0;
    this.totalItemSold = 0;
    this.totalVat = 0;
    this.totalIncome = 0;
    this.cancelledSales = 0;
    this.totalCancelledSalesAmount = 0;
    for (const iterator of res.data) {
      if (iterator.status == 0) {
        this.unapprovedSales += 1;
        this.unapprovedSalesAmount += +iterator.totalPrice;
        this.unapprovedSalesDatasource.push(iterator);
      }

      if (iterator.status == 1) {
        this.approvedSales += 1;
        this.approvedSalesAmount += +iterator.totalPrice;
        this.approvedSalesDatasource.push(iterator);
      }

      if (iterator.status == 2) {
        this.totalItemSold += iterator.totalItemsSold;
        this.totalVat += +iterator.totalVat;
        this.totalIncome += +iterator.totalPrice;
        this.completedSalesDatasource.push(iterator);
      }

      if (iterator.status == 3) {
        this.cancelledSales += 1;
        this.totalCancelledSalesAmount += +iterator.totalPrice;
        this.cancelledSalesDatasource.push(iterator);
      }

    }
    this.productsSold = this.completedSalesDatasource.length + this.approvedSalesDatasource.length;
    this.dailySalesDataSource = res.data;
  }, err =>{
    this.toastr.error('Failed to retrieve data');
  })
}

onGetEodDetails() {
  this.utilities.postServiceCall({}, this.modelName).subscribe(res => {
    if (res.statusCode == 2000) {
      this.totalGoodsSold = res.data.totalGoodsSold;
      this.costOfGoodsSold = res.data.costOfGoodsSold;
      this.netRevenue = res.data.netRevenue;
      this.totalRevenueVat = res.data.totalRevenueVat;
      this.totalDiscountAllowedAmount = res.data.totalDiscountAllowedAmount;
      this.grossProfit = res.data.grossProfit;
      this.totalExpensesAmount = res.data.totalExpensesAmount;
      this.netProfit = res.data.netProfit;
      this.closingDate = res.data.currentDate;
      this.logger(res.data);
    } else {
      this.toastr.info(res.statusMessage);
    }
  }, error => {
    this.logger(error);
  });
}

reportPeriod(e) {
  if (e.value == 'CUSTOM') {
    this.showDateRangeUI = true;
  } else {
    this.showDateRangeUI = false;
  }
}

reportPeriodExpenses(e) {
  if (e.value == 'CUSTOM') {
    this.showDateRangeUIExpenses = true;
  } else {
    this.showDateRangeUIExpenses = false;
  }
}

reportPeriodPackagesBooked(e) {
  if (e.value == 'CUSTOM') {
    this.showDateRangeUIPackagesBooked = true;
  } else {
    this.showDateRangeUIPackagesBooked = false;
  }
}

reportPeriodPhotoShootBooked(e) {
  if (e.value == 'CUSTOM') {
    this.showDateRangeUIPhotoShootBooked = true;
  } else {
    this.showDateRangeUIPhotoShootBooked = false;
  }
}

onSearchFinancialReport(flag?) {
  if (flag == 1) {
    this.searchForm.get('searchPeriod').patchValue('TODAY');
  }

  let startDatestring = '';
  let endDatestring = '';

  if (this.searchForm.get('searchPeriod').value == 'CUSTOM') {
    if (this.searchForm.invalid) {
      this.toastr.error("Start Date and end Date is required.");
      return;
    }

  startDatestring = this.searchForm.get('startDate').value.getFullYear() + "-"+  ("0"+(this.searchForm.get('startDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.searchForm.get('startDate').value.getDate()).slice(-2);
  const dt = new Date(this.searchForm.get('endDate').value).setDate(new Date(this.searchForm.get('endDate').value).getDate());
  const dd = String(new Date(dt).getDate()).padStart(2, '0');
  const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0');
  const yyyy = new Date(dt).getFullYear();
  endDatestring = yyyy + '-' + mm + '-' + dd;

  if (new Date(startDatestring) > new Date(endDatestring)) {
    this.toastr.error("Start Date is greater than end Date.");
    this.spinner.hide();
    return;
  }

}

  const data = {
    searchPeriod: this.searchForm.get('searchPeriod').value,
    startDate: startDatestring,
    endDate: endDatestring
  }

  this.utilities.postServiceCall(data, 'financial/stats').subscribe(res => {

    if (res.statusCode == 2000) {
      this.financialPeriodTotalRevenue = res.data.totalRevenue;
      this.financialPeriodCostOfGoodsSold = res.data.costOfGoodsSold;
      this.financialPeriodNetProfit = res.data.netProfit;
      this.financialPeriodExpenses = res.data.expenses;
    }
  }, error => {
    console.log('Getting financial stats encountered an error: '+error);
  });
}

onSearchFinancialExpensesReport(flag?) {
  if (flag == 1) {
    this.searchFormExpenses.get('searchPeriod').patchValue('WEEK');
  };

  let startDatestring = '';
  let endDatestring = '';

  if (this.searchFormExpenses.get('searchPeriod').value == 'CUSTOM') {
    if (this.searchFormExpenses.invalid) {
      this.toastr.error("Start Date and end Date is required.");
      return;
  }

  startDatestring = this.searchFormExpenses.get('startDate').value.getFullYear() + "-"+  ("0"+(this.searchFormExpenses.get('startDate').value.getMonth()+1)).slice(-2) + "-" +("0" + this.searchFormExpenses.get('startDate').value.getDate()).slice(-2);
  const dt = new Date(this.searchFormExpenses.get('endDate').value).setDate(new Date(this.searchFormExpenses.get('endDate').value).getDate() + 1);
  const dd = String(new Date(dt).getDate()).padStart(2, '0');
  const mm = String(new Date(dt).getMonth() + 1).padStart(2, '0');
  const yyyy = new Date(dt).getFullYear();
  endDatestring = yyyy + '-' + mm + '-' + dd;

  if (new Date(startDatestring) > new Date(endDatestring)) {
    this.toastr.error("Start Date is greater than end Date.");
    this.spinner.hide();
    return;
  }

}

  const data = {
    searchPeriod: this.searchFormExpenses.get('searchPeriod').value,
    startDate: startDatestring,
    endDate: endDatestring
  }

  this.utilities.postServiceCall(data, 'expenses/stats').subscribe(res => {
    if (res.statusCode == 2000) {
     this.financialExpensesDatasource = res.data;
    }
  }, error => {
    console.log('Getting financial expenses stats encountered an error: '+error);
  });
}

masterMethod() {
  this.onSearchFinancialReport(1);
  this.onGetDailySalesData();
  this.onGetEodDetails();
  this.onSearchFinancialExpensesReport(1);
}

onPointClick(e) {
  e.target.select();
}

customizeTooltip = (arg: any) => ({
  text: `${arg.valueText} - ${this.pipe.transform(arg.percent, '1.2-2')}`,
});

goToLink(link) {
  this.router.navigate([link]);
}

}


