import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-expenses-category',
  templateUrl: './expenses-category.component.html',
  styleUrls: ['./expenses-category.component.scss']
})
export class ExpensesCategoryComponent extends SharedClassComponent implements OnInit {

  title = 'Expenses Categories';

  now = new Date();

  // modelName = 'expenses/categories';
  modelName = 'categories/list';
  approveExpensesDataSource = [];
  expensesDataSource = [];

  expensesForm: FormGroup;


  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      categoryName: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([Validators.required])),
      drAccId: new FormControl('', Validators.compose([Validators.required])),
      crAccId: new FormControl('', Validators.compose([Validators.required])),
      createdAt: new FormControl('', Validators.compose([])),
      createdBy: new FormControl('', Validators.compose([])),
      modifiedAt: new FormControl('', Validators.compose([])),
      modifiedBy: new FormControl('', Validators.compose([]))
    });
    this.observerCall();
    // this.onGetParamsdetails();
    this.onGetDebitAccounts();
    this.onGetExpensesCategories();
  }

  saveParams(path): void {
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please provide information on empty fields');
      return;
    }
    this.utilities.postServiceCall(this.paramwinfrm.value, path).subscribe(res => {
      const response = res;
      if (response.statusCode === 2000) {
        this.toastr.success(response.statusMessage);
        this.paramswinpnl = false;
        this.onGetParamsdetails();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

}
