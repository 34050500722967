import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
//import XLSX from 'xlsx-style/dist/xlsx';
import csv2json from 'csvjson-csv2json/csv2json';

@Component({
  selector: 'app-new-purchase-order',
  templateUrl: './new-purchase-order.component.html',
  styleUrls: ['./new-purchase-order.component.scss']
})
export class NewPurchaseOrderComponent extends SharedClassComponent implements OnInit {
 ngOnInit() {}
}