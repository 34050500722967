import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent extends SharedClassComponent implements OnInit {

  title = 'Sales Report';
  tab_paneldata: any = [
    {
      ID: 1,
      name: 'Completed Sales',
    },
    {
      ID: 2,
      name: 'Approved Sales',
    },
    {
      ID: 3,
      name: 'Unapproved Sales',
    },
    {
      ID: 4,
      name: 'Cancelled Sales',
    }
  ];

  completedSales = [];
  approvedSales = [];
  unapprovedSales = [];
  cancelledSales = [];

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.ongetDailySalesData();
  }

  ongetDailySalesData() {
    this.utilities.postServiceCall({}, 'all/sales').subscribe(res => {
      if (res.data.length == 0) {
        this.toastr.success('No daily sales recorded');
        return;
      }

      this.completedSales = [];
      this.approvedSales = [];
      this.unapprovedSales = [];
      this.cancelledSales = [];

      for (const iterator of res.data) {

        if (iterator.status == 0) {
          this.unapprovedSales.push(iterator);
        }

        if (iterator.status == 1) {
          this.approvedSales.push(iterator);
        }

        if (iterator.status == 2) {
          this.completedSales.push(iterator);
        }

        if (iterator.status == 3) {
          this.cancelledSales.push(iterator);
        }

      }
    }, error => {
      this.logger(error);
    });
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-recycle',
        onClick: this.ongetDailySalesData.bind(this)
      }
    });
  }

}
