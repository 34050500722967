import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';


@Component({
  selector: 'app-department-management',
  templateUrl: './department-management.component.html',
  styleUrls: ['./department-management.component.scss']
})
export class DepartmentManagementComponent extends SharedClassComponent implements OnInit {

  title = 'Department Managemnt';
  modelName = 'users/list';
  departmentDataSource = [];
  hideEditActionButton = true;
  showAddDialog = false;
  popUpTitle = 'Add New Department';
  hideDepartmentStatus = true;

  ngOnInit() {
    this.getAllDepartments();
    this.paramwinfrm =  new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      status: new FormControl(null, Validators.compose([]))
    });
  }

  addNewDepartment() {
    this.hideEditActionButton = true;
    this.showAddDialog = true;
    this.hideDepartmentStatus = true;
    this.paramwinfrm.reset();
  }

  previewDepartment(e) {
    this.hideEditActionButton = false;
    this.popUpTitle = 'Update Department';
    this.showAddDialog = true;
    this.hideDepartmentStatus = false;
    this.paramwinfrm.patchValue(e.data);
    }

  getAllDepartments() {
    this.utilities.postServiceCall({}, 'department/all').subscribe(res => {
      this.departmentDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  createDepartment(): void {

    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill all required details');
      return;
    }

    this.utilities.postServiceCall(this.paramwinfrm.value, 'department').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllDepartments();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  updateDepartment() {

    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill all required details');
      return;
    }

    if (this.paramwinfrm.get('status').value === null || this.paramwinfrm.get('status').value === false) {
      this.paramwinfrm.get('status').patchValue(0);
    } else {
      this.paramwinfrm.get('status').patchValue(1);
    }

    this.utilities.postServiceCall(this.paramwinfrm.value, 'department/update').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllDepartments();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  onToolBarPreparing(e): void {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewDepartment.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getAllDepartments.bind(this)
        }
      }
    );
  }
}
