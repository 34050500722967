import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
    selector: 'app-register-cost',
    templateUrl:'./register-cost.component.html',
    styleUrls: ['./register-cost.component.scss']
})

export class RegisterCostComponent extends SharedClassComponent implements OnInit{
    
    title = 'Add new Cost Center';
    currentYear = new Date().getFullYear();
  
    hideEditActionButton = true;
  
    selectedFiles?: FileList;
    currentFile?: File;
    progress = 0;
    message = '';
    preview = '';
  
    imageInfos?: Observable<any>;
  
    costForm: FormGroup;
    userRole: any
    costFormDatasource: [];
    costFormForm: any;
    showAddDialog: boolean;
    unitCostForm: FormGroup;
  popUpTitle: string;
  unitCostDatasource: any;
  costCenterDatasource: any = [];




    ngOnInit(){
        this.appInfo.setTitle(this.title);
        this.observerCall();
        this.userRole = this.authService.getUserRole();
          
        this.costForm = new FormGroup({
          costName: new FormControl(null, Validators.compose([Validators.required])),
          costLocation: new FormControl(null, Validators.compose([Validators.required])),
          costDescription: new FormControl(null, Validators.compose([Validators.required])),
        });

        this.loadCostCenters()

        this.unitCostForm = new FormGroup({
          costId: new FormControl(Validators.compose([Validators.required])),
          name: new FormControl(null, Validators.compose([Validators.required])),
          location: new FormControl(null, Validators.compose([Validators.required])),
          description: new FormControl(null, Validators.compose([Validators.required])),
        });
    
        this.loadCostCenters()
    }

    loadCostCenters() {
      // fetch data from api
      this.utilities.getServiceCall( 'cost_centers').subscribe(res => {
        this.costCenterDatasource = res.data;
        console.log(this.costCenterDatasource);
        }, err => {
          this.toastr.error('Failed to load cost center, please try again.');
          this.logger(err);
        });
    }
  

    registerCost(){
        if (this.costForm.invalid) {
            this.toastr.error(`Hello ${this.authService.getUserName()}, please fill all required details.`);
            return;
          }
      
             
          const data = {
            costName: this.costForm.get('costName').value,
            costLocation: this.costForm.get('costLocation').value,
            costDescription: this.costForm.get('costDescription').value,
            createdBy: this.authService.getUserName(),
            items: this.costFormDatasource
          }
      
            this.utilities.postServiceCall(data, 'cost_centers/create').subscribe(res => {
            if(res.statusCode == 200) {
              this.toastr.success(res.statusMessage);
              this.costForm.reset();
              this.costFormDatasource = [];
              this.loadCostCenters();
            } else {
              this.toastr.error(res.statusMessage);
            }
            }, err => {
              this.toastr.error('Failed to register cost center, please try again.');
              this.logger(err);
            });
    }

    registerUnitCost(){
      if (this.unitCostForm.invalid) {
          this.toastr.error(`Hello ${this.authService.getUserName()}, please fill all required details.`);
          return;
        }
    
           
        const data = {
          costId: this.unitCostForm.get('costId').value,
          name: this.unitCostForm.get('name').value,
          location: this.unitCostForm.get('location').value,
          description: this.unitCostForm.get('description').value,
          createdBy: this.authService.getUserId(),
          items: this.costFormDatasource
        }
    
          this.utilities.postServiceCall(data, 'unit/create').subscribe(res => {
          if(res.statusCode == 200) {
            this.toastr.success(res.statusMessage);
            this.unitCostForm.reset();
            this.costCenterDatasource = [];
          } else {
            this.toastr.error(res.statusMessage);
          }
          }, err => {
            this.toastr.error('Failed to register unit, please try again.');
            this.logger(err);
          });
  }
   
 onDeleteCost() {
        const result = confirm(
          'Are you sure you want to delete selected cost center from list?',
          'Delete Cost Center'
        );
        result.then(dialogResult => {
          if (dialogResult) {
        for (let index = 0; index < this.costFormDatasource.length; index++) {
          if (this.costFormDatasource[index] == this.costFormForm.get('id').value) {//.id==
            this.costFormDatasource.splice(index, 1);
            this.showAddDialog = false;
            break;
          }
        }
          }
        });
      }
}
