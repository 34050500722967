import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-product-sales-report',
  templateUrl: './product-sales-report.component.html',
  styleUrls: ['./product-sales-report.component.scss']
})
export class ProductSalesReportComponent extends SharedClassComponent implements OnInit {

  title = 'Product Sales report';
  productSalesDatasource = [];
  ngOnInit() {
    this.onGetProductSalesReport();
  }

  onGetProductSalesReport() {
    this.utilities.postServiceCall({}, 'product/sold/all').subscribe(res => {
      this.productSalesDatasource = [];

      for (const iterator of res.data) {
        if (iterator.status == 1 || iterator.status == 2) {
          this.productSalesDatasource.push(iterator);
        }
      }
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onGetProductSalesReport.bind(this)
      }
    });
  }
}
