import { Component, OnInit, NgModule, Input, ViewChild } from '@angular/core';
import { SideNavigationMenuModule, HeaderModule } from '../../shared/components';
import { AuthService, ScreenService } from '../../shared/services';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule, DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';

import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import notify from 'devextreme/ui/notify';
import { BnNgIdleService } from 'bn-ng-idle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit {

  @ViewChild(DxScrollViewComponent, { static: true }) scrollView: DxScrollViewComponent;
  selectedRoute = '';

  menuOpened: boolean;
  temporaryMenuOpened = false;

  data = {
    settingId: '213'
  };
  getIdleTimeoutByName = {
    settingName: 'IDLE_TIMEOUT_IN_SECONDS'
  };
  getSettingsByNameEndpoint = 'app/settings/get/name';
  endPoint = 'app/settings/get/id';
  idleTimeout: number;

  @Input()
  title: string;

  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  totalSuppliers = 0;
  totalUsers = 0;
  userRole: any

  constructor(private screen: ScreenService,
    private router: Router,
    public utilities: UtilitiesService,
    public authService: AuthService,
    public spinner: SpinnerVisibilityService,
    private bnIdle: BnNgIdleService) {
    this.userRole = this.authService.getUserRole();
    const isLoginForm = router.url === '/login-form';
    this.idleTimeout = 600;
    if (!isLoginForm) {
    this.utilities.postServiceCall(this.getIdleTimeoutByName, this.getSettingsByNameEndpoint).subscribe(res => {
      const response = res;
      if(response.code == 2000){
        this.idleTimeout = +response.data.settingCurrentValue;
      } else {
        this.idleTimeout = 3000;
      }
      if (!isLoginForm) {
        this.bnIdle.startWatching(this.idleTimeout).subscribe(result => {
          if (result) {
            this.authService.logOut();
            this.bnIdle.stopTimer();
            notify('You have been logged out due to inactivity.', 'info', 6000);
          }
        });
      }
    }, err => {
      this.idleTimeout = 3000;
    });
  }
  }

  ngOnInit() {
    // this.getAllSuppliers();
    // this.getAllUsers();
    this.menuOpened = this.screen.sizes['screen-large'];

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.selectedRoute = val.urlAfterRedirects.split('?')[0];
      }
    });

    this.screen.changed.subscribe(() => this.updateDrawer());

    this.updateDrawer();
  }

  getAllSuppliers() {
    this.utilities.postServiceCall({}, 'suppliers/all').subscribe(res => {
      this.totalSuppliers = res.data.length;
    });
  }

  getAllUsers(): void{
    this.utilities.postServiceCall(this.data, 'users/list').subscribe(
      response => {
        this.totalUsers = response.data.length;
      }
    );
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 0;
    this.shaderEnabled = !isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event) {
    const path = event.itemData.path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node.selected) {
        pointerEvent.preventDefault();
      } else {
        this.router.navigate([path]);
        this.scrollView.instance.scrollTo(0);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent.stopPropagation();
      }
    } else {
      pointerEvent.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [ SideNavigationMenuModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule, FormsModule,
    ReactiveFormsModule, RouterModule, ],
  exports: [ SideNavOuterToolbarComponent ],
  declarations: [ SideNavOuterToolbarComponent ]
})
export class SideNavOuterToolbarModule { }
