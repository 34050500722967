import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { DxFormModule } from 'devextreme-angular/ui/form';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';
import notify from 'devextreme/ui/notify';
import { AppSettings } from 'src/app/app-settings';
import { AppInfoService, AuthService } from '../../services';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  loading = false;
  formData: any = {};
  loginForm: FormGroup;
  title = 'login';
  loginErrorMessage = '';
  hideLoginErrorMessage = true;
  hideLoginSuccessMessage = true;

  constructor(private authService: AuthService,
     private router: Router,
     private fb: FormBuilder,
      private toastr: ToastrService,
       public appInfo: AppInfoService,
       private utilities: UtilitiesService) {
    this.loginForm = this.fb.group({
      username: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])]
    });
    this.appInfo.setTitle(this.title);
   }

   onUserLoginRequest(): void {
     if (this.loginForm.invalid) {
       this.loginErrorMessage = 'Please provide login credentials to continue';
       this.hideLoginErrorMessage = false;
       return;
     }

    //  console.log('here 1');

     const data = {
      username: this.loginForm.get('username').value,
      password: this.loginForm.get('password').value
    };
    // console.log('here 2');

    this.utilities.postServiceCall(data, 'auth/login').subscribe(res => {
      const response = res;
      // console.log('here 3');
      if (response.statusCode == 200) {
       this.toastr.success(response.statusMessage);
       sessionStorage.clear();
       sessionStorage.setItem(AppSettings.isLoggedInKey, JSON.stringify(true));
       sessionStorage.setItem(AppSettings.jwtTokenKey, JSON.stringify(response.data));
       this.hideLoginErrorMessage = true;
       this.hideLoginSuccessMessage = false;
       this.router.navigate(['/welcome']);
      } else {
        this.loginErrorMessage = response.statusMessage;
        this.hideLoginErrorMessage = false;
        this.toastr.error(response.statusMessage);
      }
      // console.log('here 4');
    }, error => {
      console.log(error);
      // console.log('here 5');
      this.toastr.error(error.message);
    });
    // console.log('here 6');
   }

  onCreateAccountClick = () => {
    this.router.navigate(['/create-account']);
  }
}
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DxFormModule,
    DxLoadIndicatorModule
  ],
  declarations: [ LoginFormComponent ],
  exports: [ LoginFormComponent ]
})
export class LoginFormModule { }
