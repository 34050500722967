import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-printing-page',
  templateUrl: './printing-page.component.html',
  styleUrls: ['./printing-page.component.scss']
})
export class PrintingPageComponent extends SharedClassComponent implements OnInit {

  invoiceBillItems = [];
  invoiceHeader: any;
  qrCodeString = '';
  totalVat = 0;
  totalAmountBeforeVat = 0;
  totalAmountAfterVat = 0;

  supplierDetails: any;

  currentYear = new Date().getFullYear();

  salesDate: any;
  salesBy: any;
  salesNumber: any;
  customerName: any;
  customerNumber: any;
  customerPhone: any;
  salesOrderNumber: any;
  saleStatus: any;


  hidePurchaseOrderForm = true;
  hideSalesOrderForm = true;
  hideDeiveryNoteForm = true;

  fetchedSalesItemsDataSource = [];
  temp: any;
  userName = this.authService.getUserName();

  ngOnInit() {
    // this.hideSalesOrderForm = this.storageService.getHideSalesOrderForm();
    // this.hidePurchaseOrderForm = this.storageService.getHidePurchaseOrderForm();

    this.hideSalesOrderForm = JSON.parse(sessionStorage.getItem(AppSettings.hideSalesOrderFormKey));
    this.hidePurchaseOrderForm = JSON.parse(sessionStorage.getItem(AppSettings.hidePurchaseOrderFormKey));
    this.hideDeiveryNoteForm = JSON.parse(sessionStorage.getItem(AppSettings.hideDeiveryNoteFormKey));

    if (this.hidePurchaseOrderForm == false) {
      this.invoiceHeader = JSON.parse(sessionStorage.getItem(AppSettings.tempKey));
      this.invoiceBillItems = JSON.parse(sessionStorage.getItem(AppSettings.tempKey1));

      if (this.invoiceHeader !== null && this.invoiceBillItems !== null) {
        this.hidePurchaseOrderForm = false;
      if (this.invoiceHeader.hasOwnProperty('specialNote')) {
        this.qrCodeString = sessionStorage.getItem(AppSettings.tempKey);
      }
    }

    if (this.invoiceBillItems == null) {
      this.invoiceBillItems = [];
    }
      if (this.invoiceBillItems.length > 0) {
        for (const iterator of this.invoiceBillItems) {
          if (iterator.hasOwnProperty('vatAmt')) {
            this.totalVat += +iterator.vatAmt;
          }
          if (iterator.hasOwnProperty('net')) {
            this.totalAmountBeforeVat += +iterator.net;
          }
          if (iterator.hasOwnProperty('lineTotal')) {
            this.totalAmountAfterVat += +iterator.lineTotal;
          }
        }
      }
      const supplierdata = {
        supplierId: this.invoiceHeader.supplier
      }
      this.utilities.postServiceCall(supplierdata, 'supplier/get').subscribe(res => {
        this.supplierDetails = res.data;
        setTimeout(() => {
          this.printPurchaseOrder();
        }, 4000);
      }, err => {
        this.toastr.error('Failed to fetch supplier data');
      });
    }
    if (this.hideSalesOrderForm == false || this.hideDeiveryNoteForm == false) {
      this.temp = JSON.parse(sessionStorage.getItem(AppSettings.salesKey));
      // const soDate = this.temp[0].saleDate.getFullYear() + "-"+  ("0"+(this.temp[0].saleDate.getMonth()+1)).slice(-2) + "-" +("0" + this.temp[0].saleDate.getDate()).slice(-2);
      const soDate = ("0" + new Date(this.temp[0].saleDate).getDate()).slice(-2) + "-" + ("0"+(new Date(this.temp[0].saleDate).getMonth()+1)).slice(-2) + "-"+ new Date(this.temp[0].saleDate).getFullYear();
      this.salesDate = soDate;
      this.salesBy = this.temp[0].createdBy;
      this.salesNumber = this.temp[0].saleNo;
      this.customerName = this.temp[0].customerName;
      this.customerPhone = this.temp[0].customerPhone;
      this.salesOrderNumber = this.temp[0].saleNo;

      if (this.temp[0].status == 0) {
        this.saleStatus = 'UNPAID';
      } else if(this.temp[0].status == 1 || this.temp[0].status == 2) {
        this.saleStatus = 'PAID';
      }

      this.fetchedSalesItemsDataSource = this.temp;

      const data = {
        customerName: this.customerName,
        customerPhone: this.customerPhone,
        salesDate: this.salesDate,
        salesBy: this.salesBy,
        salesNumber: this.salesNumber,
        status: this.temp[0].status
      };

      this.qrCodeString = JSON.stringify(data);
    }
  }

  printPurchaseOrder() {
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
           <link href="assets/css/layout.css" rel="stylesheet" type="text/css">
           <link href="assets/css/themes.css" rel="stylesheet" type="text/css">
           <link href="assets/css/typography.css" rel="stylesheet" type="text/css">
           <link href="assets/css/styles.css" rel="stylesheet" type="text/css">
           <link href="assets/css/shCore.css" rel="stylesheet" type="text/css">
           <link href="assets/css/jquery.jqplot.css" rel="stylesheet" type="text/css">
           <link href="assets/css/jquery-ui-1.8.18.custom.css" rel="stylesheet" type="text/css">
           <link href="assets/css/data-table.css" rel="stylesheet" type="text/css">
           <link href="assets/css/form.css" rel="stylesheet" type="text/css">
           <link href="assets/css/ui-elements.css" rel="stylesheet" type="text/css">
           <link href="assets/css/wizard.css" rel="stylesheet" type="text/css">
           <link href="assets/css/sprite.css" rel="stylesheet" type="text/css">
           <link href="assets/css/gradient.css" rel="stylesheet" type="text/css">
           <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<style>
           body{
            font-size: 14px;
            line-height: 1.42857143;
            color: #333;
          }
          .space{
            display: flex;
            justify-content: space-between;
            // border: 1px dotted #4d88bb;
            padding: 1rem 0;
          }
          .btn_control_block{
            display: flex;
            justify-content: space-evenly;
            align-content: center;
          }
          .stripe {
            background-color: rgba(0,0,0,.05);
          }
          .logo__block{
          /* border-bottom: 1px dotted #4d88bb; */
          // border-bottom: 1px dotted rgb(0 172 238);
          }
          .logo__block p, .footer, p {
            /* color: #4d88bb; */
            // color: rgb(0 172 238);
          }
          .invoice__head__child_contacts table tbody tr td{
          /* text-decoration: underline; */
          font-style: italic;
          }
          .kill_margin {
            margin: 0 !important;
            padding: 0 !important;
          }
          .invoice{
            background-color: rgba(0, 0, 0, 0.055);
            padding: 2rem;
            // width: fit-content;
            height: 100%;
            width: 100%;
          }
          .invoice__head,
          .invoice__how_to_pay{
            display: flex;
            justify-content: space-between;
          }
          .invoice__body{
            display: flex;
            /* justify-content: center; */
          }
          .invoice__body table tr th{
            // background: rgb(0 172 238);
            color:white;
            text-align:left;
            vertical-align:center;
          }
          .invoice__body table tr {
            // background-color: rgba(136 ,136 ,136, .1);
            // border: 1px solid #ddd;
            padding: .35em;
          }
          .logo__block{
              text-align: start;
          }
          .footer {
            color:white;
            text-align: center;
          }
          .kill_padding_right{
            padding-right: 0;
          }
          .kill_padding_left{
            padding-left: 0;
          }
          .center_para {
            text-align: center;
          }
          .full_table{
            width: 100%;
          }

          table tbody tr td{
          padding: .5rem;
          }

          @media (max-width: 576px) {
            .btn_control_block{
              display: flex;
              flex-direction: row;
            }
           }

          @media print {
          @page {
            size: A4;
            margin: 0mm;
          }

          html, body {
            width: 1024px;
          }

          body {
            margin: 0 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            background:rgba(0, 0, 0, 0.055);
            color:black;
            width: 100%;
            float: none;
          }
          .invoice__body table tr th{
            padding: 5px;
          }
          .footer{
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
          }
          .footer2{
            position: absolute;
            bottom: 2%;
            width: 100%;
            text-align: center;
            color: #000;
          }
          .invoice{
            background-color: transparent;
          }

          /* avoid page-breaks inside a listingContainer*/
          .listingContainer{
            page-break-inside: avoid;
          }
          h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }
            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }
           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

  printSalesOrder() {
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    // popUpWindow.document.open();
    popUpWindow.document.write(`<html lang="en">
    <head>
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="assets/css/layout.css" rel="stylesheet" type="text/css">
    <link href="assets/css/themes.css" rel="stylesheet" type="text/css">
    <link href="assets/css/typography.css" rel="stylesheet" type="text/css">
    <link href="assets/css/styles.css" rel="stylesheet" type="text/css">
    <link href="assets/css/shCore.css" rel="stylesheet" type="text/css">
    <link href="assets/css/jquery.jqplot.css" rel="stylesheet" type="text/css">
    <link href="assets/css/jquery-ui-1.8.18.custom.css" rel="stylesheet" type="text/css">
    <link href="assets/css/data-table.css" rel="stylesheet" type="text/css">
    <link href="assets/css/form.css" rel="stylesheet" type="text/css">
    <link href="assets/css/ui-elements.css" rel="stylesheet" type="text/css">
    <link href="assets/css/wizard.css" rel="stylesheet" type="text/css">
    <link href="assets/css/sprite.css" rel="stylesheet" type="text/css">
    <link href="assets/css/gradient.css" rel="stylesheet" type="text/css">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">
<style>
    body{
     font-size: 14px;
     line-height: 1.42857143;
     color: #333;
   }
   .unpaid {
    font-weight: bold;
    font-size: larger;
    font-family: monospace;
    color: rgb(129, 2, 2);
    border: 1px dotted rgb(129, 2, 2);
  }

  .paid {
    font-weight: bold;
    font-size: larger;
    font-family: monospace;
    color: #147529;
    border: 1px dotted rgb(0, 82, 18);
  }
   .space{
     display: flex;
     justify-content: space-between;
     // border: 1px dotted #4d88bb;
     padding: 1rem 0;
   }
   .btn_control_block{
     display: flex;
     justify-content: space-evenly;
     align-content: center;
   }
   .stripe {
     background-color: rgba(0,0,0,.05);
   }
   .logo__block{
   /* border-bottom: 1px dotted #4d88bb; */
   // border-bottom: 1px dotted rgb(0 172 238);
   }
   .logo__block p, .footer, p {
     /* color: #4d88bb; */
     // color: rgb(0 172 238);
   }
   .invoice__head__child_contacts table tbody tr td{
   /* text-decoration: underline; */
   font-style: italic;
   }
   .kill_margin {
     margin: 0 !important;
     padding: 0 !important;
   }
   .invoice{
     background-color: rgba(0, 0, 0, 0.055);
     padding: 2rem;
     // width: fit-content;
     height: 100%;
     width: 100%;
   }
   .invoice__head,
   .invoice__how_to_pay{
     display: flex;
     justify-content: space-between;
   }
   .invoice__body{
     display: flex;
     /* justify-content: center; */
   }
   .invoice__body table tr th{
     // background: rgb(0 172 238);
     color:white;
     text-align:left;
     vertical-align:center;
   }
   .invoice__body table tr {
     // background-color: rgba(136 ,136 ,136, .1);
     // border: 1px solid #ddd;
     padding: .35em;
   }
   .logo__block{
       text-align: start;
   }
   .footer {
     color:white;
     text-align: center;
   }
   .kill_padding_right{
     padding-right: 0;
   }
   .kill_padding_left{
     padding-left: 0;
   }
   .center_para {
     text-align: center;
   }
   .full_table{
     width: 100%;
   }

   table tbody tr td{
   padding: .5rem;
   }

   @media (max-width: 576px) {
     .btn_control_block{
       display: flex;
       flex-direction: row;
     }
    }

   @media print {
   @page {
     size: A4;
     margin: 0mm;
   }

   html, body {
     width: 1024px;
   }

   body {
     margin: 0 auto;
     line-height: 1em;
     word-spacing:2px;
     letter-spacing:0.2px;
     /* font: 14px "Times New Roman", Times, serif; */
     font-size: 17px;
     background:rgba(0, 0, 0, 0.055);
     color:black;
     width: 100%;
     float: none;
   }
   .invoice__body table tr th{
     padding: 5px;
   }
   .footer{
     position: absolute;
     bottom: 0;
     width: 100%;
     text-align: center;
   }
   .footer2{
     position: absolute;
     bottom: 2%;
     width: 100%;
     text-align: center;
     color: #000;
   }
   .invoice{
     background-color: transparent;
   }

   /* avoid page-breaks inside a listingContainer*/
   .listingContainer{
     page-break-inside: avoid;
   }
   h1 {
       font: 28px "Times New Roman", Times, serif;
     }

     h2 {
       font: 24px "Times New Roman", Times, serif;
     }

     h3 {
       font: 20px "Times New Roman", Times, serif;
     }

     /* Improve colour contrast of links */
     a:link, a:visited {
       color: #781351
     }
     /* URL */
     a:link, a:visited {
       background: transparent;
       color:#333;
       text-decoration:none;
     }

     a[href]:after {
       content: "" !important;
     }
     a[href^="http://"] {
       color:#000;
     }

     #header {
       height:75px;
       font-size: 24pt;
       color:black
     }
   }
    </style>
    </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

}
