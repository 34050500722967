import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-cash-disbursement-report',
  templateUrl: './cash-disbursement-report.component.html',
  styleUrls: ['./cash-disbursement-report.component.scss']
})
export class CashDisbursementReportComponent extends SharedClassComponent implements OnInit {

  title = 'Cash Disbursment report';
  cashDisbursmentDatasource = [];
  ngOnInit() {
    this.onGetCashDisburmentReport();
  }

  onGetCashDisburmentReport() {
    this.utilities.postServiceCall({}, 'disbursement/all').subscribe(res => {
      this.cashDisbursmentDatasource = res.data;
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onGetCashDisburmentReport.bind(this)
      }
    });
  }

}
