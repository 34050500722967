import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-sms-report',
  templateUrl: './sms-report.component.html',
  styleUrls: ['./sms-report.component.scss']
})
export class SmsReportComponent extends SharedClassComponent implements OnInit {

  title = 'SMS Report';

  smsDataSource = [];
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.getSmsReport();
  }

  getSmsReport() {
    this.utilities.postServiceCallCustom({}, `${AppSettings.apiBaseUrl}sms/list`).subscribe(res => {
      this.smsDataSource = res.data;
    }, error => {
      this.logger(error);
    });
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-recycle',
        onClick: this.getSmsReport.bind(this)
      }
    });
  }

}
