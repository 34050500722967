import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.scss']
})
export class AccountManagementComponent extends SharedClassComponent implements OnInit {

  title = 'Accounting Management';
  modelName = 'accounts/all';
  showPopUpDialog = false;

  accountPrefix = [
    {
      id: '101',
      prefix: 'ASSETS'
    },
    {
      id: '102',
      prefix: 'LIABILITIES'
    },
    {
      id: '103',
      prefix: 'EQUITY'
    },
    {
      id: '104',
      prefix: 'REVENUE'
    },
    {
      id: '105',
      prefix: 'EXPENSES'
    },
    {
      id: '106',
      prefix: 'CASH'
    },
    {
      id: '107',
      prefix: 'BANK'
    },
    {
      id: '108',
      prefix: 'VAT'
    },
    {
      id: '109',
      prefix: 'SUSPENSE'
    }
  ];
  AccountOperationsDataSource = [];
  fundTransferForm: FormGroup;
  paymentsDataSource = [];
  fundTransferDataSource = [];
  oneWeekAgo = new Date();
  accountsDatasource = [];
  accountForm: FormGroup;
  openPopUpDialog = false;
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.accountForm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      accountName: new FormControl('', Validators.compose([Validators.required])),
      accountNumber: new FormControl('', Validators.compose([Validators.required])),
      markedForCredits: new FormControl('', Validators.compose([Validators.required])),
      markedForDebits: new FormControl('', Validators.compose([Validators.required])),
      markedForSalesCollectionAccount: new FormControl(false, Validators.compose([])),
      accountStatus: new FormControl('', Validators.compose([Validators.required])),
      accountEnabled: new FormControl('', Validators.compose([Validators.required])),
      accountBalance: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([Validators.required])),
      accountPrefix: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.fundTransferForm =  new FormGroup({
      fromAccount: new FormControl(null, Validators.compose([Validators.required])),
      toAccount: new FormControl(null, Validators.compose([Validators.required])),
      amount: new FormControl(null, Validators.compose([Validators.required])),
      currency: new FormControl('TZS', Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required, Validators.maxLength(3000)])),
    });
    this.appInfo.setTitle(this.title);
    this.oneWeekAgo.setDate(this.oneWeekAgo.getDate() - 7);
    this.getAccountsList();
  // Controls the datagrid height and max rows to display
    this.appInfo.setTitle(this.title);
    this.observerCall();
  }

  getAccountsList() {
    this.utilities.postServiceCall({}, this.modelName).subscribe(
      response => {
        const data = response;
        this.accountsDatasource = data.data;
      },
      error => {
        this.toastr.error(error.message);
        console.error(error);
      }
    );
  }

  onPreviewAccountDetails(e) {
    this.accountForm.patchValue(e.data);
    this.accountForm.get('accountPrefix').patchValue(e.data.accountType);
    this.hideSaveButton = false;
    this.hideButton = true;
    this.openPopUpDialog=true;
  }

    // insert settings
    addAccount(): void {

      if (this.accountForm.get('markedForCredits').value === null) {
        this.accountForm.get('markedForCredits').patchValue(false);
      }

      if (this.accountForm.get('markedForDebits').value === null) {
        this.accountForm.get('markedForDebits').patchValue(false);
      }

      if (this.accountForm.get('markedForSalesCollectionAccount').value === null) {
        this.accountForm.get('markedForSalesCollectionAccount').patchValue(false);
      }

      if (this.accountForm.get('accountName').invalid) {
        this.toastr.error('Account Name is required');
        return;
      }
      if (this.accountForm.get('accountPrefix').invalid) {
        this.toastr.error('Account Prefix is required');
        return;
      }
      if (this.accountForm.get('description').invalid) {
        this.toastr.error('Account descriptionis required');
        return;
      }

      // if (this.accountForm.invalid) {
      //   this.toastr.error('Please provide all details required');
      //   return;
      // }
      const data = {
        accountName: this.accountForm.get('accountName').value,
        accountPrefix: this.accountForm.get('accountPrefix').value,
        accountMarkedForCredit: this.accountForm.get('markedForCredits').value,
        accountMarkedForDebit: this.accountForm.get('markedForDebits').value,
        salesCollectionAccount: this.accountForm.get('markedForSalesCollectionAccount').value,
        description: this.accountForm.get('description').value
      };

      this.utilities.postServiceCall(data, 'accounts/register').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.openPopUpDialog = false;
          this.getAccountsList();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    updateAccount(): void {

      if (this.accountForm.get('markedForCredits').value === null) {
        this.accountForm.get('markedForCredits').patchValue(false);
      }

      if (this.accountForm.get('markedForDebits').value === null) {
        this.accountForm.get('markedForDebits').patchValue(false);
      }

      if (this.accountForm.get('markedForSalesCollectionAccount').value === null) {
        this.accountForm.get('markedForSalesCollectionAccount').patchValue(false);
      }

      if (this.accountForm.get('accountPrefix').invalid) {
        this.toastr.error('Account prefix is required');
        return;
      }
      if (this.accountForm.get('description').invalid) {
        this.toastr.error('Account description is required');
        return;
      }
      if (this.accountForm.get('accountName').invalid) {
        this.toastr.error('Account accountName is required');
        return;
      }

      // if (this.accountForm.invalid) {
      //   this.toastr.error('Please provide all details required');
      //   return;
      // }
      const data = {
        id: this.accountForm.get('id').value,
        accountName: this.accountForm.get('accountName').value,
        accountPrefix: this.accountForm.get('accountPrefix').value,
        markedForCredits: this.accountForm.get('markedForCredits').value,
        markedForDebits: this.accountForm.get('markedForDebits').value,
        salesCollectionAccount: this.accountForm.get('markedForSalesCollectionAccount').value,
        description: this.accountForm.get('description').value
      };

      this.utilities.postServiceCall(data, 'accounts/update').subscribe(res => {
        const response = res;
        if (response.statusCode === 2000) {
          this.toastr.success(response.statusMessage);
          this.openPopUpDialog = false;
          this.getAccountsList();
        } else {
          this.toastr.error(response.statusMessage);
        }
      }, error => {
        this.toastr.error(error.message);
      });
    }

    enableDisableAccount(e): void {
      const accountEnabled: boolean = e.accountEnabled ? false : true;
      const data = {
        accountId: e.id,
        accountEnabled
      };
      const result = confirm(
        `Are You sure You want to ${accountEnabled ? 'Enable account' : 'Disable account'} ?`,
        `${accountEnabled ? 'Enable account' : 'Disable account'}`
      );
      result.then(dialogResult => {
        if (dialogResult) {

          this.utilities.postServiceCall(data, 'accounts/status').subscribe(res => {
            const response = res;
            if (response.statusCode === 2000) {
              this.toastr.success(response.statusMessage);
              this.openPopUpDialog = false;
              this.getAccountsList();
            } else {
              this.toastr.error(response.statusMessage);
            }
          }, error => {
            this.toastr.error(error.message);
          });
        }
      });
    }

    addNewAccount() {
      this.accountForm.reset();
      this.openPopUpDialog = true;
      this.hideSaveButton = true;
      this.hideButton = false;
    }

    onParamsToolBarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxButton',
          options: {
            text: 'Add',
            type: 'default',
            icon: 'fa fa-plus',
            onClick: this.addNewAccount.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: this.getAccountsList.bind(this)
          }
        }
      );
    }


}
