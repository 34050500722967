import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent extends SharedClassComponent implements OnInit {

  title = 'Analytics';
  modelName = 'eod/check';
  totalItemSold = 0;
  totalIncome = 0;
  totalVat = 0;
  unapprovedSales = 0;
  approvedSales = 0;
  cancelledSales = 0;
  dailySalesDataSource = [];

  tab_paneldata: any = [
    {
      ID: 1,
      name: 'Completed Sales',
    },
    {
      ID: 2,
      name: 'Approved Sales',
    },
    {
      ID: 3,
      name: 'Unapproved Sales',
    },
    {
      ID: 4,
      name: 'Cancelled Sales',
    }
  ];

  completedSalesDatasource = [];
  approvedSalesDatasource  = [];
  unapprovedSalesDatasource  = [];
  cancelledSalesDatasource = [];

  closingDate = '';
  totalGoodsSold = 0;
  costOfGoodsSold = 0;
  netRevenue = 0;
  totalRevenueVat = 0;
  totalDiscountAllowedAmount = 0;
  grossProfit = 0;
  totalExpensesAmount = 0;
  netProfit = 0;


  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.onGetEodDetails();
    this.onGetDailySalesData();
  }

  onGetDailySalesData() {
    this.utilities.postServiceCall({}, 'daily/sales').subscribe(res => {

      this.completedSalesDatasource = [];
      this.approvedSalesDatasource  = [];
      this.unapprovedSalesDatasource  = [];
      this.cancelledSalesDatasource = [];

      for (const iterator of res.data) {
        if (iterator.status == 0) {
          this.unapprovedSales += 1;
          this.unapprovedSalesDatasource.push(iterator);
        }

        if (iterator.status == 1) {
          this.approvedSales += 1;
          this.approvedSalesDatasource.push(iterator);
        }

        if (iterator.status == 2) {
          this.totalItemSold += iterator.totalItemsSold;
          this.totalVat += +iterator.totalVat;
          this.totalIncome += +iterator.totalPrice;
          this.completedSalesDatasource.push(iterator);
        }

        if (iterator.status == 3) {
          this.cancelledSales += 1;
          this.cancelledSalesDatasource.push(iterator);
        }
      }
      this.dailySalesDataSource = res.data;
    }, err =>{

    })
  }

  onGetEodDetails() {
    this.utilities.postServiceCall({}, this.modelName).subscribe(res => {
      if (res.statusCode == 2000) {
        this.totalGoodsSold = res.data.totalGoodsSold;
        this.costOfGoodsSold = res.data.costOfGoodsSold;
        this.netRevenue = res.data.netRevenue;
        this.totalRevenueVat = res.data.totalRevenueVat;
        this.totalDiscountAllowedAmount = res.data.totalDiscountAllowedAmount;
        this.grossProfit = res.data.grossProfit;
        this.totalExpensesAmount = res.data.totalExpensesAmount;
        this.netProfit = res.data.netProfit;
        this.closingDate = res.data.currentDate;

        this.logger(res.data);
      } else {
        this.toastr.info(res.statusMessage);
      }

    }, error => {
      this.logger(error);
    });
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-recycle',
        onClick: this.onGetDailySalesData.bind(this)
      }
    });
  }

}
