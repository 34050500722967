import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { Observable } from 'rxjs';

import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-daily-sales',
  templateUrl: './daily-sales.component.html',
  styleUrls: ['./daily-sales.component.scss']
})
export class DailySalesComponent extends SharedClassComponent implements OnInit {

  title = 'Registered Unit';

  villasDatasource = [];

  villaAssetsDatasource = [];

  showAddDialog = false;
  showUpdateVilla = false;
  showRegisteredAssetsDialog = false;
  showUpdateAssetDialog = false;
  showassetItemUpdateForm = false
  showAssetImage = false;
  assetItemForm: FormGroup;
  assetItemUpdateForm: FormGroup;
  assetForm: FormGroup;

  imageSrc = '';

  imgBase = 'https://asset.digilabs.co.tz/public/api/image/';
  imgBase1 = 'https://asset-api.digilabs.co.tz/public/api/assets/image/';
  noImage = 'https://asset-api.digilabs.co.tz/public/api/unit/image/no-im.jpg';


  // https://asset.digilabs.co.tz/v1/public/api/image/no-im.png

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  preview = '';

  imageInfos?: Observable<any>;
  costCenterDatasource: any = [];
  unitCostDatasource: any;
  assetToUpdate: any;
  unitToUpdate: any;

  viewAssetImage(asset) {
    this.imageSrc = this.imgBase1 + asset.imageBase;
    this.showAssetImage = true;
  }

  fullImg(img): string {
    if (img === "flutter_images/") {
      return this.noImage;
    }
    return this.imgBase1 + img;
  }

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.assetItemForm = new FormGroup({
      unitId: new FormControl(null, Validators.compose([])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      //villaName: new FormControl(null, Validators.compose([])),
      costId: new FormControl(null, Validators.compose([])),
      location: new FormControl(null, Validators.compose([Validators.required])),
      quantity: new FormControl(1, Validators.compose([])),
      price: new FormControl(null, Validators.compose([Validators.required])),
      depreciation: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required])),
    });
    this.assetItemUpdateForm = new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      unitId: new FormControl(null, Validators.compose([])),
      costId: new FormControl(null, Validators.compose([])),
      // villaName: new FormControl(null, Validators.compose([])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      location: new FormControl(null, Validators.compose([Validators.required])),
      quantity: new FormControl(1, Validators.compose([])),
      price: new FormControl(null, Validators.compose([Validators.required])),
      depreciation: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required])),
    });
    this.assetForm = new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      location: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required])),
    });
    this.observerCall();
    //this.onGetVillaData();
    this.loadCostCenters();
    //this.loadUnitCost();
  }
  loadCostCenters() {
    // fetch data from api
    this.utilities.getServiceCall('cost_centers').subscribe(res => {
      this.costCenterDatasource = res.data;
      console.log(this.costCenterDatasource);

    }, err => {
      this.logger(err);
    });
  }

  onGetCost(costId) {
    const data = {
      costId: costId.value,
      // unitId : unitId
    };
    this.utilities.getServiceCall('cost_centers/units/' + costId.value).subscribe(res => {
      if (res.statusCode == 200) {
       // this.toastr.success(res.statusMessage);
        this.unitCostDatasource = [];
        for (const iterator of res.data) {
          if (iterator.status == 1) {
            this.unitCostDatasource.push(iterator);
          }
        }
      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      //this.toastr.error('Failed to add asset to units');
    })
  }
  onGetVillaData(costId) {
    this.utilities.getServiceCall('cost_centers/units/' + costId).subscribe(res => {
      this.unitCostDatasource = [];
      for (const iterator of res.data) {
        if (iterator.status == 1) {
          //console.log(iterator);
          this.unitCostDatasource.push(iterator);
        }
      }
    }, err => {
      this.toastr.error('Failed to get units data');
    })
  }


  selectFile(event: any): void {
    this.message = '';
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          console.log(e.target.result);
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }

  upload(id): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.utilities.upload(this.currentFile, 'assets/image', id).subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.imageInfos = this.utilities.getFiles(event.body.data);
            }
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the image!';
            }
            this.currentFile = undefined;
          },
        });
      }

      this.selectedFiles = undefined;
    }
  }

  onPreviewVilla(e) {
    this.unitToUpdate = e;
    this.assetForm.reset();
    this.assetForm.patchValue(e);
    this.showUpdateVilla = true;
  }

  updateVilla() {
    if (this.assetForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserName()} please fill all required fields.`)
      return;
    }
    this.utilities.putServiceCall(this.assetForm.value, 'unit/update').subscribe(res => {
      if (res.statusCode == 200) {
        this.toastr.success(res.statusMessage);
        this.onGetVillaData(this.unitToUpdate.costId);
        this.showUpdateVilla = false;
      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error('Failed to update units');
    })
  }

  onPreviewUnit(e) {
    this.unitToUpdate = e;
    this.assetForm.reset();
    this.assetForm.patchValue(e);
    this.showUpdateVilla = true;

  }

  onDeleteUnit(e) {
    this.unitToUpdate = e;
    this.assetForm.patchValue(e);


    const result = confirm(
      'Are you sure you want to delete selected units from list it will delete all asset inside?',
      'Delete units'
    );
    console.log(this.assetForm.get('id').value),

      result.then(dialogResult => {
        if (dialogResult) {
          this.utilities.postServiceCall(this.assetForm.value, 'unit/delete').subscribe(res => {
            if (res.statusCode == 200) {
              this.toastr.success(res.statusMessage);
              this.onGetVillaData(this.unitToUpdate.costId);
              this.showUpdateVilla = false;
            } else {
              this.toastr.error(res.statusMessage);
            }
          }, err => {
            this.toastr.error('Failed to delete unit');
          })
        }
      });

  }

  onGetVillaAssets(unitId) {


    this.utilities.getServiceCall('unit/assets/' + unitId).subscribe(res => { // unit/assets/1
      if (res.statusCode == 200) {
       // this.toastr.success(res.statusMessage);
        this.villaAssetsDatasource = [];
        for (const iterator of res.data) {
          if (iterator.status == 1) {
            this.villaAssetsDatasource.push(iterator);
          }
        }
        this.showRegisteredAssetsDialog = true;

      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error('Failed to add asset to units');
    })
  }

  addAsset(e) {
    console.log(e);
    this.assetItemForm.reset();
    this.assetItemForm.get('unitId').patchValue(e.id);
    this.assetItemForm.get('costId').patchValue(e.costId);
    // this.assetItemForm.get('name').patchValue(e.name);

    this.showAddDialog = true;
  }

  addAssetToVilla() {

    if (this.assetItemForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserId()} please fill all required fields.`)
      return;
    }

    if (+this.assetItemUpdateForm.get('depreciation').value > this.assetItemUpdateForm.get('price').value) {
      this.toastr.error('Asset depreciated price can not exceed asset\'s original price');
      return;
    }

    console.log(this.assetItemForm.value);

    console.log(this.assetItemForm.get('unitId').value);

    // return;

    const data = {
      unitId: this.assetItemForm.get('unitId').value,
      costId: this.assetItemForm.get('costId').value,
      name: this.assetItemForm.get('name').value,
      location: this.assetItemForm.get('location').value,
      quantity: 1,
      price: this.assetItemForm.get('price').value,
      depreciation: this.assetItemForm.get('depreciation').value,
      description: this.assetItemForm.get('description').value,
      totalItemAmt: +this.assetItemForm.get('price').value,
      createdBy: this.authService.getUserId()
    };

    this.utilities.postServiceCall(data, 'assets/create').subscribe(res => {
      if (res.statusCode == 200) {
        //this.toastr.success(res.statusMessage);
        this.showAddDialog = false;
        this.upload(res.data.id);
      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error('Failed to add asset to units');
    })
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift({
      // location: 'after',
      // widget: 'dxButton',
      // options: {
      //   type: 'default',
      //   icon: 'fa fa-recycle',
      //   onClick: this.onGetVillaData.bind(this)
      // }
    });
  }

  onUpdateAssetButtonClick(e) {

    this.assetToUpdate = e
    this.assetItemForm.reset();
    this.assetItemUpdateForm.patchValue(e);
    this.showUpdateAssetDialog = true;

  }

  onUpdateAsset() {

    if (this.assetItemUpdateForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserName()} please fill all required fields.`)
      return;
    }

    if (this.assetItemUpdateForm.get('quantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, asset quantity can not be less than one.`)
      return;
    }

    if (+this.assetItemUpdateForm.get('depreciation').value > this.assetItemUpdateForm.get('price').value) {
      this.toastr.error('Asset depreciated price can not exceed asset\'s original price');
      return;
    }

    const data = {
      id: this.assetItemUpdateForm.get('id').value,
      name: this.assetItemUpdateForm.get('name').value,
      location: this.assetItemUpdateForm.get('location').value,
      quantity: 1,
      price: this.assetItemUpdateForm.get('price').value,
      description: this.assetItemUpdateForm.get('description').value,
      depreciation: this.assetItemUpdateForm.get('depreciation').value,
      totalItemAmt: +this.assetItemUpdateForm.get('price').value,
      createdBy: this.authService.getUserId()
    };

    this.utilities.putServiceCall(data, 'assets/update').subscribe(res => {
      if (res.statusCode == 200) {
        console.log(res)
        this.toastr.success(res.statusMessage);
        this.showUpdateAssetDialog = false;
        //this.showRegisteredAssetsDialog = true;
        this.upload(res.data.id);
        this.onGetVillaAssets(this.assetToUpdate.unitId)
      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err => {
      this.toastr.error('Failed to add asset to units');
    })
  }
  onPreviewAsset(e) {
    this.assetForm.reset();
    this.assetForm.patchValue(e);
    this.showUpdateVilla = true;

  }

  onDeleteAsset() {
    const data = {
      id: this.assetItemUpdateForm.get('id').value,
      status: '0'
    };


    const result = confirm(
      'Are you sure you want to delete selected asset from list?',
      'Delete Asset'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(data, 'assets/delete').subscribe(res => {
          if (res.statusCode == 200) {
            this.toastr.success(res.statusMessage);
            this.showUpdateAssetDialog = false;
            this.onGetVillaAssets(this.assetToUpdate.unitId);
          } else {
            this.toastr.error(res.statusMessage);
          }
        }, err => {
          this.toastr.error('Failed to delete asset');
        })
      }
    });
  }



}
