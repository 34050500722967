import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';


@Component({
  selector: 'app-units-management',
  templateUrl: './units-management.component.html',
  styleUrls: ['./units-management.component.scss']
})
export class UnitsManagementComponent extends SharedClassComponent implements OnInit {


  unitsDatasource = [];
  hideEditActionButton = true;
  showAddDialog = false;
  unitForm: FormGroup;

  popUpTitle = '';

  ngOnInit() {
    this.appInfo.setTitle('Units');
    this.observerCall();
    this.unitForm = new FormGroup({
      id: new FormControl(null),
      unitCode: new FormControl(null, Validators.compose([Validators.required])),
      unitName: new FormControl(null, Validators.compose([Validators.required])),
      unitSymbol: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required])),
    });
    this.getAllunits();
  }

  previewUnit(e) {
    this.unitForm.patchValue(e.data);
    this.popUpTitle = 'Update Unit';
    this.hideEditActionButton = false;
    this.showAddDialog = true;
  }

  getAllunits() {
    this.utilities.postServiceCall({}, 'product/units').subscribe(res => {
      this.unitsDatasource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  addNewUnit() {
    this.unitForm.reset();
    this.popUpTitle = 'Add new Unit';
    this.hideEditActionButton = true;
    this.showAddDialog = true;
  }

  createUnit() {
    if (this.unitForm.invalid) {
      this.toastr.error('Please all text fields to proceed.');
      return;
    }
    this.utilities.postServiceCall(this.unitForm.value, 'product/unit/create').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllunits();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  updateUnit() {
    if (this.unitForm.invalid) {
      this.toastr.error('Please all text fields to proceed.');
      return;
    }
    this.utilities.postServiceCall(this.unitForm.value, 'product/unit/update').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllunits();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  updateUnitStatus(e, status) {
    const data = {
      id: e.id,
      status
    }

    const message = `Are sure you want to ${status == 1 ? 'enable': 'disable'}  this unit (ID: ${e.id}) ?`;
    const header = `${status == 1 ? 'Enable': 'Disable'} action`;

    const result = confirm(message, header);

    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(data, 'product/unit/status').subscribe(res => {
          const response = res;
          if (response.statusCode == 2000) {
            this.toastr.success(response.statusMessage);
            this.getAllunits();
          } else {
            this.toastr.error(response.statusMessage);
          }
        }, error => {
          this.toastr.error(error.message);
        });
      }
    });
  }

  onToolBarPreparing(e): void {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          text: 'Add',
          type: 'default',
          icon: 'fa fa-plus',
          onClick: this.addNewUnit.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.getAllunits.bind(this)
        }
      }
    );
  }

}
