import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-security-manager',
  templateUrl: './security-manager.component.html',
  styleUrls: ['./security-manager.component.scss']
})
export class SecurityManagerComponent extends SharedClassComponent implements OnInit {

  title = 'Security Manager';
  changePasswordForm: FormGroup;

  changeUsernameForm: FormGroup;

  password = '';

  ngOnInit(): void {
    this.appInfo.setTitle(this.title);
    this.changePasswordForm = new FormGroup({
      oldPassword: new FormControl(null, Validators.compose([Validators.required])),
      newPassword: new FormControl(null, Validators.compose([Validators.required])),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.changeUsernameForm = new FormGroup({
      password: new FormControl(null, Validators.compose([Validators.required])),
      username: new FormControl(null, Validators.compose([Validators.required]))
    });
  }


  onPasswordChange(): void {
    if (this.changePasswordForm.invalid) {
      this.toastr.error('Please fill passwords to continue');
      return;
    }

    if (this.changePasswordForm.get('newPassword').value != this.changePasswordForm.get('confirmPassword').value) {
      this.toastr.error('Password mismatch between new password and confirm paswword');
      return;
    }

    const data = {
      "username": this.authService.getUserName(),
      "oldPassword": this.changePasswordForm.get('oldPassword').value,
      "newPassword": this.changePasswordForm.get('newPassword').value
  };

    const result = confirm(
      'After successfully changed your password, you will be logged out, do you want to continue?',
      'Password Change Request'
    );
    result.then(dialogResult => {
      if (dialogResult) {
    this.utilities.postServiceCall(data, 'auth/password/change').subscribe(res => {
      const response = res;
      if (response.statusCode == 200) {
        this.toastr.success(response.statusMessage);
        this.authService.logOut();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
      }
    });

  }

  onUsernameChange(): void {
    if (this.changeUsernameForm.invalid) {
      this.toastr.error('Please fill in all the required fields to continue');
      return;
    }

    const result = confirm(
      'After successfully changed your username, you will be logged out, do you to continue?',
      'Username Change Request'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(this.changeUsernameForm.value, 'users/username/change').subscribe(res => {
          const response = res;
          if (response.statusCode === 2000) {
            this.toastr.success(response.statusMessage);
            this.authService.logOut();
          } else {
            this.toastr.error(response.statusMessage);
          }
        }, error => {
          this.toastr.error(error.message);
        });
      }
    });

  }

  passwordComparison = () => {
    return this.password;
  }

}
