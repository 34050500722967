import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-close-day-operation',
  templateUrl: './close-day-operation.component.html',
  styleUrls: ['./close-day-operation.component.scss']
})
export class CloseDayOperationComponent extends SharedClassComponent implements OnInit {

  title = 'Close Business Day';

  now = new Date();

  modelName = 'eod/check';
  // modelName = 'categories/list';
  approveExpensesDataSource = [];
  expensesDataSource = [];
  debitAccountName = '';
  creditAccountName = '';

  closingDate = '';
  totalGoodsSold = 0;
  costOfGoodsSold = 0;
  netRevenue = 0;
  totalRevenueVat = 0;
  totalDiscountAllowedAmount = 0;
  grossProfit = 0;
  totalExpensesAmount = 0;
  netProfit = 0;

  ngOnInit() {
    this.onGetEodDetails();
  }

  onGetEodDetails() {
    this.utilities.postServiceCall({}, this.modelName).subscribe(res => {
      if (res.statusCode == 2000) {
        this.totalGoodsSold = res.data.totalGoodsSold;
        this.costOfGoodsSold = res.data.costOfGoodsSold;
        this.netRevenue = res.data.netRevenue;
        this.totalRevenueVat = res.data.totalRevenueVat;
        this.totalDiscountAllowedAmount = res.data.totalDiscountAllowedAmount;
        this.grossProfit = res.data.grossProfit;
        this.totalExpensesAmount = res.data.totalExpensesAmount;
        this.netProfit = res.data.netProfit;
        this.closingDate = res.data.currentDate;

        this.logger(res.data);
      } else {
        this.toastr.info(res.statusMessage);
      }

    }, error => {
      this.logger(error);
    });
  }

  onCloseBusinessDay() {

    const result = confirm(
      `Are You sure You want to close business day ?. If you continue, you'll not be able close business day for today (${this.closingDate}) `,
      `Close Business Day`
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall({}, 'eod/close').subscribe(res => {
          if (res.statusCode == 2000) {
            this.toastr.success(res.statusMessage);
          } else {
            this.toastr.error(res.statusMessage);
          }

        }, error => {
          this.logger(error);
        });
      }
    });


  }

}
