import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { confirm } from 'devextreme/ui/dialog';

import { AppInfoService, AuthService } from '../../services';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { Router } from '@angular/router';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from '../../services/utilities.service';
import { DxActionSheetModule } from 'devextreme-angular';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title: string;

  user = {
    username: '',
    userRole: ''
  };

  now = new Date().toUTCString();

  appName: string;
  roleName: string = '';
  username: string = '';

  commands: any[] = [
    { text: 'change-password' },
    // { text: 'user-management' },
  ];

  actionSheetVisible = false;

  actionSheetTarget: any = '';

  userMenuItems = [{
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logOut();
    }
  }];

  constructor(private authService: AuthService,
    public appInfo: AppInfoService,
    private router: Router,
    private spinner: SpinnerVisibilityService,
    public toastr: ToastrService,
    private utilityService: UtilitiesService) {
      this.appName = this.appInfo.title;
    }

  ngOnInit() {
    this.user.username = this.authService.getUserName();
    this.username = this.authService.getUserName();
    this.user.userRole = this.authService.getUserRole().replace('_', ' ');
    this.roleName = `${this.username} | ${this.user.userRole}`.toUpperCase();
  }

  itemClick(e) {
    this.actionSheetTarget = e.itemElement;
    this.actionSheetVisible = true;
  }

  showRoute(value) {
    this.router.navigate([`/${value}`]);
  }
  logoClick(){
    this.router.navigate(['/welcome']);
  }

  funcUserLogOut() {
    const result = confirm(
      'Are you sure you want to log out?',
      'Log Out Request.'
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.authService.logOut();
      }
    });
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxActionSheetModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
