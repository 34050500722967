import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-roles-management',
  templateUrl: './roles-management.component.html',
  styleUrls: ['./roles-management.component.scss']
})
export class RolesManagementComponent extends SharedClassComponent implements OnInit {

title = 'System Roles';
modelName = 'users/roles/all';
ngOnInit() {
  this.paramwinfrm =  new FormGroup({
    roleId: new FormControl('', Validators.compose([])),
    roleName: new FormControl('', Validators.compose([Validators.required])),
    createdAt: new FormControl('', Validators.compose([Validators.required])),
    createdBy: new FormControl('', Validators.compose([Validators.required])),
    modifiedAt: new FormControl('', Validators.compose([Validators.required])),
    modifiedBy: new FormControl('', Validators.compose([Validators.required]))
  });
  this.onGetParamsdetails();
// Controls the datagrid height and max rows to display
  this.appInfo.setTitle(this.title);
  this.observerCall();
}

addRoles(): void {
  if (this.paramwinfrm.get('roleName').invalid) {
    this.toastr.error('Role name is required');
    return;
  }

  const data = {
    roleName: this.paramwinfrm.get('roleName').value
  };

  this.utilities.postServiceCall(data, 'users/roles/register').subscribe(res => {
    const response = res;
    if (response.statusCode === 2000) {
      this.toastr.success(response.statusMessage);
      this.paramswinpnl = false;
      this.onGetParamsdetails();
    } else {
      this.toastr.error(response.statusMessage);
    }
  }, error => {
    this.toastr.error(error.message);
  });
}

}
