import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-end-of-the-day-report',
  templateUrl: './end-of-the-day-report.component.html',
  styleUrls: ['./end-of-the-day-report.component.scss']
})
export class EndOfTheDayReportComponent extends SharedClassComponent implements OnInit {

  title = 'End Of Business Operation Day';
  eodDatasource = [];
  ngOnInit() {
    this.onGetEodReport();
  }

  onGetEodReport() {
    this.utilities.postServiceCall({}, 'eod/all').subscribe(res => {
      this.eodDatasource = res.data;
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onGetEodReport.bind(this)
      }
    });
  }

}
