import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { confirm } from 'devextreme/ui/dialog';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.scss']
})
export class AddExpensesComponent extends SharedClassComponent implements OnInit {

  title = 'Add Expenses';

  now = new Date();

  modelName = 'expenses/list';
  // modelName = 'categories/list';
  approveExpensesDataSource = [];
  expensesDataSource = [];
  debitAccountName = '';
  creditAccountName = '';

  expensesForm: FormGroup;


  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.expensesForm =  new FormGroup({
      categoryId: new FormControl(null, Validators.compose([Validators.required])),
      amount: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required]))
    });

    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      categoryName: new FormControl('', Validators.compose([Validators.required])),
      description: new FormControl('', Validators.compose([Validators.required])),
      createdAt: new FormControl('', Validators.compose([])),
      createdBy: new FormControl('', Validators.compose([])),
      modifiedAt: new FormControl('', Validators.compose([])),
      modifiedBy: new FormControl('', Validators.compose([]))
    });
    this.onGetDebitAccounts();
    this.onGetExpensesCategories();
  }

  fetchExpenes() {
    this.utilities.postServiceCall(this.data, 'expenses/list').subscribe(
      response => {
        const serverResponse = response;
        this.approveExpensesDataSource = [];
        for (const iterator of serverResponse.data) {
          if (iterator.flag == 0) {
            this.approveExpensesDataSource.push(
              {
                  expenseId: iterator.id,
                  puchaseDate: iterator.createdAt,
                  amount: iterator.amount,
                  purchasedBy: iterator.createdBy,
                  expenseCategory: iterator.expensesCategory.categoryName,
                  accountName: iterator.debitedAccount.accountName,
                  debitedAccount: iterator.debitedAccount.accountNumber,
                  description: iterator.description,
                  flag: iterator.flag
              }
            );
          }
        }
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  approveExpenses(e) {
    const data = {
      expenseId: e.expenseId
    };
    const result = confirm(
      `Are You sure You want to approve selected expense record ?`,
      `Approve Expenses`
    );
    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(data, 'expenses/approve-expenses').subscribe(res => {
          if (res.statusCode === 2000) {
            this.toastr.success(res.statusMessage);
            this.fetchExpenes();
          } else {
            this.toastr.success(res.statusMessage);
          }
        }, err => {
          this.toastr.error(err.message, 'Something went wrong, try again later.')
        });
      }
    });
  }

  onBillToolsBarPreparing (e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.fetchExpenes.bind(this)
        }
      }
    );
  }

  onBillToolsBarPreparingExpReport (e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.fetchData.bind(this)
        }
      }
    );
  }

  onAddDailyExpenses(): void {
    if (this.expensesForm.invalid) {
      this.toastr.error('Please provide relevant expenses informations');
      return;
    }
    if (this.expensesForm.get('amount').value < 1) {
      this.toastr.error('Amount must be above zero (0)');
      return;
    }
    this.utilities.postServiceCall(this.expensesForm.value, 'expenses/add').subscribe(
      response => {
        const data = response;
        if (data.statusCode === 2000) {
          this.toastr.success(data.statusMessage);
          this.expensesForm.reset();
          this.debitAccountName = '';
          this.creditAccountName = '';
        } else {
          this.toastr.error(data.statusMessage);
        }
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  onExpenseCategoryValueChanged(e) {
    for (const iterator of this.paramsDataSets) {
      if (e.value == iterator.id ) {
        this.debitAccountName = iterator.debitAccount.accountName;
        this.creditAccountName = iterator.creditAccount.accountName;
        break;
      }
    }
  }

  saveParams(path): void {
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please provide information on empty fields');
      return;
    }
    this.utilities.postServiceCall(this.paramwinfrm.value, path).subscribe(res => {
      const response = res;
      if (response.statusCode === 2000) {
        this.toastr.success(response.statusMessage);
        this.paramswinpnl = false;
        this.onGetParamsdetails();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  fetchData() {
    this.utilities.postServiceCall(this.data, this.modelName).subscribe(
      response => {
        const serverResponse = response;
        this.expensesDataSource = [];
          for (const iterator of serverResponse.data) {
            this.expensesDataSource.push(
              {
                  expenseId: iterator.id,
                  puchaseDate: iterator.createdAt,
                  amount: iterator.amount,
                  purchasedBy: iterator.createdBy,
                  expenseCategory: iterator.expensesCategory.categoryName,
                  accountName: iterator.debitedAccount.accountName,
                  debitedAccount: iterator.debitedAccount.accountNumber,
                  description: iterator.description
              }
            );
          }
      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

}
