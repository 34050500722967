import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { confirm } from 'devextreme/ui/dialog';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { UtilitiesService } from 'src/app/shared/services/utilities.service';
import { AuthService, AppInfoService } from 'src/app/shared/services';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-shared-class',
  templateUrl: './shared-class.component.html',
  styleUrls: ['./shared-class.component.scss']
})
export class SharedClassComponent {
  dailyBookingDataSource = [];
  dailyPhotoShootsDataSource = [];
  dailySalesDataSource = [];
  dailyExpensesDataSource = [];
  salesAdditionalItems = [];

  hidePurchaseOrderForm = true;
  hideSalesOrderForm = true;

  businessName = 'TOK General Building Material Limited';

  tabPanelHeight = 69;
  datagridHeight = 67;
  pageSize = 5;
  dialogHeight = 350;
  allowedPageSises = [];
  paramwinfrm: FormGroup;
  paramswinpnl = false;
  hideSaveButton: boolean;
  previewBillsFrm: FormGroup;
  previewBillsWin = false;
  hideButton: boolean;
  showAuthorizeUserButton: boolean;
  title: string;
  modelName: string;
  currencyDatasource: any; // TEMPORARY VARIABLE
  disableEnableTextSwitcher: any;
  currentData: any;
  data: any = {};
  paramsDataSets: any;
  expensesCategories = [];
  userRoles = [];
  accountsCategory = [];
  eligibleForTransfer = [];
  expensesAccount = [];
  accounts = [];
  bookingPackages = [];
  packagePhotoShoots = [];
  availableTime = [];
  photoShootTypes = [];
  selectedPhotoshootTypes = [];
  users = [];
  uniqueNumber: any;

  constructor(
    public toastr: ToastrService,
    public authService: AuthService,
    public router: Router,
    public observer: BreakpointObserver,
    public appInfo: AppInfoService,
    public utilities: UtilitiesService,
    public spinner: SpinnerVisibilityService,
    public storageService: StorageService
    ) { }

    // setHidePurchaseOrderForm(value: boolean) {
    //   this.hidePurchaseOrderForm = value;
    // }

    // getHidePurchaseOrderForm(): boolean {
    //   return this.hidePurchaseOrderForm;
    // }

    // setHideSalesOrderForm(value: boolean) {
    //   this.hideSalesOrderForm = value;
    // }

    // getHideSalesOrderForm(): boolean {
    //   return this.hideSalesOrderForm;
    // }



    addNewParameter() {
      this.dialogHeight = 350;
      this.paramwinfrm.reset();
      this.paramswinpnl = true;
      this.hideSaveButton = true;
      this.hideButton = false;
      this.showAuthorizeUserButton = false;
    }

    logger(object) {
      console.log(object);
    }

    onPopUpClose() {
      this.previewBillsWin = false;
    }
    previewDetails(e, obj) {
      this.dialogHeight = 420;
      this.hideSaveButton = false;
      this.hideButton = true;
      this.currentData = e.data;
      if (e.data.authorized_by != 0) {
        this.showAuthorizeUserButton = false;
      } else {this.showAuthorizeUserButton = true; }
      // tslint:disable-next-line: max-line-length
      this.disableEnableTextSwitcher = obj === 'User' ? e.data.status === 0 ? `Enable ${obj}` : `Disable ${obj}` : e.data.is_enabled === 0 ? `Enable ${obj}` : `Disable ${obj}`;
      let text = '';
      if (e.data.status === 0 || e.data.status === 2) {
        text = 'Enable user';
      } else if (e.data.status === 1) {
        text = 'Disable user';
      }
      this.appInfo.setTextSwitcher(text);
      // console.log(e.data);
      this.paramwinfrm.patchValue(e.data);
      if (this.modelName === 'management/package/list/all') {
       this.paramwinfrm.get('numberOfPhotos').patchValue(e.data.packageDetails.numberOfPhotos);
       this.paramwinfrm.get('clothChangeFreq').patchValue(e.data.packageDetails.clothChangeFreq);
       this.paramwinfrm.get('photoSessionTime').patchValue(e.data.packageDetails.photoSessionTime);
       this.paramwinfrm.get('printedA4PhotoIncluded').patchValue(e.data.packageDetails.printedA4PhotoIncluded);
       this.paramwinfrm.get('printedA4PhotosNumber').patchValue(e.data.packageDetails.printedA4PhotosNumber);
       this.paramwinfrm.get('frameA4Included').patchValue(e.data.packageDetails.frameA4Included);
       this.paramwinfrm.get('printedA3PhotoIncluded').patchValue(e.data.packageDetails.printedA3PhotoIncluded);
       this.paramwinfrm.get('printedA3PhotosNumber').patchValue(e.data.packageDetails.printedA3PhotosNumber);
       this.paramwinfrm.get('frameA3Included').patchValue(e.data.packageDetails.frameA3Included);
       this.paramwinfrm.get('shortVideoIncluded').patchValue(e.data.packageDetails.shortVideoIncluded);
       this.paramwinfrm.get('shortVideoTime').patchValue(e.data.packageDetails.shortVideoTime);
       this.paramwinfrm.get('reserve1').patchValue(e.data.reserve1);
       this.paramwinfrm.get('reserve2').patchValue(e.data.reserve2);
       this.paramwinfrm.get('reserve3').patchValue(e.data.reserve3);
       this.paramwinfrm.get('reserve4').patchValue(e.data.reserve4);
       this.paramwinfrm.get('reserve5').patchValue(e.data.reserve5);
       this.paramwinfrm.get('reserve6').patchValue(e.data.reserve6);
      //  this.selectedPhotoshootTypes = [];
      //  for (const iterator of e.data.photoShootTypes) {
      //   this.selectedPhotoshootTypes.push(iterator) ;
      //  }
       this.paramwinfrm.get('photoShootTypes').patchValue(null);
      }

      if (this.modelName === 'accounts/all') {
        this.paramwinfrm.get('accountPrefix').patchValue('RM');
      }
      if (this.modelName === 'accounts/all') {
        if (e.data.accountType == '101') {
          this.paramwinfrm.get('markedForSalesCollectionAccount').patchValue(true);
          this.toastr.info('Marked as sales collection account');
        } else {
          this.paramwinfrm.get('markedForSalesCollectionAccount').patchValue(false);
          // this.toastr.info('Not marked as sales collection account');
        }
      }

      if (this.modelName == 'categories/list') {
        this.paramwinfrm.get('drAccId').patchValue(e.data.debitAccount.id);
        this.paramwinfrm.get('crAccId').patchValue(e.data.creditAccount.id);
      }

      if (this.modelName == 'running/account/all') {
        this.paramwinfrm.get('accTypeId').patchValue(e.data.accountType.id);
        this.paramwinfrm.get('accId').patchValue(e.data.runningAcc.id);
      }


      this.paramswinpnl = true;
    }
    // tslint:disable-next-line: typedef
    funcpopWidth(percentage_width) {
      if (window.innerWidth > 800) {
        return (window.innerWidth * percentage_width) / 100;
      } else {
        return window.innerWidth - 50;
      }
      // return window.innerWidth - 50;
    }
  // set the widget height
  // tslint:disable-next-line: typedef
  funcpopHeight(percentage_height) {
      return (window.innerHeight * percentage_height) / 100;
    }
    // tslint:disable-next-line: typedef
    dataGridPagerController() {
      if (
        window.innerHeight < 800 &&
        !(window.innerWidth >= 1500 && window.innerWidth <= 1600)
      ) {
        return 7;
      } else if (
        window.innerWidth >= 1500 &&
        window.innerWidth <= 1600 &&
        window.innerHeight <= 800
      ) {
        return 10;
      } else if (window.innerHeight >= 800 && window.innerHeight < 900) {
        return 10;
      } else {
        return 15;
      }
    }
  // controls the PageSizeSelector
  // tslint:disable-next-line: typedef
  allowedPageSizeController() {
      if (window.innerHeight < 800) {
        return [];
      } else if (window.innerHeight >= 800 && window.innerHeight < 900) {
        return [5, 10];
      } else {
        return [5, 10, 15];
      }
    }
  // controls the number of rows to display for other datagrids apart from home's ones
  // tslint:disable-next-line: typedef
  otherdataGridPagerController() {
      if (
        window.innerHeight < 800 &&
        !(window.innerWidth >= 1500 && window.innerWidth <= 1600)
      ) {
        return 10;
      } else if (
        window.innerWidth >= 1500 &&
        window.innerWidth <= 1600 &&
        window.innerHeight <= 800
      ) {
        return 15;
      } else if (window.innerHeight >= 800 && window.innerHeight < 870) {
        return 15;
      } else if (window.innerHeight >= 870 && window.innerHeight < 900) {
        return 16;
      } else if (window.innerWidth >= 2000 ) {
        return 23;
      } else if (window.innerWidth >= 1900 ) {
        return 21;
      } else {
        return 19;
      }
    }

  // controls the PageSizeSelector for other datagrids apart from home's ones
  // tslint:disable-next-line: typedef
  otherAllowedPageSizeController() {
    if (window.innerHeight < 800) {
      return [];
    } else if (window.innerHeight >= 800 && window.innerHeight < 870) {
      return [5, 10, 15];
    } else if (window.innerHeight >= 870 && window.innerHeight < 900) {
      return [5, 10, 16];
    } else if (window.innerWidth >= 1900 ) {
      return [5, 10, 15, 21];
    } else if (window.innerWidth >= 2000 ) {
      return [5, 10, 15, 23];
    } else {
      return [5, 10, 19];
    }
  }

  // tslint:disable-next-line: typedef
  observerCall() {
       // Controls the datagrid height and width on screen size change detection
       const widthHeightController = () => {
        this.pageSize = this.otherdataGridPagerController();
        this.allowedPageSises = this.otherAllowedPageSizeController();
      };
       this.observer.observe(['(max-width: 1400px)', '(max-height: 800px)']).subscribe(result => {
        const isMatched = Object.values(result.breakpoints);
        if (isMatched[0] && isMatched[1]) {
            // this.tabPanelHeight = 56;
            this.datagridHeight = 57;
            widthHeightController();
          } else if ((window.innerWidth >= 1500 && window.innerWidth <= 1600) && window.innerHeight <= 800 ) {
            // this.tabPanelHeight = 59;
            this.datagridHeight = 54;
            widthHeightController();
          } else {
            // this.tabPanelHeight = 69;
            this.datagridHeight = 67;
            widthHeightController();
          }
      });
    }

    // tslint:disable-next-line: typedef
    formatNumberCounter(num, decPlaces) {
      decPlaces = Math.pow(10, decPlaces);
      const abbrev = [ 'k', 'm', 'b', 't' ];
      for (let i = abbrev.length - 1; i >= 0; i--) {
          const size = Math.pow(10, ( i + 1 ) * 3);
          if (size <= num) {
               num = Math.round(num * decPlaces / size) / decPlaces;
               if ((num == 1000) && (i < abbrev.length - 1)) {
                 num = 1;
                 i++;
               }
               num += abbrev[i];
               break;
          }
      }
      return num;
     }


     onParamsToolBarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxButton',
          options: {
            text: 'Add',
            type: 'default',
            icon: 'fa fa-plus',
            onClick: this.addNewParameter.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: this.refreshDataGrid.bind(this)
          }
        }
      );
    }

    refreshDataGrid() {
      this.onGetParamsdetails();
    }

    // tslint:disable-next-line: typedef
    onGetParamsdetails() {
      if (this.modelName === undefined) {
       return;
      }
      this.utilities.postServiceCall(this.data, this.modelName).subscribe(
        response => {
          const data = response;
          this.paramsDataSets = data.data;
        },
        error => {
          if (this.router.url === '/login') {
              return;
          }
          this.toastr.error(error.message);
          console.error(error);
        }
      );
    }

    onGetExpensesCategories(): void {
      this.utilities.postServiceCall(this.data, 'categories/list').subscribe(
        response => {
          const data = response;
          this.paramsDataSets = data.data;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onGetUserRoles(): void {
      this.utilities.postServiceCall(this.data, 'users/roles/all').subscribe(
        response => {
          const data = response;
          this.userRoles = data.data;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onGetDebitAccounts(): void {
      this.utilities.postServiceCall(this.data, 'accounts/all').subscribe(
        response => {
          const data = response;
          for (const el of data.data) {
            // if (el.accountType != '101') {
              this.accountsCategory.push(el);
            // }
            this.accounts.push(el);

            if (el.accountType == 106 || el.accountType == 107) {
              this.eligibleForTransfer.push(el);
            }
            if (el.accountType == 105) {
              this.expensesAccount.push(el);
            }
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onBookingPackages(): void {
      this.utilities.postServiceCall(this.data, 'management/package/list/all').subscribe(
        response => {
          const data = response;
          this.bookingPackages = data.data;
          this.packagePhotoShoots = data.data.photoShootTypes;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onPhotoShootTypes(): void {
      this.utilities.postServiceCall(this.data, 'management/photoshoot/type/lists').subscribe(
        response => {
          const data = response;
          this.photoShootTypes = data.data;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onGetDailyBookingData(): void {
      this.utilities.postServiceCall(this.data, 'customer/booking/daily/bookings').subscribe(
        response => {
          const data = response;
          this.dailyBookingDataSource = [];
          for (const iterator of data.data) {
            this.dailyBookingDataSource.push(
              {
              bookingId: iterator.id,
              photoShootType: iterator.photoShootType.photoShootName,
              package: iterator.bookingPackage.packageName,
              parentName: iterator.parentName,
              kidName: iterator.kidName,
              age: iterator.age,
              gender: iterator.gender,
              clothColor: iterator.clothColor,
              birthdayDate: iterator.birthdayDate,
              photoShootDate: iterator.photoShootDate,
              photoShootLoc: iterator.photoShootLoc,
              photoShootTime: iterator.photoShootTime,
              mobileNumber: iterator.mobileNumber,
              whatsAppNumber: iterator.whatsAppNumber,
              emergencyNumber: iterator.emergencyNumber,
              theme: iterator.theme,
              addDetails: iterator.addDetails,
              status: iterator.status,
              createdAt: iterator.createdAt,
              createdBy: iterator.createdBy,
              modifiedAt: iterator.modifiedAt,
              modifiedBy: iterator.modifiedBy,
              }
              );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onGetDailyPhotoShootsData(): void {
      this.utilities.postServiceCall(this.data, 'customer/booking/daily/photoshoots').subscribe(
        response => {
          const data = response;
          this.dailyPhotoShootsDataSource = [];
          for (const iterator of data.data) {
            this.dailyPhotoShootsDataSource.push(
            {
            id: iterator.id,
            parentName: iterator.parentName,
            kidName: iterator.kidName,
            age: iterator.age,
            gender: iterator.gender,
            photoShootType: iterator.photoShootType.photoShootName,
            package: iterator.bookingPackage.packageName,
            clothColor: iterator.clothColor,
            birthdayDate: iterator.birthdayDate,
            photoShootDate: iterator.photoShootDate,
            photoShootLoc: iterator.photoShootLoc,
            photoShootTime: iterator.photoShootTime,
            mobileNumber: iterator.mobileNumber,
            whatsAppNumber: iterator.whatsAppNumber,
            emergencyNumber: iterator.emergencyNumber,
            theme: iterator.theme,
            addDetails: iterator.addDetails,
            status: iterator.status,
            createdAt: iterator.createdAt,
            createdBy: iterator.createdBy,
            modifiedAt: iterator.modifiedAt,
            modifiedBy: iterator.modifiedBy
            }
            );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onGetDailySalesData(): void {
      this.utilities.postServiceCall(this.data, 'sales/daily/sales').subscribe(
        response => {
          const data = response;
          this.dailySalesDataSource = [];
          for (const iterator of data.data) {
            this.dailySalesDataSource.push(
            {
            id: iterator.id,
            salesDate: iterator.salesDate,
            parentName: iterator.bookings.parentName,
            photoShootType: iterator.bookings.photoShootType.photoShootName,
            packageName: iterator.bookings.bookingPackage.packageName,
            bookingId: iterator.bookings.id,
            numberOfPhotos: iterator.numberOfPhotos,
            ratePerPhoto: iterator.ratePerPhoto,
            printOutNumber: iterator.printOutNumber,
            printOutDetails: iterator.printOutDetails,
            printOutAmount: iterator.printOutAmount,
            softCopyNumber: iterator.softCopyNumber,
            softCopyAmount: iterator.softCopyAmount,
            otherChargesAmount: iterator.otherChargesAmount,
            otherChargesDescription: iterator.otherChargesDescription,
            receivedCash: iterator.receivedCash,
            receivedTigoPesa: iterator.receivedTigoPesa,
            addDetails: iterator.addDetails,
            createdBy: iterator.createdBy,
            totalDebit: iterator.totalDebit,
            totalCredit: iterator.totalCredit,
            balance: iterator.balance,
            status: iterator.status
            }
            );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }
    onGetDailyExpensesData(): void {
      this.utilities.postServiceCall(this.data, 'expenses/daily/expenses').subscribe(
        response => {
          const data = response;
          this.dailyExpensesDataSource = [];
          for (const iterator of data.data) {
            this.dailyExpensesDataSource.push(
            {
            id: iterator.id,
            amount: iterator.amount,
            createdAt: iterator.createdAt,
            createdBy: iterator.createdBy,
            modifiedAt: iterator.modifiedAt,
            modifiedBy: iterator.modifiedBy,
            currency: 'TZS',
            description: iterator.description,
            category: iterator.expensesCategory.categoryName,
            debitedAccountNumber: iterator.debitedAccount.accountNumber,
            debitedAccountName: iterator.debitedAccount.accountName
            }
            );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    onBillssToolBarPreparing (e, refresh_action) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: refresh_action.bind(this)
          }
        }
      );
    }

    onGetAdditionalItems(): void {
      this.utilities.postServiceCall(this.data, 'sales/additional/list').subscribe(
        response => {
          const data = response;
          for (const iterator of data.data) {
            this.salesAdditionalItems.push(
            {
            id: iterator.id,
            itemName: iterator.itemName,
            pricePerUnit: +iterator.pricePerUnit,
            description: iterator.description
            }
            );
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      );
    }

    formatCurrency(number) {
      let val =  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);
      val = val.replace('$', '');
      return val;
    }

    onGetUniqueNumber() {
      const data = {};
      this.utilities.postServiceCall(data, 'unique-number').subscribe(res => {
        this.uniqueNumber = res.number;
      }, error => {
        this.logger(error);
      });
    }
}
