import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { AuthGuardService } from 'src/app/shared/services';

@Component({
  selector: 'app-cost-report',
  templateUrl: './cost-report.component.html',
  styleUrls: ['./cost-report.component.scss']
})
export class CostReportComponent extends SharedClassComponent implements OnInit {

  title = 'Asset Report';
  assetsDatasource = [];
  elementType = 'svg';
  value = 'someValue12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 2;
  height = 100;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 20;
  background = '#ffffff';
  margin = 10;
  marginTop = 10;
  marginBottom = 10;
  marginLeft = 10;
  marginRight = 10;

  selectedRowsData = [];
  costCenterDatasource: any = []
  imageSrc = '';
  assetsCount = 0;

  imgBase = 'https://asset.digilabs.co.tz/v1/public/api/image/';

  noImgSrc = 'https://asset.digilabs.co.tz/v1/public/api/image/no-im.png';
  appInfo: any;
  utilities: any;
  toastr: any;
  villaAssetsDatasource: any[];
  showRegisteredAssetsDialog: boolean;
  unitCostDatasource: any;
  


  ngOnInit() {
    this.observerCall();
    //this.onGetAssetData();
    this.loadCostCenters();
   // this.loadUnitCenter();
  }
  loadUnitCenter() {
    throw new Error('Method not implemented.');
  }

  loadCostCenters() {
    // fetch data from api
    this.utilities.getServiceCall('cost_centers').subscribe(res =>{
      this.costCenterDatasource = res.data;
     if (res.data.length > 0) {
        this.onGetVillaAssets(res.data[0].id);
      }
      
      console.log(this.costCenterDatasource,);
      }, err => {
        this.toastr.error('Failed to register cost center, please try again.');
        this.logger(err);
      });
  }
  logger(err: any) {
    throw new Error('Method not implemented.');
  }

  xyz(e) {
    console.log(e);
  }
  
  onGetVillaAssets(id) {
    console.log(id)
   id.value
    this.utilities.getServiceCall('cost_centers/assets/'+id.value).subscribe(res => {
      if (res.statusCode == 200) {
        //this.toastr.success(res.statusMessage);
        this.villaAssetsDatasource = [];
        for (const iterator of res.data) {
          if (iterator.status == 1) {
            this.villaAssetsDatasource.push(iterator);
          }
        }
        console.log(this.villaAssetsDatasource);
        this.showRegisteredAssetsDialog = true;

        this.assetsCount = this.villaAssetsDatasource.length;

      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err =>{
      this.toastr.error('Failed to view asset ');
    })
  }
  
  onAssetRowSelected(e) {
    this.selectedRowsData = [];
    for (const iterator of e.selectedRowsData) {
      this.selectedRowsData.push({
        id: iterator.id,
        costId: iterator.costId,
        unitId: iterator.unitId,
        name: iterator.name,
       // name: iterator.name,
        location: iterator.location,
        price: iterator.price,
        depreciation: iterator.depreciation,
        quantity: iterator.quantity,
        totalItemAmt: iterator.totalItemAmt,
        description: iterator.description,
        barcode: iterator.barcode,
        createdBy: iterator.createdBy,
        imageBase: iterator.imageBase == null ? this.noImgSrc : this.imgBase+iterator.imageBase,
        status: iterator.status,
        created_at: iterator.created_at,
        updated_at: iterator.updated_at
      });
    }
    console.log(this.selectedRowsData);
  }

  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
      location: 'before',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-print',
        onClick: this.printBarcode.bind(this)
      }
    },
    // {
    //   location: 'after',
    //   widget: 'dxButton',
    //   options: {
    //     type: 'default',
    //     icon: 'fa fa-recycle',
    //     onClick: this.onGetAssetData.bind(this)
    //   }
    // }
    );
  }

  printBarcode() {
    if (this.selectedRowsData.length == 0) {
      this.toastr.error('Please select asset first to print barcode.!');
      return;
    }
    // if (this.selectedRowsData.length > 24) {
    //   this.toastr.error('Please select only 24 assets at a time to print the barcodes');
    //   return;
    // }
    let popUpWindow;
    const innerContents = document.getElementById('print-container2').innerHTML;
    popUpWindow = window.open('', '_blank', 'scrollbars=no, menubar=no, toolbar=no, location=no, status=no, titlebar=no');
    popUpWindow.document.write(`<html lang="en">
           <head>
           <link href="./assets/css/bootstrap.min.css" type="text/css" rel="stylesheet"/>
           <style>
           body{
            font-size: 16px;
            background:#fff;
            color:#000;
          }
          .table-bordered th, .table-bordered td {
            border: 1px solid #000 !important;
          }

          @media print {
          @page {
            size: A4;
            margin: 0mm;
          }

          body {
            margin: 10 auto;
            line-height: 1em;
            word-spacing:2px;
            letter-spacing:0.2px;
            /* font: 14px "Times New Roman", Times, serif; */
            font-size: 17px;
            background:#fff;
            color:#000;
            width: 100%;
          }

          .table-bordered th, .table-bordered td {
            border: 1px solid #000 !important;
          }

          @media print {
            .pagebreak { page-break-before: always; } /* page-break-after works, as well */
        }
            h1 {
              font: 28px "Times New Roman", Times, serif;
            }

            h2 {
              font: 24px "Times New Roman", Times, serif;
            }

            h3 {
              font: 20px "Times New Roman", Times, serif;
            }

            /* Improve colour contrast of links */
            a:link, a:visited {
              color: #781351
            }
            /* URL */
            a:link, a:visited {
              background: transparent;
              color:#333;
              text-decoration:none;
            }

            a[href]:after {
              content: "" !important;
            }
            a[href^="http://"] {
              color:#000;
            }

            #header {
              height:75px;
              font-size: 24pt;
              color:black
            }
          }
           </style>
           </head>
           <body onload="window.print()">${innerContents}</html>`);
    popUpWindow.document.close();
  }

}



