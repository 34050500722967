import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-stock-adjustment-report',
  templateUrl: './stock-adjustment-report.component.html',
  styleUrls: ['./stock-adjustment-report.component.scss']
})
export class StockAdjustmentReportComponent extends SharedClassComponent  implements OnInit {

  title = "Stock Adjustment Report"
  datasource = [];
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.onLoadData();
  }

  onLoadData() {
    this.utilities.postServiceCall({}, 'product/stock/adjust/all').subscribe(res => {
      this.datasource = [];
      for (const iterator of res.data) {
        this.datasource.push(iterator)
      }
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onLoadData.bind(this)
      }
    });
  }

}
