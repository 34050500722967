import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-adjust-stoke-approval',
  templateUrl: './adjust-stoke-approval.component.html',
  styleUrls: ['./adjust-stoke-approval.component.scss']
})
export class AdjustStokeApprovalComponent extends SharedClassComponent implements OnInit {

  title = 'Approve Stock Adjustment';
  datasource = [];
  showAlertDialog = false;
  showSuccessDialog = false;
  showWarningDialog = false;
  alertReason = '';
  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.onLoadData();
  }

  onLoadData() {
    this.utilities.postServiceCall({}, 'product/stock/adjust/all').subscribe(res => {
      this.datasource = [];
      for (const iterator of res.data) {
        if(iterator.status == 0) {
          this.datasource.push(iterator)
        }
      }
    }, error => {
      this.logger(error);
    });
  }

  hideDialog() {
    this.showAlertDialog = false;
  }

  hideSuccessDialod() {
    this.showSuccessDialog = false;
  }


  approveStockAdjustment(e, flag) {

    const data = {
      entryId: e.id,
      flag: flag
    }

    let commandMessage = '';
    let commandTitle = '';

    if (flag == 1) {
      commandMessage = 'Are You sure You want to approve selected stock adjustment record ?';
      commandTitle = 'Approve Stock Adjustment';
    }

    if (flag == 3) {
      commandMessage = 'Are You sure You want to cancel selected stock adjustment record ?';
      commandTitle = 'Cancel Stock Adjustment';
    }

    const result = confirm(commandMessage, commandTitle);

    result.then(dialogResult => {
      if (dialogResult) {
        this.utilities.postServiceCall(data, 'product/stock/adjust/approve').subscribe(res => {
          if (res.statusCode == 2000) {
            this.onLoadData();
            this.showSuccessDialog = true;
            this.alertReason = res.statusMessage;
          } else {
            this.showAlertDialog = true;
            this.alertReason = res.statusMessage;
          }
        }, error => {
          this.logger(error);
        });
      }});
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onLoadData.bind(this)
      }
    });
  }

}
