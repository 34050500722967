import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-vat-return-report',
  templateUrl: './vat-return-report.component.html',
  styleUrls: ['./vat-return-report.component.scss']
})
export class VatReturnReportComponent extends SharedClassComponent implements OnInit {

  title = 'Vat return report';
  vatReturnDatasource = [];
  ngOnInit() {
    this.onGetVatReturnReport();
  }

  onGetVatReturnReport() {
    this.utilities.postServiceCall({}, 'vat/return/all').subscribe(res => {
      this.vatReturnDatasource = res.data;
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onGetVatReturnReport.bind(this)
      }
    });
  }


}
