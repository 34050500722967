import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent extends SharedClassComponent implements OnInit {
  [x: string]: any;

  title = 'welcome';
  assetsDatasource = [];
  elementType = 'svg';
  value = 'someValue12340987';
  format = 'CODE128';
  lineColor = '#000000';
  width = 1;
  height = 40;
  displayValue = true;
  fontOptions = '';
  font = 'monospace';
  textAlign = 'center';
  textPosition = 'bottom';
  textMargin = 2;
  fontSize = 13;
  background = '#ffffff';
  margin = 0;
  marginTop = 0;
  marginBottom = 0;
  marginLeft = 0;
  marginRight = 0;

  unitCostDatasource: any = [];
  unitCounts= 0;
  assetCounts = 0;
  selectedRowsData: any = [];
  toastr: any;
  costId: any;
  unitname: any;
  assetname:any;
  unitId: any;
  showRegisteredAssetsDialog: boolean;
  costCenterDatasource: any;
  costCenterCDatasource: any;
  assetItemForm: any;

  costIDs: any = [];

  ngOnInit() {
    // this.onGetUnitAssets(this.id);
   // this.loadUnitCenters();
    this.loadCostCenters();
  }

  
  loadCostCenters() {
    // fetch data from api
    this.utilities.getServiceCall('cost_centers').subscribe(res =>{
      this.costCenterDatasource = res.data;
      this.costIDs = [];
      for (const iterator of res.data) {
        this.costIDs.push(iterator.id);
        this.onGetUnitAssets();
      }
    //  if (res.data.length > 0) {
    //     this.onGetUnitAssets(res.data[0].id);
    //   }
      
      // console.log(this.costCenterDatasource,);
      }, err => {
        this.toastr.error('Failed to register cost center, please try again.');
        this.logger(err);
      });
  }
  logger(err: any) {
    throw new Error('Method not implemented.');
  }

  // getUnits(costId: number) {
  //   const costCenters = this.ostCenterDatasource.filter(element => element.costId === costId)
  //   return costCenters.length
  // }

  
  onGetUnitAssets() {
    console.log()
   
    this.utilities.getServiceCall('cost_centers/stats').subscribe(res => {
      this.costCenterCDatasource = res.data;
           
 }, err =>{
      this.toastr.error(err,'Failed to view asset ');
    })
  }
  onAssetRowSelected(e) {
    this.selectedRowsData = [];
    for (const iterator of e.selectedRowsData) {
      if(iterator.status == 1){
        this.selectedRowsData.push({
          costName: iterator.costName,
          unitcount:iterator.unitcount,
          assetCount:iterator.assetCount,

         
        });
      }
      
    }
    console.log(this.selectedRowsData);
  }
}
