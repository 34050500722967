import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppSettings } from 'src/app/app-settings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private httpClient: HttpClient) {
  }

  // tslint:disable-next-line: typedef
  postServiceCall(data, path, ): any {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST'
    });
    return this.httpClient.post(`${AppSettings.baseURL}${path}`, data, {headers}).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }


  getServiceCall(path): any {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'GET'
    });
    return this.httpClient.get(`${AppSettings.baseURL}${path}`, {headers}).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  putServiceCall(data, path): any {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'PUT'
    });
    return this.httpClient.put(`${AppSettings.baseURL}${path}`, data, {headers}).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  upload(file: File, path,  id) {

    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST'
    });
    const formData: FormData = new FormData();

    formData.append('imageBase', file);
    formData.append('id', id);

      return this.httpClient.post(`${AppSettings.baseURL}${path}`, formData, {
          reportProgress: true,
          responseType: 'json',
        }).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }

  getFiles(img) {
    return this.httpClient.get(`${AppSettings.baseURL}image/${img}`);
  }

  postServiceCallCustom(data, path) {
    const headers = new HttpHeaders({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'x-auth, content-type',
      'Access-Control-Allow-Methods': 'POST'
    });
    return this.httpClient.post(`${path}`, data, {headers}).pipe(map(res => JSON.parse(JSON.stringify(res))));
  }


}
