import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { confirm } from 'devextreme/ui/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-store-management',
  templateUrl: './store-management.component.html',
  styleUrls: ['./store-management.component.scss']
})
export class StoreManagementComponent extends SharedClassComponent implements OnInit {

  title = 'Store Managemnt';
  storesDataSource = [];
  hideEditActionButton = true;
  showAddDialog = false;
  popUpTitle = 'Add New Store';
  hideStoreStatus = true;

  ngOnInit() {
    this.getAllStores();
    this.paramwinfrm =  new FormGroup({
      id: new FormControl('', Validators.compose([])),
      storeName: new FormControl('', Validators.compose([Validators.required])),
      // storeCode: new FormControl('', Validators.compose([])),
      storeSqMetre: new FormControl('', Validators.compose([Validators.required])),
      storeLocation: new FormControl('', Validators.compose([Validators.required])),
      status: new FormControl('', Validators.compose([]))
    });
  }

    addNewStore() {
      this.paramwinfrm.reset();
      this.hideEditActionButton = true;
      this.showAddDialog = true;
      this.hideStoreStatus = true;
    }

    previewStore(e) {
      this.hideEditActionButton = false;
      this.hideStoreStatus = false;
      this.popUpTitle = 'Update Store';
      this.showAddDialog = true;
      this.paramwinfrm.patchValue(e.data);
      this.paramwinfrm.get('storeSqMetre').patchValue(e.data.sqMetre);
      this.paramwinfrm.get('storeLocation').patchValue(e.data.storeLoc);
      }

  getAllStores() {
    this.utilities.postServiceCall({}, 'store/all').subscribe(res => {
      this.storesDataSource = res.data;
    }, error => {
      this.toastr.error(error.message);
    });
  }

  createStore(): void {

    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill all required details');
      return;
    }

    this.utilities.postServiceCall(this.paramwinfrm.value, 'store').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllStores();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  updateStore(): void {
    if (this.paramwinfrm.invalid) {
      this.toastr.error('Please fill all required details');
      return;
    }

    if (this.paramwinfrm.get('status').value === null || this.paramwinfrm.get('status').value === false) {
      this.paramwinfrm.get('status').patchValue(0);
    } else {
      this.paramwinfrm.get('status').patchValue(1);
    }

    this.utilities.postServiceCall(this.paramwinfrm.value, 'store/update').subscribe(res => {
      const response = res;
      if (response.statusCode == 2000) {
        this.toastr.success(response.statusMessage);
        this.showAddDialog = false;
        this.getAllStores();
      } else {
        this.toastr.error(response.statusMessage);
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }


    onToolBarPreparing(e): void {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxButton',
          options: {
            text: 'Add',
            type: 'default',
            icon: 'fa fa-plus',
            onClick: this.addNewStore.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            type: 'default',
            onClick: this.getAllStores.bind(this)
          }
        }
      );
    }

}
