import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-expenses-report',
  templateUrl: './expenses-report.component.html',
  styleUrls: ['./expenses-report.component.scss']
})
export class ExpensesReportComponent extends SharedClassComponent implements OnInit {
  title = 'Expenses Report';
  modelName = 'expenses/list';
  expensesDataSource = [];
  oneWeekAgo = new Date();
  flag = 0;
  ngOnInit(): void {
    this.appInfo.setTitle(this.title);
    this.fetchData();
    this.observerCall();
  }

  fetchData() {
    this.utilities.postServiceCall(this.data, this.modelName).subscribe(
      response => {
        const serverResponse = response;
        this.expensesDataSource = [];

        
            for (const iterator of serverResponse.data) {
              if (iterator.flag == 1) {
              this.expensesDataSource.push(
                {
                    expenseId: iterator.id,
                    entryDate: iterator.createdAt,
                    amount: iterator.amount,
                    entryBy: iterator.createdBy,
                    expenseCategory: iterator.expensesCategory.categoryName,
                    debitedAccount: iterator.expensesCategory.debitAccount.accountName,
                    creditedAccount: iterator.expensesCategory.creditAccount.accountName,
                    description: iterator.description,
                    approvedBy: iterator.approvedBy,
                    cancelledBy: iterator.cancelledBy,
                    flag: iterator.flag
                }
              );
            } else if (iterator.flag == 7) {
                this.expensesDataSource.push(
                  {
                    expenseId: iterator.id,
                    entryDate: iterator.createdAt,
                    amount: null,
                    entryBy: iterator.createdBy,
                    expenseCategory: iterator.expensesCategory.categoryName,
                    debitedAccount: iterator.expensesCategory.debitAccount.accountName,
                    creditedAccount: iterator.expensesCategory.creditAccount.accountName,
                    description: iterator.description,
                    approvedBy: null,
                    cancelledBy: iterator.cancelledBy,
                    flag: iterator.flag
                  } );
            } else if (iterator.flag == 0) {
                this.expensesDataSource.push(
                  {
                    expenseId: iterator.id,
                    entryDate: iterator.createdAt,
                    amount: null,
                    entryBy: iterator.createdBy,
                    expenseCategory: iterator.expensesCategory.categoryName,
                    debitedAccount: iterator.expensesCategory.debitAccount.accountName,
                    creditedAccount: iterator.expensesCategory.creditAccount.accountName,
                    description: iterator.description,
                    approvedBy: null,
                    cancelledBy: null,
                    flag: iterator.flag
                  }
                );
            }
        } 
        

      },
      error => {
        this.toastr.error(error.message);
      }
    );
  }

  onBillToolsBarPreparing (e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          type: 'default',
          onClick: this.fetchData.bind(this)
        }
      }
    );
  }
}
