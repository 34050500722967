import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppSettings } from 'src/app/app-settings';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { Observable } from 'rxjs';
import { HttpEventType, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-register-sales',
  templateUrl: './register-sales.component.html',
  styleUrls: ['./register-sales.component.scss']
})
export class RegisterSalesComponent extends SharedClassComponent implements OnInit {

  title = 'Add new Asset to Unit';
  currentYear = new Date().getFullYear();

  hideApproveControls = true;
  assetItemsDatasource = [];
  showAddDialog = false;
  popUpTitle = 'Add New Asset';

  hideEditActionButton = true;

  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  preview = '';

  imageInfos?: Observable<any>;

  assetItemForm: FormGroup;
  villaForm: FormGroup;
  userRole: any;

  unit: any;
  asset: any;
  costId: any;
  unitId: any;

  costCenterDatasource: any = [];
  unitCostDatasource: any =[];
  assetsDatasource: any = [];
  showRegisteredAssetsDialog: boolean;

  ngOnInit() {
    this.appInfo.setTitle(this.title);
    this.observerCall();
    this.userRole = this.authService.getUserRole();
    this.assetItemForm = new FormGroup({
      id: new FormControl(null, Validators.compose([])),
      costId: new FormControl(null, Validators.required),
      unitId: new FormControl(null, Validators.compose([Validators.required])),
      name: new FormControl(null, Validators.compose([Validators.required])),
      location: new FormControl(null, Validators.compose([Validators.required])),
      quantity: new FormControl(1, Validators.compose([])),
      price: new FormControl(null, Validators.compose([Validators.required])),
      description: new FormControl(null, Validators.compose([Validators.required])),
      depreciation: new FormControl(null, Validators.compose([Validators.required])),
      assetImage: new FormControl(null, Validators.compose([])),
    });

    
    this.loadCostCenters();
    this.loadUnitCost();
  }

  loadCostCenters() {
    // fetch data from api
    this.utilities.getServiceCall( 'cost_centers').subscribe(res => {
      this.costCenterDatasource = res.data;
      }, err => {
        this.logger(err);
      });
  }

  loadUnitCost() {
    // fetch data from api
    this.utilities.getServiceCall( 'unit').subscribe(res => {
     
      for (const unitCost of res.data) {
        if (unitCost.status == 1) {
          this.unitCostDatasource.push(unitCost);
        }
      }
      }, err => {
       this.toastr.error('Failed to load units please try again.');
        this.logger(err);
      });
  }

  xyz(e) {
    console.log(e);
  }

  selectFile(event: any): void {
    this.message = '';
    this.preview = '';
    this.progress = 0;
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;

        const reader = new FileReader();

        reader.onload = (e: any) => {
          // console.log(e.target.result);
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }
  

  upload(): void {
    this.progress = 0;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.currentFile = file;

        this.utilities.upload(this.currentFile, 'assets/image', '1').subscribe({
          next: (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round((100 * event.loaded) / event.total);
            } else if (event instanceof HttpResponse) {
              this.message = event.body.message;
              this.imageInfos = this.utilities.getFiles(event.body.data);
            }
          },
          error: (err: any) => {
            console.log(err);
            this.progress = 0;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the image!';
            }
            this.currentFile = undefined;
          },
        });
      }

      this.selectedFiles = undefined;
    }
  }

  resetForm(e) {
    if (e == 1) {
      this.assetItemsDatasource = [];
    } else if (e == 2) {
      this.assetItemForm.reset();
    }
  }

  addNewAsset() {
    this.hideEditActionButton = true;
    this.showAddDialog = true;
    this.assetItemForm.reset();
  }

  hello(e) {
    console.log(e.value)

    alert(e.value)
  }

  onGetCost(costId) {
      const data = {
      costId: costId.value,
     // unitId : unitId
    };
    this.utilities.getServiceCall('cost_centers/units/'+costId.value).subscribe(res => {
      if (res.statusCode == 200) {
       // this.toastr.success(res.statusMessage);
        this.unitCostDatasource = [];
        for (const iterator of res.data) {
          if (iterator.status == 1) {
            this.unitCostDatasource.push(iterator);
          }
        }
        if (this.unitCostDatasource.length > 0) {
          console.log(this.unitCostDatasource);
          this.assetItemForm.get('unitId').patchValue(this.unitCostDatasource[0].id);
          console.log(this.assetItemForm.get('unitId').value);
        } else {
        }
      } else {
        this.toastr.error(res.statusMessage);
      }
    }, err =>{
      //this.toastr.error('Failed to add asset to units');
    })
  }

  onGetUnit(unitId) {
 
    const data = {
          
          unitId : unitId
        }
  }

  registerAsset() {

    console.log(this.assetItemForm.value);

    if (this.assetItemForm.invalid) {
      this.toastr.error(`Dear ${this.authService.getUserName()} please fill all required fields.`)
      return;
    }

      if (+this.assetItemForm.get('depreciation').value > this.assetItemForm.get('price').value) {
      this.toastr.error('Asset depreciated price can not exceed asset\'s original price');
      return;
    }

    
    const data = {
        costId: this.assetItemForm.get('costId').value,
        unitId: this.assetItemForm.get('unitId').value,
        name: this.assetItemForm.get('name').value,
        location: this.assetItemForm.get('location').value,
        assetQuantity: '1',
        price: this.assetItemForm.get('price').value,
        depreciation: this.assetItemForm.get('depreciation').value,
        description: this.assetItemForm.get('description').value,
        totalItemAmt: this.assetItemForm.get('price').value,
        createdBy: this.authService.getUserId()
    };

    this.showAddDialog = false;
    this.utilities.postServiceCall(data, 'assets/create').subscribe(res => {
      if(res.statusCode == 200) {
        this.toastr.success(res.statusMessage);
        this.assetItemForm.reset();
        this.assetItemsDatasource = [];
      } else {
        this.toastr.error(res.statusMessage);
      }
      }, err => {
        this.toastr.error('Failed, to add new  asset please try again.');
        // this.logger(err);
      });
  }

 
  onParamsToolBarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        text: 'New Asset',
        type: 'default',
        icon: 'fa fa-plus',
        onClick: this.addNewAsset.bind(this)
      }
    });
  }

  previewAsset(e) {
    this.hideEditActionButton = false;
    this.popUpTitle = 'Update Asset';
    this.assetItemForm.patchValue(e.data);
    this.assetItemForm.get('id').patchValue(e.data.id);
    this.showAddDialog = true;
  }

  onUpdateAsset() {
    if (this.assetItemForm.invalid) {
      this.toastr.error(`${this.authService.getUserName()}, please fill some important data that are lest empty to continue.`);
      return;
    }

    if (this.assetItemForm.get('quantity').value < 1) {
      this.toastr.error(`Dear ${this.authService.getUserName()}, asset quantity can not be less than one.`);
      return;
    }


    for (let index = 0; index < this.assetItemsDatasource.length; index++) {
      if (this.assetItemsDatasource[index].id === this.assetItemForm.get('id').value) {
        this.assetItemsDatasource[index] = this.assetItemForm.value;
        let totalItemAmt = this.assetItemForm.get('quantity').value * +this.assetItemForm.get('price').value;
        this.assetItemsDatasource[index].totalItemAmt = totalItemAmt;
        this.assetItemsDatasource[index].assetLocation = this.assetItemForm.get('location').value;
        this.assetItemsDatasource[index].assetPrice = +this.assetItemForm.get('price').value;
        this.assetItemsDatasource[index].assetDescription = this.assetItemForm.get('description').value;
        this.assetItemsDatasource[index].assetName = this.assetItemForm.get('name').value;
        this.showAddDialog = false;
        this.spinner.hide();
        break;
      }
    }
  }

  onDeleteAsset() {
    const result = confirm(
      'Are you sure you want to delete selected asset from list?',
      'Delete Asset'
    );
    result.then(dialogResult => {
      if (dialogResult) {
    for (let index = 0; index < this.assetItemsDatasource.length; index++) {
      if (this.assetItemsDatasource[index].id == this.assetItemForm.get('id').value) {
        this.assetItemsDatasource.splice(index, 1);
        this.showAddDialog = false;
        break;
      }
    }
      }
    });
  }
}
