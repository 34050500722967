import { Component, OnInit } from '@angular/core';
import { SharedClassComponent } from 'src/app/configurations/shared-class/shared-class.component';

@Component({
  selector: 'app-discount-allowed-report',
  templateUrl: './discount-allowed-report.component.html',
  styleUrls: ['./discount-allowed-report.component.scss']
})
export class DiscountAllowedReportComponent extends SharedClassComponent implements OnInit {

  title = 'Discount allowed report';
  discountAllowedDatasource = [];
  ngOnInit() {
    this.onGetDiscountAllowedReport();
  }

  onGetDiscountAllowedReport() {
    this.utilities.postServiceCall({}, 'discount/allowed/all').subscribe(res => {
      this.discountAllowedDatasource = res.data;
    }, error => {
      this.logger(error);
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift( {
      location: 'after',
      widget: 'dxButton',
      options: {
        type: 'default',
        icon: 'fa fa-retweet',
        onClick: this.onGetDiscountAllowedReport.bind(this)
      }
    });
  }
}
